/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #377dff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ed4c78;
  --orange: #fd7e14;
  --yellow: #f5ca99;
  --green: #28a745;
  --teal: #00c9a7;
  --cyan: #00c9db;
  --white: #fff;
  --gray: #8c98a4;
  --gray-dark: #406c8b;
  --primary: #277525;
  --primary-dark: #134612;
  --secondary: #406c8b;
  --success: #00c9a7;
  --info: #00c9db;
  --warning: #f5ca99;
  --danger: #ed4c78;
  --light: #f9fafc;
  --dark: #132144;
  --black: #000000;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}
#root{
  max-height: -webkit-fill-available;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.scroll-bar::-webkit-scrollbar {
  width: 4px;
}

.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(206, 29, 29, 0.3);
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #677788;
  text-align: left;
  background-color: #fff;
  height: 100vh;
  max-height: -webkit-fill-available;
}


[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--primary-dark);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--primary) !important;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.4;
  color: #1e2022;
}

h1,
.h1 {
  font-size: 1.41094rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.26609rem + 0.19312vw);
  }
}

h2,
.h2 {
  font-size: 1.3125rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}

h3,
.h3 {
  font-size: 1.14844rem;
}

h4,
.h4 {
  font-size: 0.98438rem;
}

h5,
.h5 {
  font-size: 0.875rem;
}

h6,
.h6 {
  font-size: 0.76563rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 400;
}

.display-lg-1 {
  font-size: 9.375rem;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .display-lg-1 {
    font-size: calc(4.5625rem + 7.25vw);
  }
}

@media (max-width: 1100px) {
  .display-lg-1.display-breakpoint {
    font-size: calc(3.025rem + 7.25vw);
  }
}

@media (max-width: 600px) {
  .display-lg-1.display-breakpoint {
    font-size: calc(3.025rem + 7.25vw);
  }
}

.display-1 {
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.5625rem + 3.75vw);
  }
}

.display-2 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

.display-3 {
  font-size: 3.0625rem;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.43125rem + 2.175vw);
  }
}

.display-3-5 {
  font-size: 2.4625rem;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .display-3-5 {
    font-size: calc(1.43125rem + 1.175vw);
  }
}

.display-4 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

.display-5 {
  font-size: 1.4rem;
}
.display-6 {
  font-size: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 0.0625rem solid #e7eaf3;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: rgba(55, 125, 255, 0.1);
}

.height-auto {
  height: auto !important;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #8c98a4;
}

.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  max-width: 100%;
  height: auto;
}

.fs-1{
  line-height: 120% !important;
}

.fs-2{
  line-height: 150% !important;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #8c98a4;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #1e2022;
  background-color: #e7eaf3;
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #1e2022;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.row-cards > .col,
.row-cards > [class*='col-'] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row-deck > .col,
.row-deck > [class*='col-'] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row-deck > .col .card,
.row-deck > [class*='col-'] .card {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}



@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .assistant-side-bar {
    border-width: 0px 5px !important;
  }

  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #677788;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  color: #000;
}

.table thead th {
  vertical-align: bottom;
  font-size: bold;
  border-bottom: 0.125rem solid rgba(231, 234, 243, 0.7);
}

.table tbody + tbody {
  border-top: 0.125rem solid rgba(231, 234, 243, 0.7);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

.table-bordered th,
.table-bordered td {
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

.table-bordered th:first-child,
.table-bordered td:first-child {
  border-left: 0;
}

.table-bordered th:last-child,
.table-bordered td:last-child {
  border-right: 0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0.125rem;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8fafd;
}

.table-hover tbody tr:hover {
  color: #677788;
  background-color: rgba(231, 234, 243, 0.4);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c7dbff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #97bbff;
}

.table-hover .table-primary:hover {
  background-color: #aecbff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aecbff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d7dde4;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b5c0cc;
}

.table-hover .table-secondary:hover {
  background-color: #c8d0da;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8d0da;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f0e6;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae3d1;
}

.table-hover .table-success:hover {
  background-color: #a3ecdf;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3ecdf;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f0f5;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ae3ec;
}

.table-hover .table-info:hover {
  background-color: #a2ebf2;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a2ebf2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf0e2;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fae3ca;
}

.table-hover .table-warning:hover {
  background-color: #fae4cb;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fae4cb;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #facdd9;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f6a2b9;
}

.table-hover .table-danger:hover {
  background-color: #f8b6c7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f8b6c7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfefe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfd;
}

.table-hover .table-light:hover {
  background-color: #ecf6f6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ecf6f6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdc1cb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #848c9e;
}

.table-hover .table-dark:hover {
  background-color: #afb4c0;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #afb4c0;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(231, 234, 243, 0.4);
}

.table-hover .table-active:hover {
  background-color: rgba(214, 219, 235, 0.4);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(214, 219, 235, 0.4);
}

.table .thead-dark th {
  color: #fff;
  background-color: #132144;
  border-color: #1b3062;
}

.table .thead-light {
  border-bottom: 1px solid rgb(231 234 243);
  border-top: 1px solid rgb(231 234 243);
}

.table .thead-light th {
  color: #677788;
  background-color: #f8fafd;
  border-color: rgba(231, 234, 243, 0.7);
}

.table-dark {
  color: #fff;
  background-color: #132144;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #1b3062;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.tool-form-control{
  display: block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  position: relative;
  overflow: hidden;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  background-color: #fff;
  /*border: 1px solid #ccc;*/
  border-radius: 5px !important;
  position: relative;
  overflow: hidden;
  /*box-shadow: inset 1px 2px 4px 1px rgba(0, 0, 0, 0.15);*/
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control.disabled{
  background-color: #f8fafd;
  opacity: 1;
  color: #999;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}

.form-control:focus {
  color: #1e2022;
  background-color: #fff;
  border-color: #999;
  outline: 0;
  box-shadow: 0px -0px 6px 0px rgba(0, 0, 0, 0.2) !important;
}

.form-control::-webkit-input-placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control::placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8fafd;
  opacity: 1;
}
.form-label {
  color: #000;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #1e2022;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.60938rem;
  padding-bottom: 0.60938rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.54688rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #677788;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1rem);
  padding: 0.4375rem 0.65625rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1.5em + 1.875rem);
  padding: 0.875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.custom-checkbox-lg {
  transform: scale(1.75);
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #8c98a4;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00c9a7;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: #fff;
  background-color: #00c9a7;
  border-radius: 0.3125rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #00c9a7;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #00c9a7;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #00c9a7;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #00c9a7;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00c9a7;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00fcd1;
  background-color: #00fcd1;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #00c9a7;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00c9a7;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ed4c78;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: #fff;
  background-color: #ed4c78;
  border-radius: 0.3125rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #ed4c78;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ed4c78;
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ed4c78;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ed4c78;
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ed4c78;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #ed4c78;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ed4c78;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f27a9b;
  background-color: #f27a9b;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #ed4c78;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ed4c78;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ed4c78;
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .mobile-menu {
    display: block !important;
  }

  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #677788;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.54688rem 0.875rem;
  font-size: 16px;
  line-height: 1.6;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #677788;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-back {
  color: #000;
  background-color: #e3e3e3;
  border-radius: 0 !important;
  padding: 20px;
}

.btn-basic-primary {
  color: #fff;
  background-color: transparent;
  border: none;
  color: #598b40;
}

.btn-result-action{
  color: #666 ;
}
.btn-result-action:hover{
  color: #000 ;
}

.btn-comingsoon {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.btn-comingsoon:hover {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.btn-tab{
  color: #666;
  background-color: #e3e3e3;
  border-color: #ccc;
}
.btn-tab.active{
  color: #000;
  background-color: #fff;
  border-color: #ccc;
}

.btn-primary {
  color: #fff;
  background-color: #598b40;
  border-color: #598b40;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #41642e;
  border-color: #598b40;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #41642e;
  border-color: #598b40;
  box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #646464;
  background-color: #bdc5b9;
  border-color: #598b40;
  cursor:  not-allowed;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #406c8b;
  border-color: #406c8b;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5f7389;
  border-color: #596d82;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5f7389;
  border-color: #596d82;
  box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #406c8b;
  border-color: #406c8b;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #596d82;
  border-color: #54667a;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
}


.btn-outline-dark {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.btn-outline-dark:hover {
  color: #fff !important;
  background-color: #000;
  border-color: #000;
}

.btn-with-icon {
  color: #000;
  background-color: #fff;
  border-color: #ccc;
}

.btn-with-icon:hover {
  color: #fff;
  background-color: #000;
  border-color: #ccc;
}

.btn-with-icon:disabled {
  color: #666;
  background-color: #fff;
  border-color: #ccc;
  font-size: 14px;
}

.btn-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:hover {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00967d;
  border-color: #008972;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-info:hover {
  color: #fff;
  background-color: #00a6b5;
  border-color: #009aa8;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #00a6b5;
  border-color: #009aa8;
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 224, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #009aa8;
  border-color: #008e9b;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 224, 0.5);
}

.btn-warning {
  color: #1e2022;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-warning:hover {
  color: #1e2022;
  background-color: #f2b876;
  border-color: #f0b26b;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #1e2022;
  background-color: #f2b876;
  border-color: #f0b26b;
  box-shadow: 0 0 0 0.2rem rgba(213, 177, 135, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #1e2022;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #1e2022;
  background-color: #f0b26b;
  border-color: #efac5f;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 177, 135, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ea295e;
  border-color: #e81e55;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ea295e;
  border-color: #e81e55;
  box-shadow: 0 0 0 0.2rem rgba(240, 103, 140, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e81e55;
  border-color: #e2174e;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 103, 140, 0.5);
}
.btn-soft-danger .btn-loading {
  color: #fff;
  background-color: #e81e55 !important;
  border-color: #e2174e !important;
}

.btn-light {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-light:hover {
  color: #1e2022;
  background-color: #e0e5ef;
  border-color: #d7deeb;
}

.btn-light:focus,
.btn-light.focus {
  color: #1e2022;
  background-color: #e0e5ef;
  border-color: #d7deeb;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #1e2022;
  background-color: #d7deeb;
  border-color: #cfd7e7;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #000000;
  border-color: #132144;
}

.btn-dark:hover {
  color: #fff !important;
  background-color: #0b1226;
  border-color: #080e1c;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #0b1226;
  border-color: #080e1c;
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 96, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #132144;
  border-color: #132144;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #080e1c;
  border-color: #050912;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 96, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-primary {
  color: #598b40;
  border-color: #598b40;
  background-color: #fff;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #598b40;
  border-color: #598b40;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 139, 64, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #8fc574;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #83b16c;
  border-color: #598b40;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 139, 64, 0.5);
}

.btn-outline-secondary {
  color: #406c8b;
  border-color: #ccc !important;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #406c8b;
  border-color: #406c8b;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #406c8b;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #406c8b;
  border-color: #406c8b;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}

.btn-outline-success {
  color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00c9a7;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-info {
  color: #00c9db;
  border-color: #00c9db;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.flicker {
  animation: flicker 1s infinite;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 219, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00c9db;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 219, 0.5);
}

.btn-outline-warning {
  color: #f5ca99;
  border-color: #f5ca99;
}

.btn-outline-warning:hover {
  color: #1e2022 !important;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f5ca99;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #1e2022;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.btn-outline-danger {
  color: #ed4c78;
  border-color: #ed4c78;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ed4c78;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
}

.btn-outline-light {
  color: #f9fafc;
  border-color: #f9fafc;
}

.btn-outline-light:hover {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f9fafc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.btn-outline-dark {
  color: #132144;
  border-color: #132144;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #132144;
  border-color: #132144;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 33, 68, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #132144;
  background-color: transparent;
  border-color: var(--dark) !important;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #132144;
  border-color: #132144;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 33, 68, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #377dff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0052ea;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #8c98a4;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.4375rem 0.65625rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 98;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 0;
  margin: 0.625rem 0 0;
  font-size: 0.875rem;
  color: #677788;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.625rem;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.625rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7eaf3;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1e2022;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding:10px 20px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #121314;
  text-decoration: none;
  background-color: rgba(0, 201, 167, 0.13);
}

.dropdown-item.active,
.dropdown-item:active {
  color: #1e2022;
  text-decoration: none;
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #8c98a4;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #1e2022;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.49219rem;
  padding-left: 0.49219rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.82031rem;
  padding-left: 0.82031rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.0625rem;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -0.0625rem;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -0.0625rem;
}

.input-group-prepend {
  margin-right: -0.0625rem;
}

.input-group-append {
  margin-left: -0.0625rem;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.54688rem 0.875rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #677788;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.875rem);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1rem);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4375rem 0.65625rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.875rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.2rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(55, 125, 255, 0.4);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #8c98a4;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f8fafd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.2rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #d6dbeb solid 0.0625rem;
}

.custom-control-label::after {
  position: absolute;
  top: 0.2rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #377dff;
  background-color: #377dff;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: 0.325rem;
  left: -2.125rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #d6dbeb;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 1.875rem 0.54688rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right 0.5rem center/1rem 1rem;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: rgba(55, 125, 255, 0.4);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #1e2022;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.875rem;
  background-image: none;
}

.custom-select:disabled {
  color: #8c98a4;
  background-color: #f8fafd;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}

.custom-select-sm {
  height: calc(1.5em + 1rem);
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 0.65625rem;
  font-size: 0.8125rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.875rem);
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 1.09375rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(55, 125, 255, 0.4);
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f8fafd;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 1.09375rem);
  padding: 0.54688rem 0.875rem;
  line-height: 1.6;
  color: #1e2022;
  content: 'Browse';
  background-color: transparent;
  border-left: inherit;
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(55, 125, 255, 0.1);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(55, 125, 255, 0.1);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 10px rgba(55, 125, 255, 0.1);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #377dff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #377dff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #377dff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e7eaf3;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e7eaf3;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #97a4af;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #97a4af;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #97a4af;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.75rem 0.75rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.0625rem solid #e7eaf3;
}

.nav-tabs .nav-item {
  margin-bottom: -0.0625rem;
}

.nav-tabs .nav-link {
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}

.nav-tabs .nav-link.disabled {
  color: #8c98a4;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #00c9a7;
  background-color: transparent;
  border-color: transparent transparent #00c9a7;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3125rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #377dff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-xxl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.8125rem;
  padding-left: 0.8125rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xxl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #377dff;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: #00c9a7;
}

.navbar-light .navbar-nav .nav-link {
  color: #677788;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #00c9a7;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #598b40;
}

.navbar-light .navbar-toggler {
  color: #677788;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #677788;
}

.navbar-light .navbar-text a {
  color: #377dff;
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color:var(--primary) ;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--primary);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #96a2c1;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%2396a2c1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #96a2c1;
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid #d7d5d2;
  border-radius: 0.75rem;
}
.card:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15) !important;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.3125rem;
}

.card-title {
  margin-bottom: 1.3125rem;
}

.card-subtitle {
  margin-top: -0.65625rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.3125rem;
}

.card-header {
  padding: 1.3125rem 1.3125rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

.card-header:first-child {
  border-radius: 0.6875rem 0.6875rem 0 0;
}

.card-footer {
  padding: 1.3125rem 1.3125rem;
  background-color: #fff;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

.card-footer:last-child {
  border-radius: 0 0 0.6875rem 0.6875rem;
}

.card-header-tabs {
  margin-right: -0.65625rem;
  margin-bottom: -1.3125rem;
  margin-left: -0.65625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.65625rem;
  margin-left: -0.65625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.6875rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.3125rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -0.0625rem;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.3125rem;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #8c98a4;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #1e2022;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #677788;
  background-color: #fff;
  border: 0 solid #e7eaf3;
}

.page-link:hover {
  z-index: 2;
  color: #377dff;
  text-decoration: none;
  background-color: rgba(55, 125, 255, 0.1);
  border-color: rgba(55, 125, 255, 0.1);
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.page-item.disabled .page-link {
  color: #8c98a4;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e7eaf3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.3125em 0.5em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #377dff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #045cff;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #406c8b;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #596d82;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #00c9a7;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #00967d;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #00c9db;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #009aa8;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 219, 0.5);
}

.badge-warning {
  color: #1e2022;
  background-color: #f5ca99;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #1e2022;
  background-color: #f0b26b;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ed4c78;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #e81e55;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
}

.badge-light {
  color: #1e2022;
  background-color: #f9fafc;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #1e2022;
  background-color: #d7deeb;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #132144;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #080e1c;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 33, 68, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f8fafd;
  border-radius: 0.75rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3.59375rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1d4185;
  background-color: #d7e5ff;
  border-color: #c7dbff;
}

.alert-primary hr {
  border-top-color: #aecbff;
}

.alert-primary .alert-link {
  color: #142d5b;
}

.alert-secondary {
  color: #3b4652;
  background-color: #e3e7eb;
  border-color: #d7dde4;
}

.alert-secondary hr {
  border-top-color: #c8d0da;
}

.alert-secondary .alert-link {
  color: #262d34;
}

.alert-success {
  color: #006957;
  background-color: #ccf4ed;
  border-color: #b8f0e6;
}

.alert-success hr {
  border-top-color: #a3ecdf;
}

.alert-success .alert-link {
  color: #00362d;
}

.alert-info {
  color: #006972;
  background-color: #ccf4f8;
  border-color: #b8f0f5;
}

.alert-info hr {
  border-top-color: #a2ebf2;
}

.alert-info .alert-link {
  color: #003a3f;
}

.alert-warning {
  color: #7f6950;
  background-color: #fdf4eb;
  border-color: #fcf0e2;
}

.alert-warning hr {
  border-top-color: #fae4cb;
}

.alert-warning .alert-link {
  color: #604f3c;
}

.alert-danger {
  color: #7b283e;
  background-color: #fbdbe4;
  border-color: #facdd9;
}

.alert-danger hr {
  border-top-color: #f8b6c7;
}

.alert-danger .alert-link {
  color: #551b2b;
}

.alert-light {
  color: #818283;
  background-color: #fefefe;
  border-color: #fdfefe;
}

.alert-light hr {
  border-top-color: #ecf6f6;
}

.alert-light .alert-link {
  color: #686969;
}

.alert-dark {
  color: #0a1123;
  background-color: #d0d3da;
  border-color: #bdc1cb;
}

.alert-dark hr {
  border-top-color: #afb4c0;
}

.alert-dark .alert-link {
  color: black;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e7eaf3;
  border-radius: 0.3125rem;
}

.progress-h-2 {
  height: 2rem !important;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #377dff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem;
}

.list-group-item-action {
  width: 100%;
  color: #677788;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #677788;
  text-decoration: none;
  background-color: #f9fafc;
}

.list-group-item-action:active {
  color: #677788;
  background-color: #f8fafd;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0.0625rem;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -0.0625rem;
  border-left-width: 0.0625rem;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 0.0625rem;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1d4185;
  background-color: #c7dbff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #1d4185;
  background-color: #aecbff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1d4185;
  border-color: #1d4185;
}

.list-group-item-secondary {
  color: #3b4652;
  background-color: #d7dde4;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #3b4652;
  background-color: #c8d0da;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3b4652;
  border-color: #3b4652;
}

.list-group-item-success {
  color: #006957;
  background-color: #b8f0e6;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #006957;
  background-color: #a3ecdf;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #006957;
  border-color: #006957;
}

.list-group-item-info {
  color: #006972;
  background-color: #b8f0f5;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #006972;
  background-color: #a2ebf2;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #006972;
  border-color: #006972;
}

.list-group-item-warning {
  color: #7f6950;
  background-color: #fcf0e2;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #7f6950;
  background-color: #fae4cb;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7f6950;
  border-color: #7f6950;
}

.list-group-item-danger {
  color: #7b283e;
  background-color: #facdd9;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #7b283e;
  background-color: #f8b6c7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7b283e;
  border-color: #7b283e;
}

.list-group-item-light {
  color: #818283;
  background-color: #fdfefe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818283;
  background-color: #ecf6f6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818283;
  border-color: #818283;
}

.list-group-item-dark {
  color: #0a1123;
  background-color: #bdc1cb;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0a1123;
  background-color: #afb4c0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0a1123;
  border-color: #0a1123;
}

.close {
  float: right;
  font-size: 1.09375rem;
  font-weight: 600;
  line-height: 1;
  color: #406c8b;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #858b92;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid #e7eaf3;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.75rem;
}

.toast:not(:last-child) {
  margin-bottom: 1rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 1rem;
  color: #8c98a4;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.toast-body {
  padding: 1rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed; /* Fixed position ensures it stays within viewport */
  top: 0; /* Ensure it covers the entire viewport */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000003;
  display: none;
  overflow: auto; /* Enable scrolling if content exceeds viewport */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  outline: 0;
}

.modal-o {
  display: block !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 1rem; /* Adjust margin for spacing */
  pointer-events: auto;
  max-height: calc(100vh - 3.5rem); /* Limit height to 100% viewport height minus margins */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.modal-dialog.modal-full {
  width: 100vw !important;
  height: 100vh !important;
  border-radius: 0 !important;
  margin: 0;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
  box-shadow: 1px 1px 12px 8px #888888;
  border-radius: 14px;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: auto;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.2rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 33, 68, 0.25);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem !important;
  border-bottom: 1px solid #e7eaf3 !important;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.modal-header .close {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
}
.modal-body .files-body {
  max-height: calc(100vh - 270px) !important;
  overflow: auto !important;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0.0625rem solid #e7eaf3;
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal.left .modal-dialog {
  position: fixed;
  min-width: 70% !important;
  margin: 0;
  border-radius: 0;
  min-height: 100vh;
  animation-name: modal-left;
  animation-duration: 400ms;
}

@keyframes modal-left {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}
.modal.left .modal-dialog .modal-content {
  min-height: 100vh;
  border-radius: 0;
}
.modal.left .modal-dialog .modal-content .modal-body {
  max-height: calc(100vh);
}

.modal.right .modal-dialog {
  position: fixed;
  min-width: 70% !important;
  margin: 0;
  border-radius: 0;
  min-height: 100vh;
  animation-name: modal-right;
  animation-duration: 400ms;
  right: 0;
}

@keyframes modal-right {
  from {
    transform: translate3d(200%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}
.modal.right .modal-dialog .modal-content {
  min-height: 100vh;
  border-radius: 0;
  position: relative;
}
.modal.right .modal-dialog .modal-content .modal-body {
  max-height: calc(100vh);
}

.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0d1730;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0d1730;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #0d1730;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #0d1730;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #0d1730;
  border-radius: 0.3125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 97;
  display: block;
  max-width: 276px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.75rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.75rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: -0.5625rem;
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 0.0625rem;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #132144;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  left: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.75rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 0.0625rem;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #132144;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: -0.5625rem;
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 0.0625rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #132144;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 0.0625rem solid #132144;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  right: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.75rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 0.0625rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #132144;
}

.popover-header {
  padding: 0.75rem 1.09375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  background-color: #132144;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.875rem 1.09375rem;
  color: #bdc5d1;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border-md {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-input-tag {
  background-color: #C8D7BA;
}

.bg-d7d5d2 {
  background-color: #d7d5d2!important;
}

.bg-indigo {
  background-color: #5258e4 !important;
}

.bg-output-tag {
  background-color: #BBBAD7;
}


.bg-soft-primary {
  background-color: rgba(89, 139, 64, 0.1) !important;
}

.bg-gray{
  background-color: #dedede;
}

.bg-dark-primary {
  background-color: #41642E;
}

.bg-soft-warning {
  background-color: #f1f0cb !important;
}

.bg-extra-light-primary{
  background-color: #f1f6ee;
}
.bg-light-primary {
  background-color: rgba(89, 139, 64, 0.4);
}

.bg-yellow{
  background-color: #fff791;
}

.bg-primary {
  background-color: #598b40 !important;
}
.bg-333 {
  background-color: #333 !important;
}
.bg-orange {
  background-color: #ff681e !important;
}

.bg-primary-dark {
  background-color: var(--primary-dark) !important;
}

.bg-red {
  background-color: #d3212c !important;
}



.bg-active {
  background-color: #e0ecff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #045cff !important;
}

.bg-secondary {
  background-color: #343541 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #596d82 !important;
}

.bg-success {
  background-color: #00c9a7 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00967d !important;
}

.bg-info {
  background-color: #00c9db !important;
}
.bg-blue-50 {
  background-color: #e7eecc !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #009aa8 !important;
}

.bg-warning {
  background-color: #f5ca99 !important;
}

.bg-dark-warning {
  background-color: #B87B36 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f0b26b !important;
}

.bg-danger {
  background-color: #ed4c78 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e81e55 !important;
}

.bg-light {
  background-color: #f9fafc !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d7deeb !important;
}


.bg-dark-primary {
  background-color: #41642E !important;
}

.bg-dark {
  background-color: #1B1B1B !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #080e1c !important;
}

.bg-purple {
  background-color: #8799c9 !important;
}
.bg-white {
  background-color: #fff !important;
}

.bg-golden-yellow{
  background-color: #fdbc68 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-white-75 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.bg-f9f9f9 {
  background-color: #f9f9f9 !important;
}

.bg-efefef {
  background-color: #efefef !important;
}

.bg-666 {
  background-color: #666 !important;
}
.bg-999 {
  background-color: #999 !important;
}

.bg-e3e3e3 {
  background-color: #e3e3e3 !important;
}

.bg-dark-5 {
  background-color: rgba(19, 33, 68, 0.07) !important;
}
.bg-dark-25 {
  background-color: rgba(19, 33, 68, 0.25) !important;
}

.bg-dark-50 {
  background-color: rgba(19, 33, 68, 0.5) !important;
}

.bg-dark-75 {
  background-color: rgba(19, 33, 68, 0.75) !important;
}

.bg-photoshop {
  background-image: linear-gradient(
      45deg,
      rgba(176, 176, 176, 0.2) 25%,
      transparent 25%
    ),
    linear-gradient(-45deg, rgba(176, 176, 176, 0.2) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(176, 176, 176, 0.2) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(176, 176, 176, 0.2) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.prism-wrapper {
  overflow-y: auto !important;
  overflow-x: auto !important;
  max-height: 340px;
}
.test-result-wrapper {
  overflow-y: auto !important;
  overflow-x: auto !important;
}


.border {
  border: 0.0625rem solid #d7d5d2 !important;
}
.border-2 {
  border: 2px solid #e7eaf3 !important;
}

.border-3 {
  border: 3px solid #e7eaf3 !important;
}
.border-4 {
  border: 4px solid #e7eaf3 !important;
}

.border-top {
  border-top: 0.0625rem solid #d7d5d2 !important;
}

.border-right {
  border-right: 0.0625rem solid #d7d5d2 !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid #d7d5d2 !important;
}

.border-left {
  border-left: 0.0625rem solid #d7d5d2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #598b40 !important;
}

.border-333 {
  border-color: #333 !important;
}
.border-666 {
  border-color: #666 !important;
}

.border-999 {
  border-color: #999 !important;
}

.border-secondary {
  border-color: #406c8b !important;
}

.border-success {
  border-color: #00c9a7 !important;
}

.border-info {
  border-color: #00c9db !important;
}

.border-warning {
  border-color: #f5ca99 !important;
}

.border-danger {
  border-color: #ed4c78 !important;
}

.border-light {
  border-color: #999 !important;
}

.border-dark {
  border-color: #132144 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-light-gray {
  border-color: #cccccc !important;
}

.border-dark-gray {
  border-color: #666 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-50 {
  border-radius: 50% !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded-top-sm {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-top-md {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-md{
  border-radius:10px !important;

}

.rounded-lg {
  border-radius: 0.75rem !important;
}
.rounded-top-lg {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-top {
  border-radius: 0 !important;
  border-top-left-radius: 0.75rem  !important;
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-right-lg {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom {
  border-radius: 0 !important;
  border-top-left-radius: 0 important;
  border-top-right-radius: 0 important;
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-left-lg {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem!important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-br {
  border-radius: 0 0 0.75rem 0 !important;
}

.rounded-tl-xl {
  border-radius:40px 0 0  0 !important;
}

.rounded-tl-lg {
  border-radius:20px 0 0  0 !important;
}


.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-grid{
  display: grid;
}
.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.gap-10{
  gap: 10px;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1030;
}

.z-index-10000 {
  z-index: 10000 !important;
}
.z-index-100000 {
  z-index: 100000 !important;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.075) !important;
}

.shadow {
  box-shadow: 0px 0.375rem 1.5rem 0px rgba(140, 152, 164, 0.125) !important;
}

.shadow-lg {
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
}
.shadow-xl{
  box-shadow: 0px 0px 31px -1px rgba(0,0,0,0.23);
}

.shadow-lg-inner {
  box-shadow: inset  0px 0px 27px -12px rgba(0, 0, 0, 0.75) !important;
}

.card-shadow {
  box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.075) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-10px {
  width: 10px !important;
}

.w-14px {
  width: 14px !important;
}

.w-20px {
  width: 20px !important;
}

.w-30px {
  width: 30px !important;
}

.w-40px {
  width: 40px !important;
}

.w-50px {
  width: 50px !important;
}
.w-60px {
  width: 60px !important;
}
.w-70px {
  width: 70px !important;
}

.min-w-30px {
  min-width: 30px !important;
}
.min-w-40px {
  min-width: 40px !important;
}
.min-w-50px {
  min-width: 50px !important;
}

.min-w-60px {
  min-width: 60px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-350px {
  min-width: 400px !important;
}

.w-60px {
  width: 60px !important;
}
.w-70px {
  width: 70px !important;
}

.min-w-70px {
 min-width: 70px !important;
}


.max-w-100 {
  max-width: 100% !important;
 }

.max-w-30px {
  max-width: 30px !important;
 }
 .max-w-40px {
  max-width: 40px !important;
 }
.max-w-70px {
  max-width: 70px !important;
 }
 .max-w-60px {
  max-width: 60px !important;
 }

.w-480px{
  width:480px;
}

.w-80px {
  width: 80px !important;
}
.w-100px {
  width: 100px !important;
}

.w-120px {
  width: 120px !important;
}

.w-140px {
  width: 140px !important;
}

.w-160px {
  width: 160px !important;
}
.w-180px {
  width: 180px !important;
}

.w-200px {
  width: 200px !important;
}

.w-240px {
  width: 240px !important;
}

.w-300px {
  width: 300px !important;
}
.w-360px {
  width: 360px !important;
}

.w-400px {
  width: 400px !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}
.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-48 {
  width: 48% !important;
}
.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-100vw {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}



.h-5px {
  height: 5px !important;
}

.h-10px {
  height: 10px !important;
}
.h-14px {
  height: 14px !important;
}
.h-20px {
  height: 20px !important;
}
.min-h-30px {
  min-height: 30px !important;
}
.min-h-40px {
  min-height: 40px !important;
}
.h-30px {
  height: 30px !important;
}
.max-h-100 {
  max-height: 100% !important;
}
.max-h-30px {
  max-height: 30px !important;
}
.max-h-40px {
  max-height: 40px !important;
}
.h-40px {
  height: 40px !important;
}

.h-50px {
  height: 50px !important;
}
.h-60px {
  height: 60px !important;
}

.h-100px {
  height: 100px !important;
}

.min-h-50px{
  min-height: 50px !important;
}



.h-60px {
  height: 60px !important;
}

.min-h-60px{
  min-height: 60px !important;
}

.h-70px {
  height: 70px !important;
}

.min-h-70px{
  min-height: 70px !important;
}

.h-80px {
  height: 80px !important;
}

.h-90px {
  height: 90px !important;
}

.h-100px {
  height: 100px !important;
}
.h-120px {
  height: 120px !important;
}
.h-140px {
  height: 140px !important;
}
.h-160px {
  height: 160px !important;
}
.h-200px {
  height: 200px !important;
}

.h-240px {
  height: 240px !important;
}
.h-300px {
  height: 300px !important;
}
.h-380px {
  height: 380px !important;
}

.h-460px {
  height: 460px !important;
}

.max-h-60px {
  max-height: 60px !important;
  height: auto !important;
}

.max-h-120px {
  max-height: 120px !important;
  height: auto !important;
}

.h-10 {
  height: 10% !important;
}

.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}


.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-screen {
  height: 100vh;
}

.h-auto {
  height: auto !important;
}

.h-60 {
  height: 60% !important;
}

.h-85 {
  height: 85% !important;
}

.h-100vh {
  height: 100vh;
}
.h-95vh {
  height: 95vh;
}
.h-100vh-40 {
  height: calc(100vh - 40px);
}
.h-100vh-60 {
  height: calc(100vh - 60px);
}
.h-100vh-70 {
  height: calc(100vh - 70px);
}
.h-100vh-80 {
  height: calc(100vh - 80px);
}
.h-100vh-100 {
  height: calc(100vh - 100px);
}

.h-100vh-140 {
  min-height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
}

.h-100vh-180 {
  height: calc(100vh - 180px);
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}
.pb-70px{
  padding-bottom: 70px;
}
.pb-80px{
  padding-bottom: 80px;
}

.pb-130px{
  padding-bottom: 130px;
}
.pb-170px{
  padding-bottom: 170px;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}
.pt-60px{
  padding-top: 60px !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pb-100,
.py-100 {
  padding-bottom: 160px !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-130px{
    padding-bottom: 130px !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-60px{
    padding-top: 60px !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 4.5rem !important;
  }

  .pb-md-10,
  .py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 4.5rem !important;
  }

  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .positirion-md-relative{
    position: relative !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 2rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 2rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 2rem !important;
  }
  .m-xxl-6 {
    margin: 2.5rem !important;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 3rem !important;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 3rem !important;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 3rem !important;
  }
  .m-xxl-8 {
    margin: 3.5rem !important;
  }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 4rem !important;
  }
  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 4rem !important;
  }
  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 4rem !important;
  }
  .m-xxl-10 {
    margin: 4.5rem !important;
  }
  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 4.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 2rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 2rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 2rem !important;
  }
  .p-xxl-6 {
    padding: 2.5rem !important;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 3rem !important;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 3rem !important;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 3rem !important;
  }
  .p-xxl-8 {
    padding: 3.5rem !important;
  }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 4rem !important;
  }
  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 4rem !important;
  }
  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 4rem !important;
  }
  .p-xxl-10 {
    padding: 4.5rem !important;
  }
  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 4.5rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xxl-n7 {
    margin: -3rem !important;
  }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -3rem !important;
  }
  .m-xxl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-sm {
  font-size: 0.6rem !important;
} 

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-golden-yellow{
  color: #fdbc68 !important;
}

.text-999 {
  color: #999 !important;
}

.text-yellow{
  color: #EFAE42 !important;
}
.text-red-yellow{
  color : #E15241 !important;
}

.text-primary {
  color: #598b40 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #41642e !important;
}

.text-primary-dark {
  color: var(--primary-dark) !important;
}

.text-secondary {
  color: #406c8b !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #4f6072 !important;
}

.text-success {
  color: #00c9a7 !important;
}

a.text-success:hover,
a.text-success:focus,
.btn-link.text-success:hover,
.btn-link.text-success:focus {
  color: #007d67 !important;
}

.text-info {
  color: #00c9db !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #00838f !important;
}

.text-warning {
  color: #f5ca99 !important;
}
.text-warning-dark {
  color: #B87B36 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #eea653 !important;
}

.text-danger {
  color: #ed4c78 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #d7164a !important;
}

.text-light {
  color: #f9fafc !important;
}

.text-F5F5F5{
  color: #F5F5F5 !important
}

.text-999 {
  color: #999 !important;
}

.text-gray {
  color: #666 !important;
}

.text-light-gray {
  color: #999 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #c6d0e3 !important;
}

.text-dark {
  color: #132144 !important;
}

.text-link {
  color: #336aab !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #020408 !important;
}

.text-body {
  color: #677788 !important;
}

.text-muted {
  color: #8c98a4 !important;
}
.text-passive {
  color: #c3cace !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}
.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 0.0625rem solid #97a4af;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.0625rem solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e7eaf3 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: rgba(231, 234, 243, 0.7);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(231, 234, 243, 0.7);
  }
}

/*----------------------------------------------------------------------
  * Front Dashboard Template version v1.0
  * Copyright 2020 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}


.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}
.w-33 {
  width: 33% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-screen {
  width: 100vw;
}

.w-auto {
  width: auto !important;
}

.w-60 {
  width: 60% !important;
}

.w-85 {
  width: 85% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
}

@media (min-width: 768px) {
  .bg-md-transparent {
    background-color: transparent !important;
  }
  .overflow-md-auto{
    overflow: auto !important;
  }
  .border-md-left {
    border-left: 0.0625rem solid #d7d5d2 !important;
  }
  .border-md-right {
    border-right: 0.0625rem solid #d7d5d2 !important;
  }

  .position-md-fixed{
    position: fixed !important;
  }

  .position-md-absolute{
    position: absolute !important;
  }
  .font-size-md-lg {
    font-size: 1rem !important;
  }
  .font-size-md-xl {
    font-size: 1.2rem !important;
  }
  .font-size-md-2xl{
    font-size: 1.4rem !important ;
  }
  .min-w-md-60px {
    min-width: 60px !important;
  }
  .max-w-md-60px {
    max-width: 60px !important;
   }
   .h-md-20px{
    height:20px !important
   }
   .h-md-80vh {
    height: 80vh;
  }
   .h-md-100vh{
    height: 100vh !important;
   }
   .h-md-100vh-60 {
    height: calc(100vh - 60px) !important;
  }
  .min-h-md-60px{
    min-height: 60px !important;
  }
  .max-h-md-60px{
    max-height: 60px !important;
  }
  .w-md-30px {
    width: 30px !important;
  }
  .w-md-70px {
    width: 70px !important;
  }
  .w-md-300px {
    width: 300px !important;
  }

  .w-md-400px {
    width: 400px !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .w-md-20px{
    width: 20px !important;
  }
  .w-md-60px{
    width: 60px !important;
  }
  .w-md-120px{
    width: 120px !important;
  }
  .w-md-160px{
    width: 160px !important;
  }
  .w-md-200px {
    width: 200px !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .w-lg-50px{
    width: 50px !important;
  }
  .w-lg-300px {
    width: 300px !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-85 {
    width: 85% !important;
  }
}

/*------------------------------------
  Default Styles
------------------------------------*/
html {
  position: relative;
  min-height: 100%;
}

main {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #1e2022;
}

figure {
  margin-bottom: 0;
}

sup {
  right: -0.5rem;
}

dl {
  margin-bottom: 0;
}

dt {
  color: #1e2022;
}

dd {
  margin-bottom: 0.75rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: #fff;
  background-color: #377dff;
}

::selection {
  color: #fff;
  background-color: #377dff;
}
.bg-black-white-gredient-bt{
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 20%, rgba(255,255,255,0) 100%);
}
.bg-black-white-gredient-bt-long{
  background:linear-gradient(0deg, rgba(0,0,0,0.6041010154061625) 42%, rgba(255,255,255,0) 100%);
}
.bg-black-white-gredient-tb{
  background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
}

.bg-primary ::-moz-selection {
  color: #377dff;
  background-color: #fff;
}

.bg-primary ::selection {
  color: #377dff;
  background-color: #fff;
}

/*------------------------------------
  Accessibility
------------------------------------*/
:focus,
a:focus,
button:focus {
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}

.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not([disabled]):not(.disabled):active,
.btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-select:focus,
.page-link:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 transparent;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

/*------------------------------------
  Alert
------------------------------------*/
.alert-dismissible .close {
  line-height: 0.8;
  font-size: 1.53125rem;
}

.alert-soft-dark hr {
  border-color: rgba(189, 197, 209, 0.5);
}

/*------------------------------------
  Alert Styles
------------------------------------*/
.alert-primary {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.alert-primary hr {
  border-top-color: #1e6cff;
}

.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  color: #fff;
  background-color: #406c8b;
  border-color: #406c8b;
}

.alert-secondary hr {
  border-top-color: #647991;
}

.alert-secondary .alert-link {
  color: #fff;
}

.alert-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.alert-success hr {
  border-top-color: #00b092;
}

.alert-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #fff;
  background-color: #00c9db;
  border-color: #00c9db;
}

.alert-info hr {
  border-top-color: #00b2c2;
}

.alert-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: #1e2022;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.alert-warning hr {
  border-top-color: #f3be82;
}

.alert-warning .alert-link {
  color: #1e2022;
}

.alert-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.alert-danger hr {
  border-top-color: #eb3567;
}

.alert-danger .alert-link {
  color: #fff;
}

.alert-light {
  color: #1e2022;
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.alert-light hr {
  border-top-color: #e8ecf4;
}

.alert-light .alert-link {
  color: #1e2022;
}

.alert-dark {
  color: #fff;
  background-color: #132144;
  border-color: #132144;
}

.alert-dark hr {
  border-top-color: #0d1730;
}

.alert-dark .alert-link {
  color: #fff;
}

.alert-soft-primary {
  color: #277;
  background-color: #fff;
  border:2px dashed #59AC57;
}

.alert-soft-primary .alert-link {
  color: #377dff;
}

.alert-soft-primary .alert-link:hover {
  color: #1366ff;
}

.alert-soft-secondary {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
}

.alert-soft-secondary .alert-link {
  color: #406c8b;
}

.alert-soft-secondary .alert-link:hover {
  color: #60748b;
}

.alert-soft-success {
  color: #00c9a7;
  background-color: #E4F5E3;
}

.alert-soft-success .alert-link {
  color: #00c9a7;
}

.alert-soft-success .alert-link:hover {
  color: #00a589;
}

.alert-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.alert-soft-info .alert-link {
  color: #00c9db;
}

.alert-soft-info .alert-link:hover {
  color: #00a8b7;
}

.alert-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.alert-soft-warning .alert-link {
  color: #f5ca99;
}

.alert-soft-warning .alert-link:hover {
  color: #f2b978;
}

.alert-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.alert-soft-danger .alert-link {
  color: #ed4c78;
}

.alert-soft-danger .alert-link:hover {
  color: #ea2c60;
}

.alert-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.alert-soft-light .alert-link {
  color: #f9fafc;
}

.alert-soft-light .alert-link:hover {
  color: #e1e6f0;
}

.alert-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.alert-soft-dark .alert-link {
  color: #132144;
}

.alert-soft-dark .alert-link:hover {
  color: #0b1328;
}

/*------------------------------------
  Animation
------------------------------------*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}



/*------------------------------------
  Navbar
------------------------------------*/
.navbar {
  background-color: #fff;
}

.navbar-nav-wrap-content-left .navbar-nav > .nav-item:not(:last-child),
.navbar-nav-wrap-content-right .navbar-nav > .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.navbar:not(.navbar-vertical).nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.navbar .nav-link {
  color: #132144;
}

.navbar .nav-link:hover {
  color: #0052ea;
}

.navbar .nav-link.active {
  color: #377dff;
}

.navbar .show > .nav-link,
.navbar .active > .nav-link,
.navbar .nav-link.show,
.navbar .nav-link.active {
  color: #377dff;
}

.navbar .nav-pills .show > .nav-link {
  color: #fff;
}

.navbar:not(.navbar-vertical) .nav-link-toggle::after,
.navbar:not(.navbar-vertical) .nav-link-toggle[data-toggle='collapse']::after {
  padding-left: 1.5rem;
}

.navbar .navbar-dropdown-menu .dropdown-menu:not(.navbar-dropdown-sub-menu) {
  position: absolute;
}

.navbar .dropdown-menu.cmz-mega-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar .cmz-sub-menu.dropdown-menu .dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .dropdown-item-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .dropdown-item-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-left: auto;
  transition: 0.3s;
}

/*------------------------------------
  Navbar Options
------------------------------------*/
.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.navbar-fixed ~ .main {
  padding-top: 3.75rem;
}

@media (min-width: 576px) {
  .navbar-fixed-sm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-sm ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-md {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-md ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 992px) {
  .navbar-fixed-lg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-lg ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 1200px) {
  .navbar-fixed-xl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-xl ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 1400px) {
  .navbar-fixed-xxl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-xxl ~ .main {
    padding-top: 3.75rem;
  }
}

.navbar-flush .navbar {
  padding-right: 1rem;
}

.navbar-bordered {
  border-width: 0 0 0.0625rem 0;
  border-style: solid;
  border-color: #e7eaf3;
}

.navbar-bordered.navbar-vertical {
  border-width: 0 0.0625rem 0 0;
}

.navbar-shadow {
  box-shadow: 0px 0.375rem 1.5rem 0px rgba(140, 152, 164, 0.125);
}

.navbar-brand {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-brand-logo {
  height: 2.7125rem;
  object-fit: cover;
}

.navbar-brand-logo-short {
  width: 100%;
  min-width: 2rem;
  max-width: 2rem;
}

.navbar-brand-wrapper {
  position: relative;
  overflow-x: hidden;
}

.navbar-toggler {
  background-color: #f8fafd;
  border-color: transparent;
}

.navbar-toggler:hover {
  background-color: #f0f4fb;
}

.navbar-toggler.btn-white {
  border-color: #e7eaf3;
}

.navbar-toggler.btn-white:hover,
.navbar-toggler.btn-white:focus,
.navbar-toggler.btn-white:active,
.navbar-toggler.btn-white.active {
  color: #00c9a7;
  background-color: #fff;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.navbar-toggler .navbar-toggle-default {
  display: -ms-flexbox;
  display: flex;
}

.navbar-toggler .navbar-toggle-toggled {
  display: none;
}

.navbar-toggler[aria-expanded='true'] .navbar-toggle-default {
  display: none;
}

.navbar-toggler[aria-expanded='true'] .navbar-toggle-toggled {
  display: -ms-flexbox;
  display: flex;
}

.navbar-collapse .navbar-nav {
  margin-top: 0;
}

.navbar-nav-mega-menu {
  width: 100%;
}

.navbar-nav-mega-menu .navbar-nav-mega-menu-title,
.navbar-nav-mega-menu .dropdown-item {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  display: block;
}

/*------------------------------------
  Navbar Extensions
------------------------------------*/
.navbar-input-group {
  min-width: 21rem;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}

.navbar-input-group input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type='search']::-webkit-search-decoration,
.navbar-input-group input[type='search']::-webkit-search-cancel-button,
.navbar-input-group input[type='search']::-webkit-search-results-button,
.navbar-input-group input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.navbar-dropdown-menu {
  position: absolute !important;
  margin-top: 1.25rem;
}

.navbar-dropdown-menu:not(.navbar-dropdown-account) {
  padding: 0;
}

.navbar-dropdown-account {
  margin-top: 0.5rem;
}

.navbar-dropdown-account-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #1e2022;
  background-color: transparent;
  padding: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 6.1875rem;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
}

.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background-color: rgba(55, 125, 255, 0.1);
}

.navbar-dropdown-account-body {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.navbar-card-list-group {
  margin: 0.5rem 0 0.5rem 0.5rem;
}

.navbar-card-list-group .list-group-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*------------------------------------
  Navbar Expand
------------------------------------*/
.navbar-expand .navbar-vertical.navbar-collapse {
  display: block !important;
}

.navbar-expand .navbar-vertical.navbar-collapse .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-expand .navbar-vertical.navbar-vertical-fixed {
  height: 100%;
}

.navbar-expand.navbar {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand.navbar-height {
  height: 3.75rem;
}

.navbar-expand .navbar-brand-wrapper-width {
  width: 100%;
  max-width: 16.25rem;
}

.navbar-expand .navbar-nav .navbar-nav-link {
  line-height: 2.3125rem;
}

.navbar-expand .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
  padding-left: 0;
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
  margin-top: 0rem;
}

.navbar-expand.navbar:not(.navbar-vertical) .nav-indicator-icon {
  display: none;
}

.navbar-expand .navbar-nav-mega-menu-body {
  padding: 1rem 0.5rem;
  margin-left: 0;
  margin-right: 0;
}

.navbar-expand .dropdown-menu {
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
}

.navbar-expand .dropdown-menu .cmz-has-sub-menu .dropdown-menu {
  margin-top: -1rem;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-sm .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-sm.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-sm .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0rem;
  }
  .navbar-expand-sm.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-sm .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .dropdown-menu {
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-sm .dropdown-menu .cmz-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-md .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-md .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-md.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-md .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0rem;
  }
  .navbar-expand-md.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-md .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .dropdown-menu {
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-md .dropdown-menu .cmz-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-lg .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-lg.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-lg .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0rem;
  }
  .navbar-expand-lg.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-lg .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-lg .dropdown-menu .cmz-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-xl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-xl.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-xl .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0rem;
  }
  .navbar-expand-xl.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-xl .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .dropdown-menu {
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xl .dropdown-menu .cmz-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-xxl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xxl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-xxl.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xxl.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-xxl .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-xxl .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-xxl .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0rem;
  }
  .navbar-expand-xxl.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-xxl .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xxl .dropdown-menu {
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xxl .dropdown-menu .cmz-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-sm .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-sm
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-sm .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-sm .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-sm .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-sm .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-sm .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-sm.navbar-dark .navbar-nav .nav-link,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item {
    color: #96a2c1;
  }
  .navbar-expand-sm .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-sm.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-sm .navbar-nav-mega-menu .navbar-nav-mega-menu-title,
  .navbar-expand-sm .navbar-nav-mega-menu .dropdown-item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .cmz-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-sm .cmz-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-sm .cmz-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-sm .navbar-dropdown-submenu-item-invoker::after,
  .navbar-expand-sm .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-sm .cmz-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-sm .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-sm
    .navbar-dropdown-submenu-item.cmz-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-sm
    .cmz-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-sm .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm
    .cmz-has-sub-menu
    .navbar-nav-item
    .cmz-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm
    .cmz-has-sub-menu
    .cmz-sub-menu
    .cmz-has-sub-menu:not(.navbar-nav-item)
    .cmz-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .cmz-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-sm .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-sm
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-sm ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-md .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-md
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-md .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-md .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-md .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-md .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-md .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-md.navbar-dark .navbar-nav .nav-link,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item {
    color: #96a2c1;
  }
  .navbar-expand-md .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-md.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-md .navbar-nav-mega-menu .navbar-nav-mega-menu-title,
  .navbar-expand-md .navbar-nav-mega-menu .dropdown-item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .cmz-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-md .cmz-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-md .cmz-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-md .navbar-dropdown-submenu-item-invoker::after,
  .navbar-expand-md .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-md .cmz-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-md .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-md
    .navbar-dropdown-submenu-item.cmz-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-md
    .cmz-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-md .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-md .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-md .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-md
    .cmz-has-sub-menu
    .navbar-nav-item
    .cmz-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-md
    .cmz-has-sub-menu
    .cmz-sub-menu
    .cmz-has-sub-menu:not(.navbar-nav-item)
    .cmz-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-md .cmz-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-md .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-md
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-md ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-lg
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-lg .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-lg .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-lg .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-lg .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-lg .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg.navbar-dark .navbar-nav .nav-link,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item {
    color: #96a2c1;
  }
  .navbar-expand-lg .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-lg.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-lg .navbar-nav-mega-menu .navbar-nav-mega-menu-title,
  .navbar-expand-lg .navbar-nav-mega-menu .dropdown-item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .cmz-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-lg .cmz-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-lg .cmz-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker::after,
  .navbar-expand-lg .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg .cmz-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg
    .navbar-dropdown-submenu-item.cmz-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg
    .cmz-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-lg .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg
    .cmz-has-sub-menu
    .navbar-nav-item
    .cmz-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg
    .cmz-has-sub-menu
    .cmz-sub-menu
    .cmz-has-sub-menu:not(.navbar-nav-item)
    .cmz-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .cmz-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-lg .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-lg
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-lg ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-xl
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-xl .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-xl .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xl .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-xl .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-xl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl.navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item {
    color: #96a2c1;
  }
  .navbar-expand-xl .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-xl.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-xl .navbar-nav-mega-menu .navbar-nav-mega-menu-title,
  .navbar-expand-xl .navbar-nav-mega-menu .dropdown-item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .cmz-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xl .cmz-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xl .cmz-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker::after,
  .navbar-expand-xl .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl .cmz-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl
    .navbar-dropdown-submenu-item.cmz-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl
    .cmz-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl
    .cmz-has-sub-menu
    .navbar-nav-item
    .cmz-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl
    .cmz-has-sub-menu
    .cmz-sub-menu
    .cmz-has-sub-menu:not(.navbar-nav-item)
    .cmz-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .cmz-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-xl .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-xl
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-xl ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xxl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-xxl
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-xxl .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-xxl .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xxl .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-xxl .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-xxl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xxl.navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item {
    color: #96a2c1;
  }
  .navbar-expand-xxl .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-xxl.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-xxl .navbar-nav-mega-menu .navbar-nav-mega-menu-title,
  .navbar-expand-xxl .navbar-nav-mega-menu .dropdown-item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xxl .cmz-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xxl .cmz-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xxl .cmz-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xxl .navbar-dropdown-submenu-item-invoker::after,
  .navbar-expand-xxl .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xxl .cmz-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xxl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xxl
    .navbar-dropdown-submenu-item.cmz-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xxl
    .cmz-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xxl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-xxl .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl
    .cmz-has-sub-menu
    .navbar-nav-item
    .cmz-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl
    .cmz-has-sub-menu
    .cmz-sub-menu
    .cmz-has-sub-menu:not(.navbar-nav-item)
    .cmz-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl .cmz-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-xxl .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-xxl
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-xxl ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

.navbar-expand.navbar-spacer-y {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-expand .navbar-collapse {
  background-color: #fff;
}

.navbar-expand .navbar-collapse .navbar-nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-expand .navbar-vertical .navbar-vertical-footer {
  display: none;
}

.navbar-expand
  .navbar-light
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand.navbar-light
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu) {
  background-color: transparent;
}

.navbar-expand .navbar-body {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}

.navbar-expand .navbar-body::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-expand .navbar-body::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}

.navbar-expand .navbar-body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.navbar-expand .navbar-dark .navbar-nav .nav-link,
.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-item,
.navbar-expand.navbar-dark .navbar-nav .nav-link,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-item {
  color: #96a2c1;
}

.navbar-expand .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
.navbar-expand.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
  color: #fff;
}

.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-divider,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-divider {
  border-color: #192b58;
}

.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  [href].dropdown-item:hover,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  [href].dropdown-item:hover {
  color: #fff;
}

.navbar-expand .navbar-nav-mega-menu .navbar-nav-mega-menu-title,
.navbar-expand .navbar-nav-mega-menu .dropdown-item {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-expand .cmz-mega-menu {
  max-width: 100% !important;
}

.navbar-expand .cmz-mega-menu-opened .nav-link-toggle::after,
.navbar-expand .cmz-sub-menu-opened .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-expand .navbar-dropdown-submenu-item-invoker::after,
.navbar-expand .dropdown-item-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar-expand .cmz-sub-menu-opened > .dropdown-item-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand .navbar-dropdown-submenu-item-invoker {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar-expand
  .navbar-dropdown-submenu-item.cmz-active
  .navbar-dropdown-submenu-item-invoker {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand .cmz-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
  box-shadow: none;
  padding: 0;
  margin-top: 0;
}

.navbar-expand .dropdown-menu.navbar-dropdown-sub-menu {
  box-shadow: none;
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
}

.navbar-expand .navbar-nav-item {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand .navbar-nav-item .navbar-nav-item {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav-item .dropdown-item {
  padding-left: 1.75rem;
}

.navbar-expand .navbar-nav-item > .dropdown-item {
  padding-left: 1.75rem;
}

.navbar-expand .cmz-has-sub-menu .navbar-nav-item .cmz-sub-menu .dropdown-item {
  padding-left: 1.75rem;
}

.navbar-expand
  .cmz-has-sub-menu
  .cmz-sub-menu
  .cmz-has-sub-menu:not(.navbar-nav-item)
  .cmz-sub-menu {
  padding-left: 1.75rem;
}

.navbar-expand .cmz-has-sub-menu .dropdown-item-toggle {
  padding-right: 0.8125rem;
}

.navbar-expand .navbar.navbar-vertical-detached {
  position: relative;
  width: auto;
}

.navbar-expand .navbar.navbar-vertical-detached .navbar-vertical-container {
  height: auto;
  min-height: auto;
  max-height: none;
}

.navbar-expand ~ .sidebar-detached-content {
  margin-left: 0;
}

.navbar.navbar-container {
  padding-right: 2rem;
  padding-left: 3rem;
}

@media (max-width: 575.98px) {
  [class*='navbar-expand'].navbar {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/*------------------------------------
  Nav Wrap
------------------------------------*/
.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav-wrap-content-left {
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav-wrap-content-right {
  margin-left: auto;
}

.navbar-expand .navbar-nav-wrap-content-left {
  -ms-flex-order: 2;
  order: 2;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2rem;
}

.navbar-expand .navbar-nav-wrap-content-right {
  -ms-flex-order: 4;
  order: 4;
}

.navbar-expand .navbar-nav-wrap-toggle {
  -ms-flex-order: 4;
  order: 4;
  text-align: right;
}

.navbar-expand .navbar-nav-wrap-navbar {
  -ms-flex-order: 3;
  order: 3;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-sm .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-sm .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-sm .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-md .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-md .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-md .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-lg .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-lg .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-lg .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-xl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-xl .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-xl .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-xxl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-xxl .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-xxl .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

.navbar-expand .navbar-nav-wrap {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav-wrap-content-right {
  margin-left: auto;
}

/*------------------------------------
  Vertical Navbar
------------------------------------*/
.navbar-vertical.navbar {
  display: block;
  padding: 0;
}

.navbar-vertical.navbar.collapse:not(.show) {
  display: none;
}

.navbar-vertical.navbar .nav-item {
  white-space: nowrap;
}

.navbar-vertical.navbar .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-vertical.navbar .nav-subtitle-replacer {
  display: none;
  color: #677788;
  text-align: center;
  font-size: 1.3125rem;
  padding: 0.5rem 0;
}

.navbar-vertical-container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.navbar-vertical-fixed .navbar-vertical-container {
  max-height: 100%;
}

.navbar-vertical-content {
  overflow: hidden;
}

.navbar-vertical .nav-link {
  color: #132144;
}

.navbar-vertical .nav-link.disabled {
  color: #8c98a4;
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.75rem;
}

.navbar-vertical .nav-sub {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
}

.navbar-vertical .nav-sub .nav-link {
  font-size: 0.875rem;
}

.navbar-vertical .nav-sub.nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-vertical .nav-subtitle {
  padding: 0.75rem 0.8125rem;
}

.navbar-vertical .navbar-nav.nav-tabs {
  border-bottom-width: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-item:not(:last-child) {
  margin: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .nav-link.active {
  border-bottom-width: 0;
  border-radius: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  border-width: 0 0 0 0.25rem;
  margin-bottom: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .active.nav-link,
.navbar-vertical .navbar-nav.nav-tabs .active .nav-link {
  background-color: rgba(89, 139, 64, 0.1);
  border-left-color: #598b40;
}

.navbar-vertical
  .navbar-nav.nav-tabs
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  margin-right: 0;
}

.navbar-vertical .nav-link-toggle::after,
.navbar-vertical .nav-link-toggle[data-toggle='collapse']::after {
  transition: 0.3s ease;
}

.navbar-vertical .nav-link-toggle[aria-expanded='true']::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical .navbar-vertical-has-menu {
  position: relative;
}

.navbar-vertical .nav-link:hover .nav-indicator-icon,
.navbar-vertical .show > .nav-link > .nav-indicator-icon,
.navbar-vertical .active .nav-indicator-icon {
  color: #00c9a7;
}

.navbar-vertical .nav-compact,
.navbar-vertical .nav-compact-icon {
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-vertical .nav-compact .nav-compact-title,
.navbar-vertical .nav-compact-icon .nav-compact-title {
  max-width: 8.5rem;
}

.navbar-vertical .nav-compact .nav-link,
.navbar-vertical .nav-compact-icon .nav-link {
  display: block;
}

.navbar-vertical .nav-compact .nav-sub .nav-link,
.navbar-vertical .nav-compact-icon .nav-sub .nav-link {
  display: -ms-flexbox;
  display: flex;
}

.navbar-vertical-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16.25rem;
  margin-left: -16.25rem;
}

.navbar-vertical-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 16.25rem;
  margin-left: -16.25rem;
}

.navbar-vertical-fixed .navbar-vertical-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.navbar-vertical-fixed .navbar-vertical-footer-offset {
  height: 100%;
  padding-bottom: 4.25rem;
}

.navbar-vertical:not([class*='container']) .navbar-nav.navbar-nav-lg .nav-link {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.navbar-vertical:not([class*='container']) .navbar-nav .nav-link {
  padding: 0.375rem 1.75rem;
}

.navbar-vertical:not([class*='container']) .navbar-nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 3.25rem;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-vertical:not([class*='container']) .navbar-nav .nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 4.25rem;
}

.navbar-vertical:not([class*='container']) .navbar-nav .nav-title,
.navbar-vertical:not([class*='container']) .navbar-nav .nav-subtitle,
.navbar-vertical:not([class*='container']) .navbar-nav .nav-subtitle-replacer {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-vertical:not([class*='container'])
  .navbar-vertical-without-icons
  .nav
  .nav-link {
  padding-left: 3.25rem;
}

.navbar-vertical:not([class*='container'])
  .navbar-vertical-without-icons
  .nav
  .nav
  .nav-link {
  padding-left: 4.25rem;
}

.navbar-vertical:not([class*='container']) .navbar-nav.nav-compact,
.navbar-vertical:not([class*='container']) .navbar-nav.nav-compact-icon {
  margin-left: 0;
}

.navbar-vertical:not([class*='container']) .navbar-nav.nav-compact .nav-link,
.navbar-vertical:not([class*='container'])
  .navbar-nav.nav-compact-icon
  .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-vertical-footer {
  border-top: 0.0625rem solid #e7eaf3;
  padding: 0.75rem 2rem;
}

.navbar-vertical-footer-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.navbar-vertical-footer-list .navbar-vertical-footer-list-item {
  position: relative;
  margin: 0 0.5rem;
}

.navbar-vertical-footer-dropdown {
  margin-bottom: 1.5rem;
}

.navbar-vertical-detached {
  top: auto;
  left: auto;
  width: 16.25rem;
  margin-left: 0;
  bottom: auto;
}

.navbar-vertical-detached .navbar-vertical-footer {
  position: static;
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-link,
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-subtitle {
  padding-right: 0;
  padding-left: 0;
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav-link {
  padding-right: 0;
  padding-left: 2rem;
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav .nav-link {
  padding-right: 0;
  padding-left: 3rem;
}

/*------------------------------------
  Vertical Navbar Aside
------------------------------------*/
.navbar-vertical-aside-transition-on .main,
.navbar-vertical-aside-transition-on .navbar-vertical-aside,
.navbar-vertical-aside-transition-on .navbar,
.navbar-vertical-aside-transition-on .footer {
  transition: 0.2s ease-in-out;
}

.navbar-vertical-aside-transition-on
  .navbar-vertical-aside
  .navbar-vertical-footer {
  opacity: 0;
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .main {
  padding-left: 0;
}

.navbar-fixed ~ .main .navbar-vertical-aside {
  top: 0;
}

[class*='navbar-vertical-aside-show']
  .navbar.splitted-content-navbar
  .navbar-brand-wrapper {
  display: block;
}

[class*='navbar-vertical-aside-show']
  .navbar-vertical.splitted-content-navbar
  .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
}

[class*='navbar-vertical-aside-show'] .navbar-nav-wrap-content-left {
  padding-left: 0;
}

.navbar-vertical-aside-mini-mode-hidden-elements {
  -ms-flex: 1;
  flex: 1;
}

.navbar-vertical-aside .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 3.75rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-vertical-aside .navbar-brand-logo {
  display: block;
}

.navbar-vertical-aside .navbar-brand-logo-mini {
  height: 2.7125rem;
  object-fit: cover;
  display: none;
}

.navbar-vertical-content {
  height: calc(100% - 3.75rem);
  overflow-y: auto;
}

.navbar-vertical-content::-webkit-scrollbar {
  width: 4px;
}

.navbar-vertical-content::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.nav-footer-item {
  display: none;
}

.nav-footer-item:last-child {
  margin-bottom: 2rem;
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
  position: fixed;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-content {
  height: auto;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer {
  position: static;
  margin-top: -0.0625rem;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer
  .navbar-vertical-footer-list-item {
  position: static;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer
  .navbar-vertical-footer-list-item
  .cmz-unfold {
  position: static;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-offset {
  padding-bottom: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-list-item {
  margin: 0.5rem 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown {
  top: 0;
  left: calc(100% + 1rem);
  margin: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown.cmz-unfold-reverse-y {
  top: auto;
  bottom: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: '';
}

.navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: 0;
}

.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: -16.25rem;
}

.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.splitted-content-navbar.navbar-vertical-aside-initialized {
  margin-left: -21.5rem;
}

.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
  margin-left: -16.25rem;
}

.navbar-vertical-aside-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  opacity: 1;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle {
  position: relative;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle::before {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  content: '';
  width: 5px;
  height: 5px;
  background-color: #bdc5d1;
  opacity: 0;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: opacity 0.3s;
}

.navbar-vertical-aside
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle.active::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle:hover::before {
  background-color: #00c9a7;
}

.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical
  .nav-tabs
  .navbar-vertical-aside-has-menu
  > .active.nav-link {
  border-color: transparent;
}

.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical-aside-has-menu.show
  > .nav-sub {
  display: block;
}

.navbar-vertical-aside-has-menu {
  position: relative;
}

.navbar-vertical-aside-has-menu > .nav {
  display: none;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-left: auto;
  margin-right: 0.1875rem;
  transition: 0.3s ease;
}

.navbar-vertical-aside-has-menu.show > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-container {
  overflow-y: scroll;
  overflow-y: overlay;
}

.navbar-vertical-aside-mini-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside {
  width: 9rem !important;
}

.navbar-vertical-aside-compact-mode .navbar-vertical .navbar-brand {
  margin-right: 0;
}

.navbar-vertical-aside-compact-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}

.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  .nav-icon {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: -0.5rem;
  opacity: 1;
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .nav-subtitle {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav-subtitle-replacer {
  display: block;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}

@media (max-width: 575.98px) {
  .navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    min-width: 100%;
  }
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: '';
}

.navbar-vertical-aside-compact-mini-mode
  .splitted-content-navbar
  .splitted-content-mini
  .navbar-nav {
  margin-left: 0;
}

.navbar-vertical-aside-compact-mini-mode
  .splitted-content-navbar
  .splitted-content-mini
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: 0.75rem;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container,
.navbar-vertical-aside-mini-mode .navbar-vertical-container {
  position: static;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar,
.navbar-vertical-aside-mini-mode .navbar-vertical-container::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-has-menu,
.navbar-vertical-aside-mini-mode .navbar-vertical-aside-has-menu {
  position: static;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: inline-block;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-absolute:not([class*='container'])
  .nav
  .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-absolute:not([class*='container'])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-absolute:not([class*='container'])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-absolute:not([class*='container'])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*='container'])
  .nav
  .nav
  .nav-link {
  padding-left: 2.25rem;
}

.navbar-vertical-aside .splitted-content-mini {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
}

.navbar-vertical-aside-mobile-overlay,
.navbar-vertical-aside-sub-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s ease;
}

.navbar-vertical-aside-mobile-overlay {
  background-color: rgba(19, 33, 68, 0.25);
}

.navbar-vertical-aside-mobile-overlay-transparent {
  background-color: transparent;
}

.has-navbar-vertical-aside.navbar-vertical-aside-sub-menu-opened
  .navbar-vertical-aside-sub-menu-overlay,
.has-navbar-vertical-aside:not(.navbar-vertical-aside-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  z-index: 99;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.navbar-vertical.navbar-expand {
  -ms-flex-flow: column;
  flex-flow: column;
}

.navbar-vertical.navbar-expand .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-vertical-aside-show .main {
  padding-left: 16.25rem;
}

.navbar-vertical-aside-show .footer {
  margin-left: 16.25rem;
}

.navbar-vertical-aside-show .navbar:not(.navbar-vertical) {
  margin-left: 16.25rem;
}

.navbar-vertical-aside-show .splitted-content-navbar {
  margin-left: 21.5rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .main {
  padding-left: 9rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .footer {
  margin-left: 9rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode
  .navbar:not(.navbar-vertical) {
  margin-left: 9rem;
}

.navbar-vertical-aside-show.splitted-content .main {
  padding-left: 21.5rem;
}

.navbar-vertical-aside-show.splitted-content .footer {
  margin-left: 21.5rem;
}

.navbar-vertical-aside-show.splitted-content .navbar:not(.navbar-vertical) {
  margin-left: 21.5rem;
}

.navbar-vertical-aside-show .navbar-vertical-aside {
  margin-left: 0;
}

.navbar-vertical-aside-show
  .navbar:not(.navbar-vertical-aside)
  .navbar-brand-wrapper {
  display: none;
}

.navbar-vertical-aside-show
  .navbar-vertical.splitted-content-navbar
  .navbar-brand-wrapper {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar:not(.navbar-vertical) {
  margin-left: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .main {
  padding-left: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .footer {
  margin-left: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside {
  width: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .splitted-content-navbar {
  width: 21.5rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .navbar:not(.navbar-vertical),
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .splitted-content-main,
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .footer {
  padding-left: 21.5rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .navbar:not(.navbar-vertical) {
  margin-left: 2rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo-mini {
  display: block;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo {
  display: none;
}

.navbar-vertical-aside-show:not(.navbar-vertical-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  display: none;
}

.navbar-vertical-aside-show
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align,
.navbar-vertical-aside-show
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align {
  display: none;
}

.navbar-vertical-aside-show .navbar-vertical-aside-toggle {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-content {
  overflow-y: scroll;
  overflow-y: overlay;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav,
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav
  .nav,
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav
  .nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav-subtitle {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav-subtitle-replacer {
  display: block;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: '';
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  width: 2rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .nav-footer-item {
  display: block;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-footer {
  display: none;
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-sm .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-sm .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-sm .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-sm .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-sm
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-sm
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 3.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-sm:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-sm
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align,
  .navbar-vertical-aside-show-sm
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align {
    display: none;
  }
  .navbar-vertical-aside-show-sm .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-md .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-md .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-md .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-md .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-md.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-md
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-md
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-md:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-md
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align,
  .navbar-vertical-aside-show-md
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align {
    display: none;
  }
  .navbar-vertical-aside-show-md .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-lg .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-lg .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-lg .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-lg .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-lg
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-lg
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-lg:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-lg
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align,
  .navbar-vertical-aside-show-lg
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align {
    display: none;
  }
  .navbar-vertical-aside-show-lg .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-xl .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xl .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-left: 14.25rem;
  }
  .navbar-vertical-aside-show-xl .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-xl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xl
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 4.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
    padding-left: 4.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 4.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 4.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-xl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-xl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align,
  .navbar-vertical-aside-show-xl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align {
    display: none;
  }
  .navbar-vertical-aside-show-xl .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-xxl .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xxl .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xxl .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xxl .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xxl.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-xxl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xxl
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-xxl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-xxl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align,
  .navbar-vertical-aside-show-xxl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align {
    display: none;
  }
  .navbar-vertical-aside-show-xxl .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: '';
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-vertical-aside-show-sm .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-vertical-aside-show-md .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-md.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-md.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-vertical-aside-show-lg .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-vertical-aside-show-xl .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside .navbar-height {
    height: auto;
  }
  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside
    .menu-toggle-button {
    right: -40px;
    display: block !important;
  }
}

@media (max-width: 1399.98px) {
  .navbar-vertical-aside-show-xxl .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

.navbar-vertical-aside-show .navbar-brand-logo-mini {
  display: none;
}

.navbar-vertical-aside-show.has-navbar-vertical-aside .navbar-height {
  height: 3.75rem;
}

.navbar-vertical-aside-show.has-navbar-vertical-aside
  .navbar-nav-wrap-content-left {
  display: -ms-flexbox;
  display: flex;
}

.navbar-vertical-aside-show.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside {
  margin-left: -100%;
}

.has-navbar-vertical-aside .navbar-vertical-aside-toggle-short-align {
  display: block;
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-short-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}

.has-navbar-vertical-aside .navbar-vertical-aside-toggle-full-align {
  display: none;
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-full-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}

.splitted-content-navbar .navbar-vertical-aside-toggle-short-align {
  display: block;
}

.navbar-vertical-aside-closed-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align,
.navbar-vertical-aside-mini-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}

.splitted-content-navbar .navbar-vertical-aside-toggle-full-align {
  display: none;
}

.navbar-vertical-aside-closed-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align,
.navbar-vertical-aside-mini-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*------------------------------------
  Navbar Skins
------------------------------------*/
.navbar-light {
  background-color: #f9fafc;
}

.navbar-light .navbar-collapse {
  background-color: transparent;
}

.navbar-light .navbar-nav .nav-link {
  color: #132144;
}

.navbar-light .navbar-brand-wrapper {
  background-color: #f9fafc;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

.navbar-dark {
  background-color: #132144 !important;
}

.navbar-dark .navbar-collapse {
  background-color: transparent;
}

.navbar-dark .navbar-toggler {
  border-color: transparent;
}

.navbar-dark .navbar-vertical-aside-has-menu.show > .nav-link-toggle::before,
.navbar-dark .navbar-vertical-aside-has-menu > .nav-link-toggle.active::before,
.navbar-dark .navbar-vertical-aside-has-menu > .nav-link-toggle:hover::before {
  background-color: #fff;
}

.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .show
  > .nav-link,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .active
  > .nav-link,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .nav-link.show,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-indicator-icon {
  color: #96a2c1;
}

.navbar-dark .navbar-nav .nav-link {
  color: #96a2c1;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .dropdown-item.active {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.active .nav-indicator-icon,
.navbar-dark .navbar-nav .nav-link:hover .nav-indicator-icon {
  color: #fff;
}

.navbar-dark .navbar-nav .show > .nav-link > .nav-indicator-icon,
.navbar-dark .navbar-nav .active .nav-indicator-icon {
  color: #96a2c1;
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link {
  color: #132144;
}

.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link:hover,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link.active {
  color: #0052ea;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link,
.navbar-vertical-aside-mini-mode .navbar-dark .navbar-nav .nav-sub .nav-link {
  color: #677788;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link:hover,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link:hover {
  color: #377dff;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .show
  > .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .active
  > .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .nav-link.show,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu.show
  .nav-sub
  .nav-link.active,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .show
  > .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .active
  > .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .nav-link.show,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu.show
  .nav-sub
  .nav-link.active {
  color: #377dff;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .show
  > .nav-link
  > .nav-indicator-icon,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .active
  .nav-indicator-icon,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-link:hover
  .nav-indicator-icon,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .show
  > .nav-link
  > .nav-indicator-icon,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .active
  .nav-indicator-icon,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-link:hover
  .nav-indicator-icon {
  color: #377dff;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  .nav-icon {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .nav-compact-title {
  color: #96a2c1;
}

.navbar-dark .nav-compact > .nav-item > .nav-link:hover,
.navbar-dark .nav-compact > .nav-item > .nav-link.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .nav-compact > .nav-item > .nav-link:hover .nav-compact-title,
.navbar-dark .nav-compact > .nav-item > .nav-link.active .nav-compact-title {
  color: #fff;
}

.navbar-dark .nav-compact > .show > .nav-link {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .nav-compact > .show > .nav-link .nav-compact-title {
  color: #fff;
}

.navbar-dark .nav-compact-icon .nav-icon {
  color: #96a2c1;
}

.navbar-dark .nav-compact-icon .nav-icon:focus,
.navbar-dark .nav-compact-icon .nav-icon:hover,
.navbar-dark .nav-compact-icon .nav-icon.active {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .nav-compact-icon .nav-link.active .nav-icon {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .nav-compact-icon > .show > .nav-link .nav-icon {
  color: #96a2c1;
}

.navbar-dark.navbar-bordered,
.navbar-dark.navbar-bordered .navbar-vertical-footer {
  border-color: #192b58;
}

.navbar-dark .input-group-hover-light .form-control,
.navbar-dark .input-group-hover-light .form-control:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .input-group-hover-light .form-control:focus {
  background-color: #fff;
}

.navbar-dark .navbar-nav .nav-divider {
  border-color: #192b58;
}

/*------------------------------------
  Search Fullwidth
------------------------------------*/
.search-fullwidth {
  position: fixed;
  top: 0;
  left: 0 !important;
  width: 100%;
  z-index: 101;
}

.search-fullwidth .form-control {
  height: 3.75rem;
}

/*------------------------------------
  Avatar Styles
------------------------------------*/
.avatar-primary .avatar-initials {
  color: #fff;
  background-color: var(--primary);
}

.avatar-status-primary {
  color: #fff;
  background-color: #377dff;
}

.avatar-secondary .avatar-initials {
  color: #fff;
  background-color: #406c8b;
}

.avatar-status-secondary {
  color: #fff;
  background-color: #406c8b;
}

.avatar-success .avatar-initials {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-status-success {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-info .avatar-initials {
  color: #fff;
  background-color: #00c9db;
}

.avatar-status-info {
  color: #fff;
  background-color: #00c9db;
}

.avatar-warning .avatar-initials {
  color: #1e2022;
  background-color: #f5ca99;
}

.avatar-status-warning {
  color: #1e2022;
  background-color: #f5ca99;
}

.avatar-danger .avatar-initials {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-status-danger {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-light .avatar-initials {
  color: #1e2022;
  background-color: #f9fafc;
}

.avatar-status-light {
  color: #1e2022;
  background-color: #f9fafc;
}

.avatar-dark .avatar-initials {
  color: #fff;
  background-color: #132144;
}

.avatar-status-dark {
  color: #fff;
  background-color: #132144;
}

.avatar-soft-primary .avatar-initials {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.avatar-status-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.avatar-soft-secondary .avatar-initials {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-status-soft-secondary {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-soft-success .avatar-initials {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-status-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-soft-info .avatar-initials {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.avatar-status-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.avatar-soft-warning .avatar-initials {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-status-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-soft-danger .avatar-initials {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-status-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-soft-light .avatar-initials {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.avatar-status-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.avatar-soft-dark .avatar-initials {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.avatar-status-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

/*------------------------------------
  Avatar
------------------------------------*/
.avatar {
  position: relative;
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}

.avatar:not(img) {
  background-color: #fff;
}

.avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 0.3125rem;
}

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.09375rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.3125rem;
}

.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar-circle .avatar-initials,
.avatar-circle .avatar-img {
  border-radius: 50%;
}

.avatar-border-lg {
  border: 0.1875rem solid #fff;
}

.avatar-border-dashed {
  border: 0.0625rem dashed #e7eaf3;
}

.avatar-border-dashed .avatar-initials {
  color: #8c98a4;
  background-color: #fff;
  transition: 0.3s;
}

.avatar-border-dashed:hover .avatar-initials {
  color: #377dff;
}

/*------------------------------------
  Avatar Group
------------------------------------*/
.avatar-group {
  position: relative;
  z-index: 2;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar-xs,
.avatar-group .avatar-sm,
.avatar-group .avatar {
  padding: 0.125rem 0.125rem;
}

.avatar-group .avatar-lg,
.avatar-group .avatar-xl,
.avatar-group .avatar-xxl {
  padding: 0.25rem 0.25rem;
}

.avatar-group.avatar-circle .avatar {
  border-radius: 50%;
}

.avatar-group.avatar-circle .avatar .avatar-initials,
.avatar-group.avatar-circle .avatar .avatar-img {
  border-radius: 50%;
}

/*------------------------------------
  Avatar Group Sizes
------------------------------------*/
.avatar-group .avatar {
  width: 2.625rem;
  height: 2.625rem;
}

.avatar-group .avatar .avatar-initials {
  font-size: 1.09375rem;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group-xs .avatar {
  width: 1.53125rem;
  height: 1.53125rem;
}

.avatar-group-xs .avatar .avatar-initials {
  font-size: 0.875rem;
}

.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}

.avatar-group-sm .avatar {
  width: 2.1875rem;
  height: 2.1875rem;
}

.avatar-group-sm .avatar .avatar-initials {
  font-size: 0.98438rem;
}

.avatar-group-sm .avatar + .avatar {
  margin-left: -0.875rem;
}

.avatar-group-lg .avatar {
  width: 3.9375rem;
  height: 3.9375rem;
}

.avatar-group-lg .avatar .avatar-initials {
  font-size: 1.53125rem;
}

.avatar-group-lg .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xl .avatar {
  width: 4.92188rem;
  height: 4.92188rem;
}

.avatar-group-xl .avatar .avatar-initials {
  font-size: 1.96875rem;
}

.avatar-group-xl .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xxl .avatar {
  width: 7.875rem;
  height: 7.875rem;
}

.avatar-group-xxl .avatar .avatar-initials {
  font-size: 2.625rem;
}

.avatar-group-xxl .avatar + .avatar {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Ratio
------------------------------------*/
.avatar.avatar-4by3 {
  width: 3.5rem;
}

.avatar-xss.avatar-4by3 {
  width: 1.33333rem;
}

.avatar-xs.avatar-4by3 {
  width: 2.04167rem;
}

.avatar-sm.avatar-4by3 {
  width: 2.91667rem;
}

.avatar-lg.avatar-4by3 {
  width: 5.25rem;
}

.avatar-xl.avatar-4by3 {
  width: 6.5625rem;
}

.avatar-xxl.avatar-4by3 {
  width: 10.5rem;
}

/*------------------------------------
  Avatar Sizes
------------------------------------*/
.avatar.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}

.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}

.avatar.avatar-circle .avatar-lg-status {
  bottom: -0.28437rem;
  right: -0.28437rem;
}

.avatar-xss {
  width: 1rem;
  height: 1rem;
}

.avatar-xss .avatar-initials {
  font-size: 0.875rem;
}

.avatar-xs {
  width: 1.53125rem;
  height: 1.53125rem;
}

.avatar-xs .avatar-initials {
  font-size: 0.875rem;
}

.avatar-xs.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}

.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.10254rem;
  right: -0.10254rem;
}

.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.28437rem;
  right: -0.28437rem;
}

.avatar-sm {
  width: 2.1875rem;
  height: 2.1875rem;
}

.avatar-sm .avatar-initials {
  font-size: 0.98438rem;
}

.avatar-sm.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}

.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -0.08203rem;
  right: -0.08203rem;
}

.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -0.35547rem;
  right: -0.35547rem;
}

.avatar-lg {
  width: 3.9375rem;
  height: 3.9375rem;
}

.avatar-lg .avatar-initials {
  font-size: 1.53125rem;
}

.avatar-lg.avatar-circle .avatar-status {
  bottom: -2.1px;
  right: -2.1px;
}

.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 0.08203rem;
  right: 0.08203rem;
}

.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.17773rem;
  right: -0.17773rem;
}

.avatar-xl {
  width: 4.92188rem;
  height: 4.92188rem;
}

.avatar-xl .avatar-initials {
  font-size: 1.96875rem;
}

.avatar-xl.avatar-circle .avatar-status {
  bottom: 2.625px;
  right: 2.625px;
}

.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 0.20508rem;
  right: 0.20508rem;
}

.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 0.11849rem;
  right: 0.11849rem;
}

.avatar-xxl {
  width: 7.875rem;
  height: 7.875rem;
}

.avatar-xxl .avatar-initials {
  font-size: 2.625rem;
}

.avatar-xxl.avatar-circle .avatar-status {
  bottom: 6px;
  right: 6px;
}

.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 0.82031rem;
  right: 0.82031rem;
}

.avatar-xxl.avatar-circle .avatar-lg-status {
  bottom: 0.35547rem;
  right: 0.35547rem;
}

/*------------------------------------
  Avatar Status
------------------------------------*/
.avatar-status {
  position: absolute;
  bottom: -8.4px;
  right: -8.4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 21px;
  height: 21px;
  line-height: 1;
  font-size: 0.71094rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -4.2px;
  right: -4.2px;
  width: 0.82031rem;
  height: 0.82031rem;
  font-size: 0.4375rem;
}

.avatar-lg-status {
  width: 1.42188rem;
  height: 1.42188rem;
  font-size: 0.65625rem;
}

/*------------------------------------
  Avatar Uploader
------------------------------------*/
.avatar-uploader {
  cursor: pointer;
  display: inline-block;
  transition: 0.2s;
  margin-bottom: 0;
}

.avatar-uploader:hover {
  border-color: #377dff;
}

.avatar-uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 33, 68, 0.25);
  border-radius: 50%;
  transition: 0.2s;
}

.avatar-uploader-trigger {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
}

.avatar-uploader-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s;
}

.avatar-uploader:hover .avatar-uploader-icon {
  color: #fff;
  background-color: #377dff;
}

.avatar-lg .avatar-uploader-icon {
  font-size: 0.75rem;
  width: 1.3125rem;
  height: 1.3125rem;
}

.avatar-xl .avatar-uploader-icon {
  font-size: 0.8125rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.avatar-xxl .avatar-uploader-icon {
  width: 2.1875rem;
  height: 2.1875rem;
}

/*------------------------------------
  Badge Soft
------------------------------------*/
.badge-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

a.badge-soft-primary:hover,
a.badge-soft-primary:focus {
  color: #fff;
  background-color: #377dff;
}

.badge-soft-secondary {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
}

a.badge-soft-secondary:hover,
a.badge-soft-secondary:focus {
  color: #fff;
  background-color: #406c8b;
}

.badge-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

a.badge-soft-success:hover,
a.badge-soft-success:focus {
  color: #fff;
  background-color: #00c9a7;
}

.badge-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

a.badge-soft-info:hover,
a.badge-soft-info:focus {
  color: #fff;
  background-color: #00c9db;
}

.badge-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

a.badge-soft-warning:hover,
a.badge-soft-warning:focus {
  color: #1e2022;
  background-color: #f5ca99;
}

.badge-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

a.badge-soft-danger:hover,
a.badge-soft-danger:focus {
  color: #fff;
  background-color: #ed4c78;
}

.badge-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

a.badge-soft-light:hover,
a.badge-soft-light:focus {
  color: #1e2022;
  background-color: #f9fafc;
}

.badge-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

a.badge-soft-dark:hover,
a.badge-soft-dark:focus {
  color: #fff;
  background-color: #132144;
}

.badge-soft-warning {
  color: #ec9a3c !important;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/
.breadcrumb .breadcrumb-item {
  color: #1e2022;
  font-size: 0.8125rem;
}

.breadcrumb .breadcrumb-link {
  color: #8c98a4;
}

.breadcrumb .breadcrumb-link:hover {
  color: #0052ea;
}

.breadcrumb-no-gutter {
  padding: 0;
}

/*------------------------------------
  Blockquote
------------------------------------*/
.blockquote {
  font-size: 0.875rem;
  border-left: 0.1875rem solid #e7eaf3;
  padding-left: 0.75rem;
  margin-bottom: 0;
}

.blockquote-sm {
  font-size: 0.8125rem;
  padding-left: 0.5rem;
}

.blockquote-footer {
  margin-top: 1rem;
}

/*------------------------------------
  Custom Buttons
------------------------------------*/

.btn-dashed-outline {
  border-style: dashed;
}

.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.btn-white {
  background-color: #fff;
  border-color: #e7eaf3;
}

.btn-white.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white:active,
.btn-white.active {
  background-color: #fff !important;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.btn-white:hover.dropdown-toggle::after,
.btn-white:focus.dropdown-toggle::after,
.btn-white.focus.dropdown-toggle::after,
.btn-white:active.dropdown-toggle::after,
.btn-white.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-white.disabled,
.btn-white:disabled {
  color: #8c98a4;
  background-color: rgba(231, 234, 243, 0.5);
}

.btn-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-primary:hover.dropdown-toggle::after,
.btn-outline-primary:focus.dropdown-toggle::after,
.btn-outline-primary.focus.dropdown-toggle::after,
.btn-outline-primary:active.dropdown-toggle::after,
.btn-outline-primary.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-secondary:hover.dropdown-toggle::after,
.btn-outline-secondary:focus.dropdown-toggle::after,
.btn-outline-secondary.focus.dropdown-toggle::after,
.btn-outline-secondary:active.dropdown-toggle::after,
.btn-outline-secondary.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-success:hover.dropdown-toggle::after,
.btn-outline-success:focus.dropdown-toggle::after,
.btn-outline-success.focus.dropdown-toggle::after,
.btn-outline-success:active.dropdown-toggle::after,
.btn-outline-success.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-info:hover.dropdown-toggle::after,
.btn-outline-info:focus.dropdown-toggle::after,
.btn-outline-info.focus.dropdown-toggle::after,
.btn-outline-info:active.dropdown-toggle::after,
.btn-outline-info.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-warning:hover.dropdown-toggle::after,
.btn-outline-warning:focus.dropdown-toggle::after,
.btn-outline-warning.focus.dropdown-toggle::after,
.btn-outline-warning:active.dropdown-toggle::after,
.btn-outline-warning.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-danger:hover.dropdown-toggle::after,
.btn-outline-danger:focus.dropdown-toggle::after,
.btn-outline-danger.focus.dropdown-toggle::after,
.btn-outline-danger:active.dropdown-toggle::after,
.btn-outline-danger.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-light:hover.dropdown-toggle::after,
.btn-outline-light:focus.dropdown-toggle::after,
.btn-outline-light.focus.dropdown-toggle::after,
.btn-outline-light:active.dropdown-toggle::after,
.btn-outline-light.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-dark:hover.dropdown-toggle::after,
.btn-outline-dark:focus.dropdown-toggle::after,
.btn-outline-dark.focus.dropdown-toggle::after,
.btn-outline-dark:active.dropdown-toggle::after,
.btn-outline-dark.active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

/*------------------------------------
  Button Sizes
------------------------------------*/
/* Wide */
.btn-wide {
  min-width: 7rem;
}

/* Extra Small */
.btn-xs {
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.24063rem 0.65625rem;
  border-radius: 0.25rem;
}

/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/

.btn-ghost-primary {
  color: #377dff;
  background-color: transparent;
}

.btn-ghost-primary:hover,
.btn-ghost-primary:focus,
.btn-ghost-primary.focus,
.btn-ghost-primary:active,
.btn-ghost-primary.active {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-ghost-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-primary.btn-no-focus[href]:focus,
.btn-ghost-primary.btn-no-focus[href].focus {
  color: #377dff;
  background-color: unset;
}

.btn-ghost-primary.btn-no-focus[href]:focus:hover,
.btn-ghost-primary.btn-no-focus[href].focus:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-ghost-secondary {
  color: #406c8b;
  background-color: transparent;
}

.btn-ghost-secondary:hover,
.btn-ghost-secondary:focus,
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary.active {
  color: #000 !important;
  background-color: #efefef !important;
}

.btn-ghost-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-secondary.btn-no-focus[href]:focus,
.btn-ghost-secondary.btn-no-focus[href].focus {
  color: #406c8b;
  background-color: unset;
}

.btn-ghost-secondary.btn-no-focus[href]:focus:hover,
.btn-ghost-secondary.btn-no-focus[href].focus:hover {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
}

.btn-ghost-success {
  color: #00c9a7;
  background-color: transparent;
}

.btn-ghost-success:hover,
.btn-ghost-success:focus,
.btn-ghost-success.focus,
.btn-ghost-success:active,
.btn-ghost-success.active {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.btn-ghost-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-success.btn-no-focus[href]:focus,
.btn-ghost-success.btn-no-focus[href].focus {
  color: #00c9a7;
  background-color: unset;
}

.btn-ghost-success.btn-no-focus[href]:focus:hover,
.btn-ghost-success.btn-no-focus[href].focus:hover {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.btn-ghost-info {
  color: #00c9db;
  background-color: transparent;
}

.btn-ghost-info:hover,
.btn-ghost-info:focus,
.btn-ghost-info.focus,
.btn-ghost-info:active,
.btn-ghost-info.active {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.btn-ghost-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-info.btn-no-focus[href]:focus,
.btn-ghost-info.btn-no-focus[href].focus {
  color: #00c9db;
  background-color: unset;
}

.btn-ghost-info.btn-no-focus[href]:focus:hover,
.btn-ghost-info.btn-no-focus[href].focus:hover {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.btn-ghost-warning {
  color: #f5ca99;
  background-color: transparent;
}

.btn-ghost-warning:hover,
.btn-ghost-warning:focus,
.btn-ghost-warning.focus,
.btn-ghost-warning:active,
.btn-ghost-warning.active {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.btn-ghost-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-warning.btn-no-focus[href]:focus,
.btn-ghost-warning.btn-no-focus[href].focus {
  color: #f5ca99;
  background-color: unset;
}

.btn-ghost-warning.btn-no-focus[href]:focus:hover,
.btn-ghost-warning.btn-no-focus[href].focus:hover {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.btn-ghost-danger {
  color: #ed4c78;
  background-color: transparent;
}

.btn-ghost-danger:hover,
.btn-ghost-danger:focus,
.btn-ghost-danger.focus,
.btn-ghost-danger:active,
.btn-ghost-danger.active {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.btn-ghost-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-danger.btn-no-focus[href]:focus,
.btn-ghost-danger.btn-no-focus[href].focus {
  color: #ed4c78;
  background-color: unset;
}

.btn-ghost-danger.btn-no-focus[href]:focus:hover,
.btn-ghost-danger.btn-no-focus[href].focus:hover {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.btn-ghost-light {
  color: #f9fafc;
  background-color: transparent;
}

.btn-ghost-light:hover,
.btn-ghost-light:focus,
.btn-ghost-light.focus,
.btn-ghost-light:active,
.btn-ghost-light.active {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-ghost-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-light.btn-no-focus[href]:focus,
.btn-ghost-light.btn-no-focus[href].focus {
  color: #f9fafc;
  background-color: unset;
}

.btn-ghost-light.btn-no-focus[href]:focus:hover,
.btn-ghost-light.btn-no-focus[href].focus:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-ghost-dark {
  color: #132144;
  background-color: transparent;
}

.btn-ghost-dark:hover,
.btn-ghost-dark:focus,
.btn-ghost-dark.focus,
.btn-ghost-dark:active,
.btn-ghost-dark.active {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.btn-ghost-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-dark.btn-no-focus[href]:focus,
.btn-ghost-dark.btn-no-focus[href].focus {
  color: #132144;
  background-color: unset;
}

.btn-ghost-dark.btn-no-focus[href]:focus:hover,
.btn-ghost-dark.btn-no-focus[href].focus:hover {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.btn-ghost-secondary:hover,
.btn-ghost-secondary:focus,
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active {
  color: #377dff;
}

.btn-ghost-secondary:hover.dropdown-toggle::after,
.btn-ghost-secondary:focus.dropdown-toggle::after,
.btn-ghost-secondary.focus.dropdown-toggle::after,
.btn-ghost-secondary:active.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-secondary.btn-no-focus[href]:focus:hover,
.btn-ghost-secondary.btn-no-focus[href].focus:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

/*------------------------------------
  Soft Button Styles
------------------------------------*/
.btn-soft-primary {
  color: #598b40;
  background-color: rgba(89, 139, 64, 0.1);
  border-color: #598b40;
}
.btn-soft-primary:hover,
.btn-soft-primary:active,
.btn-soft-primary.active {
  color: #fff !important;
  background-color: #41642e !important;
  box-shadow: 0 4px 11px #598b40;
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: #41642e;
  background-color: rgba(89, 139, 64, 0.1);
  box-shadow: none;
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-soft-primary:disabled:hover {
  color: #41642e !important;
  background-color: rgba(89, 139, 64, 0.1) !important;
  box-shadow: none !important;
  opacity: 0.4 !important;
}

.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled).active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 139, 64, 1);
}

.btn-soft-secondary {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
  border-color: transparent;
}

.btn-soft-secondary:hover,
.btn-soft-secondary:active,
.btn-soft-secondary.active {
  color: #fff;
  background-color: #406c8b;
  box-shadow: 0 4px 11px rgba(113, 134, 157, 0.1);
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
  box-shadow: none;
}

.btn-soft-secondary:not(:disabled):not(.disabled):active,
.btn-soft-secondary:not(:disabled):not(.disabled).active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #fff;
  background-color: #406c8b;
}

.btn-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active {
  color: #fff;
  background-color: #00c9a7;
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.1);
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  box-shadow: none;
}

.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled).active,
.show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
}

.btn-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  border-color: transparent;
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active {
  color: #fff;
  background-color: #00c9db;
  box-shadow: 0 4px 11px rgba(0, 201, 219, 0.1);
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  box-shadow: none;
}

.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled).active,
.show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #00c9db;
}

.btn-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active {
  color: #1e2022;
  background-color: #f5ca99;
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.1);
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  box-shadow: none;
}

.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled).active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #1e2022;
  background-color: #f5ca99;
}

.btn-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  border-color: transparent;
}

.btn-soft-danger:hover,
.btn-soft-danger:active,
.btn-soft-danger.active {
  color: #fff;
  background-color: #ed4c78;
  box-shadow: 0 4px 11px rgba(237, 76, 120, 0.1);
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  box-shadow: none;
}

.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled).active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed4c78;
}

.btn-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  border-color: transparent;
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active {
  color: #1e2022;
  background-color: #f9fafc;
  box-shadow: 0 4px 11px rgba(249, 250, 252, 0.1);
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  box-shadow: none;
}

.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled).active,
.show > .btn-soft-light.dropdown-toggle {
  color: #1e2022;
  background-color: #f9fafc;
}

.btn-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
  border-color: transparent;
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active {
  color: #fff;
  background-color: #132144;
  box-shadow: 0 4px 11px rgba(19, 33, 68, 0.1);
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
  box-shadow: none;
}

.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #132144;
}

/*------------------------------------
  Button Groups
------------------------------------*/
.btn-group-segment {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f8fafd;
  border-radius: 0.3125rem;
  padding: 0.25rem 0.25rem;
}

.btn-group-segment .btn {
  color: #677788;
}

.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}

.btn-group-segment .btn:hover {
  color: #377dff;
}

.btn-group-segment .btn.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.btn-group-segment .btn:not(:disabled):not(.disabled):active,
.btn-group-segment .btn:not(:disabled):not(.disabled).active {
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:not(:first-child),
.btn-group-segment > .btn-group:not(:first-child) > .btn {
  border-radius: 0.3125rem;
}

.btn-group-segment.btn-group-pills {
  border-radius: 6.1875rem;
}

.btn-group-segment.btn-group-pills
  > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Outline Button Styles
------------------------------------*/
.btn-outline-secondary {
  border-color: #e7eaf3;
}

/*------------------------------------
  Button Icon
------------------------------------*/
.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.92969rem;
  font-weight: 400;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;
}

/*------------------------------------
  Button Icon Sizes
------------------------------------*/
.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.btn-icon.btn-sm,
.btn-group-sm > .btn-icon.btn {
  font-size: 0.875rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.btn-icon.btn-lg,
.btn-group-lg > .btn-icon.btn {
  font-size: 1rem;
  width: 3.9375rem;
  height: 3.9375rem;
}

/*------------------------------------
  Button Status
------------------------------------*/
.btn-status {
  position: absolute;
  top: -0.4375rem;
  right: -0.4375rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.71094rem;
  font-weight: 600;
  width: 21px;
  height: 21px;
  background-color: #bdc5d1;
  border: 0.125rem solid #fff;
  padding: 0;
  border-radius: 50%;
}

.btn.rounded-circle .btn-status {
  top: -0.29167rem;
  right: -0.29167rem;
}

/*------------------------------------
  Button Status Sizes
------------------------------------*/
.btn-xs-status {
  top: -0.3125rem;
  right: -0.3125rem;
  font-size: 0.53594rem;
  width: 10px;
  height: 10px;
  border-width: 0.1rem;
}

.btn.rounded-circle .btn-xs-status {
  top: -0.07813rem;
  right: -0.07813rem;
}

.btn-sm-status {
  top: -0.3125rem;
  right: -0.3125rem;
  font-size: 0.65625rem;
  width: 12px;
  height: 12px;
}

.btn.rounded-circle .btn-sm-status {
  top: -0.15625rem;
  right: -0.15625rem;
}

.btn-lg-status {
  top: -0.6125rem;
  right: -0.6125rem;
  font-size: 0.875rem;
  width: 24px;
  height: 24px;
}

.btn.rounded-circle .btn-lg-status {
  top: -0.49rem;
  right: -0.49rem;
}

/*------------------------------------
  Button Status Styles
------------------------------------*/
.btn-status-primary {
  color: #fff;
  background-color: #377dff;
}

.btn-status-secondary {
  color: #fff;
  background-color: #406c8b;
}

.btn-status-success {
  color: #fff;
  background-color: #00c9a7;
}

.btn-status-info {
  color: #fff;
  background-color: #00c9db;
}

.btn-status-warning {
  color: #1e2022;
  background-color: #f5ca99;
}

.btn-status-danger {
  color: #fff;
  background-color: #ed4c78;
}

.btn-status-light {
  color: #1e2022;
  background-color: #f9fafc;
}

.btn-status-dark {
  color: #fff;
  background-color: #132144;
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn {
  position: relative;
}

label.btn {
  cursor: pointer !important;
}

.btn.dropdown-toggle::after {
  transition: all 0.2s ease-in-out;
}

.btn-pill {
  border-radius: 6.1875rem;
}

.btn-link {
  font-weight: 600;
}

.btn-toggle-default {
  display: inline-block;
}

.btn-toggle-toggled {
  display: none;
}

.btn.toggled .btn-toggle-default,
.btn[aria-expanded='true'] .btn-toggle-default {
  display: none;
}

.btn.toggled .btn-toggle-toggled,
.btn[aria-expanded='true'] .btn-toggle-toggled {
  display: inline-block;
}

/*------------------------------------
  Content
------------------------------------*/
.content-space {
  padding: 2rem;
}

@media (max-width: 575.98px) {
  .content-space {
    padding: 1rem;
  }
}

.navbar-expand .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
  padding-left: 16.25rem;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

.footer-offset {
  margin-bottom: 3.5rem;
}

.main .content {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.container-fluid,
.container-xxl {
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (max-width: 575.98px) {
  .container-fluid,
  .container-xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/*------------------------------------
  Kanban
------------------------------------*/
.kanban-board {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.kanban-board::-webkit-scrollbar {
  height: 0.6125rem;
}

.kanban-board::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.kanban-board .kanban-board-row {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.kanban-board .kanban-board-row > [class*='col'] {
  max-width: 350px;
}

/*------------------------------------
  Sidebar Detached Content
------------------------------------*/
.sidebar-detached-content {
  margin-left: 18.25rem;
}

/*------------------------------------
  Splitted Content
------------------------------------*/
.splitted-content-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.navbar-vertical.splitted-content-navbar {
  width: 21.5rem;
}

.navbar-vertical.splitted-content-navbar .navbar-vertical-content {
  height: 100%;
}

.navbar-vertical.splitted-content-navbar .navbar-nav {
  margin-left: 5.25rem;
}

.navbar-vertical.splitted-content-navbar .navbar-vertical-footer {
  margin-left: 5.25rem;
}

.splitted-content-bordered:not(:last-child) {
  border-right: 0.0625rem solid #e7eaf3;
}

.splitted-content-bordered:not(:first-child) {
  border-left: 0.0625rem solid #e7eaf3;
}

.navbar-vertical-aside
  ~ .splitted-content-small.splitted-content-bordered:not(:last-child) {
  margin-left: -0.0625rem;
}

.splitted-content-mini,
.splitted-content-small {
  background-color: #fff;
}

.splitted-content-mini {
  width: 5.25rem;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.splitted-content-mini .navbar-brand {
  margin-right: 0;
}

.splitted-content-small,
.splitted-content-fluid {
  min-height: 100vh;
}

.splitted-content-small {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20rem;
  max-width: 100%;
}

.splitted-content-fluid {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.custom-header{
  height: 70px;
  border-bottom: 1px solid #ccc;;
  background-color: #fff;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.custom-main{
  background-color: #fff;

}

.menu-link{
  color: #666;
}
.menu-link:hover{
  color: #333;
}
.menu-link.active{
  color: var(--primary);
  text-decoration: underline;
  font-weight: 600;
}


.splitted-content-mini.cmz-unfold-content-initialized,
.splitted-content-small.cmz-unfold-content-initialized {
  display: -ms-flexbox !important;
  display: flex !important;
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1200px) {
  .splitted-content-toggle {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .splitted-content-mini.cmz-unfold-hidden,
  .splitted-content-small.cmz-unfold-hidden {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
  .splitted-content-small {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .splitted-content-mini-right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
  }
}

/*------------------------------------
  Page Header
------------------------------------*/
.page-header {
  border-bottom: 0.0625rem solid #e7eaf3;
  padding-bottom: 1.75rem;
  margin-bottom: 2.25rem;
}

.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}

.page-header-title {
  margin-bottom: 0.25rem;
}

.page-header-text {
  margin-bottom: 0.25rem;
}

.page-header-tabs {
  margin-bottom: -1.75rem;
  border-bottom-width: 0;
}

.page-header-reset {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.page-header-light {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.page-header-light .page-header-title {
  color: #fff;
}

.page-header-light .page-header-text {
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Card
------------------------------------*/
.card {
  box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.075);
}

.card[href]:hover .card-title,
.card[href]:hover .card-header-title {
  color: #0052ea;
}

.card-title {
  display: block;
  color: #1e2022;
  margin-bottom: 0;
}

.card-subtitle {
  display: block;
  color: #8c98a4;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.card-text {
  display: block;
  color: #677788;
}

.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-header {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
  }
}

.card-header-title {
  margin-bottom: 0;
}

.card-footer {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*------------------------------------
  Card Sizes
------------------------------------*/
.card-sm > .card-header,
.card-sm > .card-footer {
  padding-right: 1rem;
  padding-left: 1rem;
}

.card-sm > .card-body,
.card-sm > .collapse .card-body {
  padding: 1rem 1rem;
}

.card-sm .card-table th,
.card-sm .card-table td {
  padding-right: 1rem;
  padding-left: 1rem;
}

.card-lg > .card-header,
.card-lg > .card-footer {
  padding-right: 2rem;
  padding-left: 2rem;
}

.card-lg > .card-body,
.card-lg > .collapse .card-body {
  padding: 2rem 2rem;
}

.card-lg .card-table th,
.card-lg .card-table td {
  padding-right: 2rem;
  padding-left: 2rem;
}

/*------------------------------------
  Card Options
------------------------------------*/
.card-header-borderless .card-header {
  border-width: 0;
  padding-bottom: 0;
}

.card-body-height {
  height: 21.25rem;
  overflow: hidden;
  overflow-y: auto;
}

.card-body-height::-webkit-scrollbar {
  width: 0.6125rem;
}

.card-body-height::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.card-body-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-body-stretched {
  padding: 0 1.3125rem 1.3125rem 1.3125rem;
}

.card-dashed {
  border-width: 0;
}

.card-dashed:hover {
  border-color: rgba(55, 125, 255, 0.7);
}

.card-dashed:hover .card-dashed-body {
  color: #0052ea;
}

.card-dashed-body {
  color: #8c98a4;
  border: 0.0625rem dashed rgba(214, 219, 235, 0.7);
  border-radius: 0.75rem;
  transition: 0.3s;
}

.card-border-left {
  border-left: 0.125rem solid #e7eaf3;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.card-hover-shadow {
  transition: 0.3s;
}

.card-hover-shadow:hover {
  box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.25) !important;
}

.card-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: inherit;
}

.card-btn-toggle {
  display: inline-block;
  color: #377dff;
}

.card-btn-toggle-default {
  display: none;
}

.card-btn-toggle-active {
  display: block;
}

.card-btn.collapsed .card-btn-toggle-default {
  display: block;
}

.card-btn.collapsed .card-btn-toggle-active {
  display: none;
}

.card-pinned {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.card-table {
  margin-bottom: 0;
}

.card-table th,
.card-table td {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.card-table .table-thead-bordered {
  margin-top: -0.0625rem;
}

/*------------------------------------
  Card Extensions
------------------------------------*/
.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .card-dropdown-filter-centered {
    left: -8rem;
    min-width: 18rem !important;
  }
}

.card-unfold {
  z-index: 2;
}

.card-progress-wrap {
  overflow: hidden;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.card-progress {
  height: 0.25rem;
}

.card-progress:first-child .progress-bar {
  border-top-left-radius: 0.75rem;
}

.card-progress:first-child .progress-bar[aria-valuenow='100'] {
  border-top-right-radius: 0.75rem;
}

.card-nav-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-nav-vertical .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.card-nav-vertical.card-nav {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.card-navbar-nav {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.card-navbar-nav .nav-icon {
  margin-right: 0.5rem;
}

.card > .profile-cover,
.card > .profile-cover .profile-cover-img,
.card > .profile-cover .profile-cover-img-wrapper {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header ~ .profile-cover,
.card > .card-header ~ .profile-cover .profile-cover-img,
.card > .card-header ~ .profile-cover .profile-cover-img-wrapper {
  border-radius: 0;
}

.card .jvectormap-custom .jvectormap-container {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

/*------------------------------------
  Close
------------------------------------*/
.close {
  opacity: 1;
}

.close:hover {
  color: #00c9a7;
}

/*------------------------------------
  Column Divider
------------------------------------*/
.column-divider {
  position: relative;
}

.column-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 0.0625rem solid #e7eaf3;
  content: '';
}

@media (min-width: 576px) {
  .column-divider-sm {
    position: relative;
  }
  .column-divider-sm::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: '';
  }
}

@media (min-width: 768px) {
  .column-divider-md {
    position: relative;
  }
  .column-divider-md::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: '';
  }
}

@media (min-width: 992px) {
  .column-divider-lg {
    position: relative;
  }
  .column-divider-lg::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: '';
  }
}

@media (min-width: 1200px) {
  .column-divider-xl {
    position: relative;
  }
  .column-divider-xl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: '';
  }
}

@media (min-width: 1400px) {
  .column-divider-xxl {
    position: relative;
  }
  .column-divider-xxl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: '';
  }
}

/*------------------------------------
  Divider
------------------------------------*/
.divider-line {
  height: 1px !important;
  border: 1px solid #e3e3e3;
}
.divider-line-ccc {
  width: 100%;
  height: 1px !important;
  border: 1px solid #ccc;
}
.divider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.divider::before,
.divider::after {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid #e7eaf3;
  content: '';
  margin-top: 0.0625rem;
}

.divider::before {
  margin-right: 0.5rem;
}

.divider::after {
  margin-left: 0.5rem;
}

/*------------------------------------
  Links
------------------------------------*/
.link {
  font-size: 0.8125rem;
}

/*------------------------------------
  List Inline
------------------------------------*/
.list-inline-m-1 {
  margin-left: -0.25rem !important;
}

.list-inline-m-1 .list-inline-item {
  margin: 0.25rem !important;
}

/*------------------------------------
  List Unstyled
------------------------------------*/
.list-unstyled-py-2 > li:not(:last-child) {
  padding-bottom: 0.5rem;
}

.list-unstyled-py-2 .list-unstyled-py-2 > li:first-child {
  padding-top: 0.5rem;
}

.list-unstyled-py-2 .list-unstyled-py-2 > li:last-child {
  padding-bottom: 0;
}

.list-unstyled-py-3 > li:not(:last-child) {
  padding-bottom: 1rem;
}

.list-unstyled-py-3 .list-unstyled-py-3 > li:first-child {
  padding-top: 1rem;
}

.list-unstyled-py-3 .list-unstyled-py-3 > li:last-child {
  padding-bottom: 0;
}

.list-unstyled-py-4 > li:not(:last-child) {
  padding-bottom: 1.5rem;
}

.list-unstyled-py-4 .list-unstyled-py-4 > li:first-child {
  padding-top: 1.5rem;
}

.list-unstyled-py-4 .list-unstyled-py-4 > li:last-child {
  padding-bottom: 0;
}

/*------------------------------------
  List Comment
------------------------------------*/
.list-comment {
  padding-left: 0;
  list-style: none;
}

.list-comment-item:not(:first-child) {
  border-top: 0.0625rem solid #e7eaf3;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

/*------------------------------------
  List Separator
------------------------------------*/
.list-separator {
  margin-bottom: 0;
}

.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}

.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}

.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: calc(50% - 0.15625rem);
  right: 0.84375rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #bdc5d1;
  border-radius: 0.3125rem;
  content: '';
}

.list-separator .list-separator-link {
  color: #677788;
}

.list-separator .list-separator-link:hover {
  color: #0052ea;
}

/*------------------------------------
  List Group
------------------------------------*/
.list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.list-group-icon {
  width: 1.25rem;
  text-align: center;
  margin-right: 0.25rem;
}

.list-group-item-light {
  background-color: #f9fafc;
}

/*------------------------------------
  List Group Sizes
------------------------------------*/
.list-group-sm .list-group-item {
  font-size: 0.8125rem;
  padding: 0.375rem 0.625rem;
}

.list-group-lg .list-group-icon {
  font-size: 1.3125rem;
  width: 2.1875rem;
  margin-right: 0.5rem;
}

.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

/*------------------------------------
  List Checked
------------------------------------*/
.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item {
  position: relative;
  display: block;
  color: #677788;
  padding-left: 1.75rem;
  margin: 0.5rem;
}

.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-top: 0.125rem;
}

.list-checked-item[hover]:hover {
  color: #377dff;
}

[class*='list-checked-bg-'] .list-checked-item::before,
[class*='list-checked-soft-bg-'] .list-checked-item::before {
  margin-top: 0.25rem;
}

/*------------------------------------
  List Checked
------------------------------------*/
.list-checked-sm .list-checked-item {
  padding-left: 1.25rem;
}

.list-checked-sm .list-checked-item::before {
  width: 0.75rem;
  height: 0.75rem;
  background-size: 0.75rem 0.75rem;
  margin-top: 0.3125rem;
}

.list-checked-sm[class*='list-checked-bg-'] .list-checked-item::before,
.list-checked-sm[class*='list-checked-soft-bg-'] .list-checked-item::before {
  margin-top: 0.375rem;
}

.list-checked-lg .list-checked-item {
  padding-left: 2.25rem;
}

.list-checked-lg .list-checked-item::before {
  width: 1.25rem;
  height: 1.25rem;
  background-size: 1.25rem 1.25rem;
  margin-top: 0;
}

.list-checked-lg[class*='list-checked-bg-'] .list-checked-item::before,
.list-checked-lg[class*='list-checked-soft-bg-'] .list-checked-item::before {
  margin-top: 0.125rem;
}

/*------------------------------------
  List Checked
------------------------------------*/
.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23377dff' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23377dff' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2371869d' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%2371869d' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300c9a7' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%2300c9a7' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300c9db' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%2300c9db' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f5ca99' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23f5ca99' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ed4c78' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23ed4c78' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f9fafc' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23f9fafc' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23132144' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23132144' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

/*------------------------------------
  Sidebar
------------------------------------*/
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: 23rem;
  height: 100%;
  background-color: #fff;
}

.sidebar-bordered {
  border-left: 0.0625rem solid #e7eaf3;
}

.sidebar-box-shadow {
  box-shadow: -5px 0px 12px rgba(103, 119, 136, 0.1);
}

.sidebar-left {
  left: 0;
  right: auto;
}

.sidebar-left.sidebar-bordered {
  border-left: none;
  border-right: 0.0625rem solid #e7eaf3;
}

.sidebar-left.sidebar-box-shadow {
  box-shadow: 5px 0px 12px rgba(103, 119, 136, 0.1);
}

.sidebar-close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  z-index: 2;
}

.sidebar-body {
  min-height: auto;
}

.sidebar-scrollbar {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.sidebar-scrollbar::-webkit-scrollbar {
  width: 0.6125rem;
}

.sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}

.sidebar-scrollbar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.sidebar-card {
  border-radius: 0;
}

/*------------------------------------
  Tables
------------------------------------*/
.table th {
  font-weight: 400;
}

.table thead th {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
}

.table tfoot th,
.table tfoot td {
  color: #1e2022;
  font-weight: 600;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

.table .btn {
  white-space: nowrap;
}

.table tbody td {
  border-bottom: 1px solid #e3e3e3;
}
.table tbody tr:hover {
  background-color: #f1f6ee;
}

.hover-effect:hover{
  background-color: #f1f6ee !important;
}

/*------------------------------------
  Table Sizes
------------------------------------*/
.table-sm {
  font-size: 0.8125rem;
}

.table-lg th,
.table-lg td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*------------------------------------
  Table Options
------------------------------------*/
.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.table-align-middle tbody td {
  vertical-align: middle;
}

.table-text-center th,
.table-text-center td {
  text-align: center;
}

.table-column-right-aligned {
  text-align: right;
}

.table-column-pr-0 {
  padding-right: 0 !important;
}

.table-column-pl-0 {
  padding-left: 0 !important;
}

.table-thead-bordered thead th {
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

/*------------------------------------
  Table Extensions
------------------------------------*/
.table-input-group {
  min-width: 14rem;
}

.table-progress {
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 5rem;
  height: 0.25rem;
}

/*------------------------------------
  Dropdown
------------------------------------*/
.dropdown-menu {
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
}

.dropdown-item {
  font-size: 0.8125rem;
}

.dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item.active:not(:focus):not(:active) {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item[href]:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 80%;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/*------------------------------------
  Dropdown Sizes
------------------------------------*/
.dropdown-menu-sm {
  padding: 0.75rem 0;
}

.dropdown-menu-sm .dropdown-header,
.dropdown-menu-sm .dropdown-item {
  padding: 0.25rem 1rem;
}

.dropdown-menu-sm .dropdown-divider {
  margin: 0.25rem;
}

.dropdown-menu-lg .dropdown-header,
.dropdown-menu-lg .dropdown-item {
  padding: 0.5rem 1.5rem;
}

.dropdown-menu-lg .dropdown-item {
  font-size: 0.875rem;
}

.dropdown-menu-lg .dropdown-item-icon {
  font-size: 1.125rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}

/*------------------------------------
  Dropdown Options
------------------------------------*/
.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  font-size: 1rem;
  width: 1.5rem;
  color: #677788;
}

.dropdown-item-icon.fa {
  font-size: 0.875rem !important;
  margin-right: 0 !important;
}

.dropdown-toggle {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' %3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  margin-left: 0.3125rem;
}

.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropright .dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dropdown-toggle-collapse::after {
  transition: 0.3s;
}

.dropdown-toggle-collapse[aria-expanded='true']::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/*------------------------------------
  Dropdown Extensions
------------------------------------*/
.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
}

/*------------------------------------
  Footer
------------------------------------*/
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer:not([class*='container']) {
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (max-width: 575.98px) {
  .footer:not([class*='container']) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.footer-height-offset {
  height: calc(100vh - 3.5rem);
}

.footer-bordered {
  border-width: 0.0625rem 0 0 0;
  border-style: solid;
  border-color: #e7eaf3;
}

/*------------------------------------
  Form
------------------------------------*/
.custom-select:focus,
.form-control:focus {
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}

.form-text {
  color: #8c98a4;
}

.form-link {
  display: inline-block;
  margin-top: 0.75rem;
}

.form-control-single-number {
  font-size: 1.75rem;
  text-align: center;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-30 {
  border-radius: 30px !important;
}
/*------------------------------------
  Custom Select
------------------------------------*/
.custom-select,
.custom-control-label {
  cursor: pointer;
}

.form-control .custom-control-label {
  width: 100%;
}

.custom-select-lg {
  border-radius: 0.3125rem;
}

.custom-select-sm {
  border-radius: 0.25rem;
}

.custom-radio-reverse {
  width: 100%;
  padding-left: 0;
  padding-right: 1.5rem;
}

.custom-radio-reverse .custom-control-label {
  width: 100%;
}

.custom-radio-reverse .custom-control-label::before {
  left: auto;
  right: -1.5rem;
}

.custom-radio-reverse .custom-control-label::after {
  left: auto;
  right: -1.5rem;
}

/*------------------------------------
  Input Group
------------------------------------*/
.input-group-text {
  border-color: #e7eaf3;
}

.input-label {
  display: block;
  color: #1e2022;
  font-size: 0.875rem;
}

.input-label-secondary {
  color: #8c98a4;
  font-size: 0.8125rem;
  margin-left: 0.25rem;
}

.input-label.required::after {
  content: '*';
  color: #ed4c78;
  margin-left: 0.25rem;
}

.input-group .input-group-prepend,
.input-group .input-group-append {
  z-index: 4;
}

.input-group > .input-group-prepend .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append .custom-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group
  > .input-group-prepend:first-child
  .select2-custom:not(:first-child)
  .custom-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group
  > .input-group-append:last-child
  .select2-custom:not(:last-child)
  .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 575.98px) {
  .input-group-append-last-sm-down-none.input-group-append:last-child
    .select2-custom:not(:last-child)
    .custom-select {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
}

/*------------------------------------
  Form Add Field
------------------------------------*/
.input-group-add-field {
  position: relative;
  margin-top: 1rem;
}

.input-group-add-field:hover .input-group-add-field-delete {
  opacity: 1;
}

.input-group-add-field-delete {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1rem;
  color: #ed4c78;
  opacity: 0;
  margin-right: -1rem;
  padding-left: 0.25rem;
}

.input-group-add-field-delete:hover {
  color: #ea2c60;
}

@media (max-width: 1199.98px) {
  .input-group-add-field-delete {
    opacity: 1;
  }
}

/*------------------------------------
  Input Group Borderless
------------------------------------*/
.form-control-borderless,
.input-group-borderless .form-control,
.input-group-borderless .input-group-text {
  border: none;
}

.form-control-borderless:focus,
.input-group-borderless .form-control:focus,
.input-group-borderless .input-group-text:focus {
  box-shadow: none;
}

.custom-select-borderless {
  border-color: transparent;
}

.custom-select-borderless:focus {
  box-shadow: none;
  border-color: transparent;
}

/*------------------------------------
  Input Group Break
------------------------------------*/
@media (max-width: 575.98px) {
  .input-group-sm-down-break {
    display: block;
  }
  .input-group-sm-down-break > .form-control,
  .input-group-sm-down-break > .custom-select {
    width: 100%;
  }
  .input-group-sm-down-break .select2-custom,
  .input-group-sm-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-sm-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-sm-down-break > .form-control:first-child,
  .input-group-sm-down-break > .custom-select:first-child,
  .input-group-sm-down-break .select2-custom:first-child .select2-selection {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-sm-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-sm-down-break > .form-control:not(:first-child):not(:last-child),
  .input-group-sm-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection {
    border-radius: 0;
  }
  .input-group-sm-down-break > .custom-select:last-child,
  .input-group-sm-down-break > .form-control:last-child,
  .input-group-sm-down-break .select2-custom:last-child .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-sm-down-break .form-control + .form-control,
  .input-group-sm-down-break .form-control + .custom-select,
  .input-group-sm-down-break .form-control + .custom-file,
  .input-group-sm-down-break .form-control-plaintext + .form-control,
  .input-group-sm-down-break .form-control-plaintext + .custom-select,
  .input-group-sm-down-break .form-control-plaintext + .custom-file,
  .input-group-sm-down-break .custom-select + .form-control,
  .input-group-sm-down-break .custom-select + .custom-select,
  .input-group-sm-down-break .custom-select + .custom-file,
  .input-group-sm-down-break .custom-file + .form-control,
  .input-group-sm-down-break .custom-file + .custom-select,
  .input-group-sm-down-break .custom-file + .custom-file,
  .input-group-sm-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 767.98px) {
  .input-group-md-down-break {
    display: block;
  }
  .input-group-md-down-break > .form-control,
  .input-group-md-down-break > .custom-select {
    width: 100%;
  }
  .input-group-md-down-break .select2-custom,
  .input-group-md-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-md-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-md-down-break > .form-control:first-child,
  .input-group-md-down-break > .custom-select:first-child,
  .input-group-md-down-break .select2-custom:first-child .select2-selection {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-md-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-md-down-break > .form-control:not(:first-child):not(:last-child),
  .input-group-md-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection {
    border-radius: 0;
  }
  .input-group-md-down-break > .custom-select:last-child,
  .input-group-md-down-break > .form-control:last-child,
  .input-group-md-down-break .select2-custom:last-child .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-md-down-break .form-control + .form-control,
  .input-group-md-down-break .form-control + .custom-select,
  .input-group-md-down-break .form-control + .custom-file,
  .input-group-md-down-break .form-control-plaintext + .form-control,
  .input-group-md-down-break .form-control-plaintext + .custom-select,
  .input-group-md-down-break .form-control-plaintext + .custom-file,
  .input-group-md-down-break .custom-select + .form-control,
  .input-group-md-down-break .custom-select + .custom-select,
  .input-group-md-down-break .custom-select + .custom-file,
  .input-group-md-down-break .custom-file + .form-control,
  .input-group-md-down-break .custom-file + .custom-select,
  .input-group-md-down-break .custom-file + .custom-file,
  .input-group-md-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 991.98px) {
  .input-group-lg-down-break {
    display: block;
  }
  .input-group-lg-down-break > .form-control,
  .input-group-lg-down-break > .custom-select {
    width: 100%;
  }
  .input-group-lg-down-break .select2-custom,
  .input-group-lg-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-lg-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-lg-down-break > .form-control:first-child,
  .input-group-lg-down-break > .custom-select:first-child,
  .input-group-lg-down-break .select2-custom:first-child .select2-selection {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-lg-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-lg-down-break > .form-control:not(:first-child):not(:last-child),
  .input-group-lg-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection {
    border-radius: 0;
  }
  .input-group-lg-down-break > .custom-select:last-child,
  .input-group-lg-down-break > .form-control:last-child,
  .input-group-lg-down-break .select2-custom:last-child .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-lg-down-break .form-control + .form-control,
  .input-group-lg-down-break .form-control + .custom-select,
  .input-group-lg-down-break .form-control + .custom-file,
  .input-group-lg-down-break .form-control-plaintext + .form-control,
  .input-group-lg-down-break .form-control-plaintext + .custom-select,
  .input-group-lg-down-break .form-control-plaintext + .custom-file,
  .input-group-lg-down-break .custom-select + .form-control,
  .input-group-lg-down-break .custom-select + .custom-select,
  .input-group-lg-down-break .custom-select + .custom-file,
  .input-group-lg-down-break .custom-file + .form-control,
  .input-group-lg-down-break .custom-file + .custom-select,
  .input-group-lg-down-break .custom-file + .custom-file,
  .input-group-lg-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 1199.98px) {
  .input-group-xl-down-break {
    display: block;
  }
  .input-group-xl-down-break > .form-control,
  .input-group-xl-down-break > .custom-select {
    width: 100%;
  }
  .input-group-xl-down-break .select2-custom,
  .input-group-xl-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-xl-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-xl-down-break > .form-control:first-child,
  .input-group-xl-down-break > .custom-select:first-child,
  .input-group-xl-down-break .select2-custom:first-child .select2-selection {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xl-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-xl-down-break > .form-control:not(:first-child):not(:last-child),
  .input-group-xl-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection {
    border-radius: 0;
  }
  .input-group-xl-down-break > .custom-select:last-child,
  .input-group-xl-down-break > .form-control:last-child,
  .input-group-xl-down-break .select2-custom:last-child .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-xl-down-break .form-control + .form-control,
  .input-group-xl-down-break .form-control + .custom-select,
  .input-group-xl-down-break .form-control + .custom-file,
  .input-group-xl-down-break .form-control-plaintext + .form-control,
  .input-group-xl-down-break .form-control-plaintext + .custom-select,
  .input-group-xl-down-break .form-control-plaintext + .custom-file,
  .input-group-xl-down-break .custom-select + .form-control,
  .input-group-xl-down-break .custom-select + .custom-select,
  .input-group-xl-down-break .custom-select + .custom-file,
  .input-group-xl-down-break .custom-file + .form-control,
  .input-group-xl-down-break .custom-file + .custom-select,
  .input-group-xl-down-break .custom-file + .custom-file,
  .input-group-xl-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 1399.98px) {
  .input-group-xxl-down-break {
    display: block;
  }
  .input-group-xxl-down-break > .form-control,
  .input-group-xxl-down-break > .custom-select {
    width: 100%;
  }
  .input-group-xxl-down-break .select2-custom,
  .input-group-xxl-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-xxl-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-xxl-down-break > .form-control:first-child,
  .input-group-xxl-down-break > .custom-select:first-child,
  .input-group-xxl-down-break .select2-custom:first-child .select2-selection {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xxl-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-xxl-down-break
    > .form-control:not(:first-child):not(:last-child),
  .input-group-xxl-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection {
    border-radius: 0;
  }
  .input-group-xxl-down-break > .custom-select:last-child,
  .input-group-xxl-down-break > .form-control:last-child,
  .input-group-xxl-down-break .select2-custom:last-child .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-xxl-down-break .form-control + .form-control,
  .input-group-xxl-down-break .form-control + .custom-select,
  .input-group-xxl-down-break .form-control + .custom-file,
  .input-group-xxl-down-break .form-control-plaintext + .form-control,
  .input-group-xxl-down-break .form-control-plaintext + .custom-select,
  .input-group-xxl-down-break .form-control-plaintext + .custom-file,
  .input-group-xxl-down-break .custom-select + .form-control,
  .input-group-xxl-down-break .custom-select + .custom-select,
  .input-group-xxl-down-break .custom-select + .custom-file,
  .input-group-xxl-down-break .custom-file + .form-control,
  .input-group-xxl-down-break .custom-file + .custom-select,
  .input-group-xxl-down-break .custom-file + .custom-file,
  .input-group-xxl-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}

.input-group-down-break {
  display: block;
}

.input-group-down-break > .form-control,
.input-group-down-break > .custom-select {
  width: 100%;
}

.input-group-down-break .select2-custom,
.input-group-down-break .select2-custom .select2-container {
  width: 100% !important;
}

.input-group-down-break .select2-custom {
  margin-left: 1px;
}

.input-group-down-break > .form-control:first-child,
.input-group-down-break > .custom-select:first-child,
.input-group-down-break .select2-custom:first-child .select2-selection {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-down-break > .custom-select:not(:first-child):not(:last-child),
.input-group-down-break > .form-control:not(:first-child):not(:last-child),
.input-group-down-break
  .select2-custom:not(:first-child):not(:last-child)
  .select2-selection {
  border-radius: 0;
}

.input-group-down-break > .custom-select:last-child,
.input-group-down-break > .form-control:last-child,
.input-group-down-break .select2-custom:last-child .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.input-group-down-break .form-control + .form-control,
.input-group-down-break .form-control + .custom-select,
.input-group-down-break .form-control + .custom-file,
.input-group-down-break .form-control-plaintext + .form-control,
.input-group-down-break .form-control-plaintext + .custom-select,
.input-group-down-break .form-control-plaintext + .custom-file,
.input-group-down-break .custom-select + .form-control,
.input-group-down-break .custom-select + .custom-select,
.input-group-down-break .custom-select + .custom-file,
.input-group-down-break .custom-file + .form-control,
.input-group-down-break .custom-file + .custom-select,
.input-group-down-break .custom-file + .custom-file,
.input-group-down-break .select2-custom .select2-selection {
  margin-left: 0;
  margin-top: -0.0625rem;
}

/*------------------------------------
  Input Group Light
------------------------------------*/
.input-group-light .form-control,
.input-group-light .custom-select,
.form-control-light,
.custom-select-light {
  background-color: #f6f7fa;
  box-shadow: none;
}

.input-group-light .form-control:disabled,
.input-group-light .form-control[readonly],
.input-group-light .custom-select:disabled,
.input-group-light .custom-select[readonly],
.form-control-light:disabled,
.form-control-light[readonly],
.custom-select-light:disabled,
.custom-select-light[readonly] {
  background-color: transparent;
}

.input-group-hover-light .form-control:hover,
.input-group-hover-light .form-control.focus,
.input-group-hover-light .form-control:focus,
.input-group-hover-light .custom-select:hover,
.input-group-hover-light .custom-select.focus,
.input-group-hover-light .custom-select:focus,
.form-control-hover-light:hover,
.form-control-hover-light.focus,
.form-control-hover-light:focus,
.custom-select-hover-light:hover,
.custom-select-hover-light.focus,
.custom-select-hover-light:focus {
  background-color: #f6f7fa;
  box-shadow: none;
}

.input-group-hover-light .form-control:disabled,
.input-group-hover-light .form-control[readonly],
.input-group-hover-light .custom-select:disabled,
.input-group-hover-light .custom-select[readonly],
.form-control-hover-light:disabled,
.form-control-hover-light[readonly],
.custom-select-hover-light:disabled,
.custom-select-hover-light[readonly] {
  background-color: transparent;
}

/*------------------------------------
  Merge
------------------------------------*/
.input-group-merge {
  position: relative;
}

.input-group-merge .input-group-prepend,
.input-group-merge .input-group-append {
  position: absolute;
  top: 0;
  bottom: 0;
}

.input-group-merge .input-group-prepend {
  left: 0;
}

.input-group-merge .input-group-prepend .input-group-text {
  padding: 0 0 0 0.875rem;
}

.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-merge .input-group-append {
  right: 0;
}

.input-group-merge .input-group-append .input-group-text {
  padding: 0 0.875rem 0 0;
}

.input-group-merge .input-group-append .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-merge .input-group-text {
  z-index: 4;
  border: none;
}

.input-group-merge .custom-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 2.625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.input-group-merge .custom-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 2.625rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/*------------------------------------
  Flush
------------------------------------*/
.custom-select-flush,
.form-control-flush {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.custom-select-flush.is-valid,
.custom-select-flush.is-valid:focus,
.custom-select-flush.is-invalid,
.custom-select-flush.is-invalid:focus,
.form-control-flush.is-valid,
.form-control-flush.is-valid:focus,
.form-control-flush.is-invalid,
.form-control-flush.is-invalid:focus {
  box-shadow: none;
}

.input-group-flush .custom-select,
.input-group-flush .form-control {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.input-group-flush .custom-select.is-valid,
.input-group-flush .custom-select.is-valid:focus,
.input-group-flush .custom-select.is-invalid,
.input-group-flush .custom-select.is-invalid:focus,
.input-group-flush .form-control.is-valid,
.input-group-flush .form-control.is-valid:focus,
.input-group-flush .form-control.is-invalid,
.input-group-flush .form-control.is-invalid:focus {
  box-shadow: none;
}

.input-group-flush .input-group-append .input-group-text,
.input-group-flush .input-group-prepend .input-group-text {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.input-group-flush .input-group-prepend .input-group-text {
  padding-left: 0;
}

.input-group-flush .input-group-append .input-group-text {
  padding-left: 0;
}

.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group-merge .input-group-prepend .input-group-text {
  padding: 0;
}

.input-group-flush.input-group-merge .custom-select:not(:first-child),
.input-group-flush.input-group-merge .form-control:not(:first-child) {
  padding-left: 1.75rem;
}

.input-group-flush.input-group-merge .custom-select:not(:last-child),
.input-group-flush.input-group-merge .form-control:not(:last-child) {
  padding-right: 1.75rem;
}

.input-group-flush.input-group .form-control:not(:first-child),
.input-group-flush.input-group .form-control:not(:last-child),
.input-group-flush.input-group-merge .input-group-prepend .input-group-text,
.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group
  .input-group-append
  .input-group-text:first-child,
.input-group-flush.input-group .input-group-append .input-group-text:last-child,
.input-group-flush.input-group .custom-select:not(:first-child),
.input-group-flush.input-group .custom-select:not(:last-child) {
  border-radius: 0;
}

/*------------------------------------
  Form Pill
------------------------------------*/
.custom-select-pill,
.form-control-pill {
  border-radius: 6.1875rem;
}

.input-group-pill {
  border-radius: 6.1875rem;
}

.input-group-pill > .custom-select:first-child,
.input-group-pill > .form-control:first-child {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill > .custom-select:last-child,
.input-group-pill > .form-control:last-child {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.input-group-pill > .input-group-prepend > .input-group-text,
.input-group-pill > .input-group-prepend > .btn {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill > .input-group-append > .input-group-text,
.input-group-pill > .input-group-append > .btn {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.input-group-pill.input-group-merge .form-control:not(:last-child) {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.input-group-pill.input-group-merge .input-group-prepend > .input-group-text,
.input-group-pill.input-group-merge .input-group-prepend > .btn {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill.input-group-merge .form-control:not(:first-child) {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill.input-group-merge .input-group-append > .input-group-text,
.input-group-pill.input-group-merge .input-group-append > .btn {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.input-group-pill.input-group-merge .custom-select {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Form Quantity Counter
------------------------------------*/
.input-group-quantity-counter {
  position: relative;
  width: 8rem;
}

.input-group-quantity-counter .input-group-quantity-counter-control {
  padding-right: 4.0625rem;
}

.input-group-quantity-counter .input-group-quantity-counter-toggle {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-group-quantity-counter .input-group-quantity-counter-btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 50%;
}

.input-group-quantity-counter .input-group-quantity-counter-btn:hover {
  color: #0052ea;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Form Sizes
------------------------------------*/
.input-group-sm.input-group-merge .custom-select:not(:first-child),
.input-group-sm.input-group-merge .form-control:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.input-group-sm.input-group-merge .custom-select:not(:last-child),
.input-group-sm.input-group-merge .form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.input-group-sm.input-group-merge
  .input-group-prepend
  .input-group-text:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.input-group-sm.input-group-merge
  .input-group-append
  .input-group-text:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.input-group-lg.input-group-merge .custom-select:not(:first-child),
.input-group-lg.input-group-merge .form-control:not(:first-child) {
  padding-left: 2.625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.input-group-lg.input-group-merge .custom-select:not(:last-child),
.input-group-lg.input-group-merge .form-control:not(:last-child) {
  padding-right: 2.625rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.input-group-lg.input-group-merge
  .input-group-prepend
  .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.input-group-lg.input-group-merge
  .input-group-append
  .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/*------------------------------------
  States
------------------------------------*/
.form-control.is-valid {
  background-size: 1rem 1rem;
}

.form-control.is-valid,
.form-control.is-valid:focus {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}

.form-control.is-invalid {
  background-size: 1rem 1rem;
}

.form-control.is-invalid,
.form-control.is-invalid:focus {
  box-shadow: 0 0 10px rgba(237, 76, 120, 0.1);
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00c9a7;
  background-color: #00c9a7;
}

.is-valid .custom-select {
  background-size: 1rem 1rem;
  border-color: #00c9a7;
}

.is-valid .custom-select,
.is-valid .custom-select:focus {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}

.is-invalid .custom-select {
  background-size: 1rem 1rem;
  border-color: #ed4c78;
}

.is-invalid .custom-select,
.is-invalid .custom-select:focus {
  box-shadow: 0 0 10px rgba(237, 76, 120, 0.1);
}

/*------------------------------------
  Checkbox Bookmark
------------------------------------*/
.custom-checkbox-bookmark {
  padding-left: 0;
}

.custom-checkbox-bookmark-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #bdc5d1;
  font-size: 1.09375rem;
  margin-bottom: 0;
}

.custom-checkbox-bookmark-default,
.custom-checkbox-bookmark-active {
  margin-top: -1px;
}

.custom-checkbox-bookmark-active {
  color: #ffc107;
}

.custom-checkbox-bookmark-input
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-default {
  display: block;
}

.custom-checkbox-bookmark-input
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-active {
  display: none;
}

.custom-checkbox-bookmark-input:checked
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-default {
  display: none;
}

.custom-checkbox-bookmark-input:checked
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-active {
  display: block;
}

.custom-checkbox-bookmark-input:disabled ~ .custom-checkbox-bookmark-label {
  color: #e7eaf3;
}

/*------------------------------------
  Checkbox Card
------------------------------------*/
.custom-checkbox-card {
  position: relative;
  height: 100%;
  padding-left: 0;
  margin-right: 0;
}

.custom-checkbox-card.card {
  border-width: 0;
}

.custom-checkbox-card-input {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox-card-label {
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  cursor: pointer;
  margin-bottom: 0;
}

.custom-checkbox-card-label::after {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  z-index: 1;
  content: '';
}

.custom-checkbox-card-label-stretched {
  position: absolute;
  top: 0;
  left: 0;
}

.custom-checkbox-card-label-stretched::after {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23e7eaf3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.custom-checkbox-card-img {
  max-width: 100%;
  height: auto;
  border-radius: 0.3125rem;
}

.custom-checkbox-card-text {
  display: block;
  padding: 0.3125rem 0;
}

.custom-checkbox-card-lg,
.custom-checkbox-card-lg .custom-checkbox-card-label {
  border-radius: 0.75rem;
}

.custom-checkbox-card-lg .custom-checkbox-card-label::after {
  top: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23e7eaf3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1.25rem 1.25rem;
}

.custom-checkbox-card-input:checked ~ .custom-checkbox-card-label::after {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.custom-checkbox-card-lg
  .custom-checkbox-card-input:checked
  ~ .custom-checkbox-card-label::after {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1.25rem 1.25rem;
}

.custom-checkbox-card-input:checked ~ .custom-checkbox-card-label,
.custom-checkbox-card-input:checked ~ .custom-checkbox-card-label-stretched {
  border-color: #00c9a7;
}

.custom-checkbox-card .custom-checkbox-card-btn {
  color: #acb4c1;
  background-color: #e7eaf3;
  border-color: #e7eaf3;
}

.custom-checkbox-card.checked .custom-checkbox-card-btn {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.custom-checkbox-card-input:disabled ~ .custom-checkbox-card-label {
  opacity: 0.5;
}

.custom-checkbox-card.disabled .custom-checkbox-card-btn {
  cursor: default;
  opacity: 0.5;
}

/*------------------------------------
  Custom Checkbox List
------------------------------------*/
.custom-checkbox-list {
  position: static;
  z-index: auto;
}

.custom-checkbox-list
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none;
}

.custom-checkbox-list .custom-control-label::before {
  width: 0.6875rem;
  height: 0.6875rem;
  border: none;
  background-color: #e7eaf3;
  border-radius: 50%;
}

.custom-checkbox-list .custom-control-label::after {
  z-index: 2;
}

.custom-checkbox-list:hover .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.3125);
}

.custom-checkbox-list:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #377dff;
}

.custom-checkbox-list-stretched-bg::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.custom-checkbox-list-wrapper:hover .custom-checkbox-list-stretched-bg::after {
  background-color: rgba(55, 125, 255, 0.035);
}

.custom-checkbox-list
  .custom-control-input:checked
  ~ .custom-checkbox-list-stretched-bg::after {
  background-color: rgba(55, 125, 255, 0.035);
}

.custom-checkbox-list
  .custom-control-input:disabled
  ~ .custom-control-label::before {
  background-color: #8c98a4;
}

/*------------------------------------
  Checkbox Switch
------------------------------------*/
.custom-checkbox-switch {
  display: inline-block;
  padding-left: 0;
}

.custom-checkbox-switch-label {
  cursor: pointer;
  color: #377dff;
  background-color: #fff;
  border: 0.0625rem solid #377dff;
  margin-bottom: 0;
}

.custom-checkbox-switch-label:hover {
  color: #377dff;
}

.custom-checkbox-switch-label-btn-dashed {
  border: 0.0625rem dashed #e7eaf3;
}

.custom-checkbox-switch-input
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-default {
  display: block;
}

.custom-checkbox-switch-input
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-active {
  display: none;
}

.custom-checkbox-switch-input:checked ~ .custom-checkbox-switch-label {
  color: #fff;
  border-style: solid;
  background-color: #377dff;
}

.custom-checkbox-switch-input:checked
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-default {
  display: none;
}

.custom-checkbox-switch-input:checked
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-active {
  display: block;
}

.custom-checkbox-switch-input:disabled ~ .custom-checkbox-switch-label {
  color: #8c98a4;
  background-color: rgba(231, 234, 243, 0.5);
}

/*------------------------------------
  Toggle Switch
------------------------------------*/
.toggle-switch {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch:not(.form-group) {
  margin-bottom: 0;
}

.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.toggle-switch-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.5rem;
}

.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: #ccc;
  background-clip: content-box;
  border: 0.125rem solid transparent;
  border-radius: 6.1875rem;
  transition: 0.3s;
  border: 1px solid #999;
}

.toggle-switch-indicator {
  position: absolute;
  left: 0.125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  -webkit-transform: initial;
  transform: initial;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  border-radius: 50%;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  transition: 0.3s;
}

.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #598b40;
}

.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  transform: translate3d(1.025rem, 50%, 0);
}

.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: #00c9a7;
}

.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: #ed4c78;
}

.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: #e3e3e3;
  border: 1px solid #ccc;
}

.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: lightgray;
}

/*------------------------------------
  Toggle Switch Sizes
------------------------------------*/
.toggle-switch-sm .toggle-switch-label {
  width: 2.5rem;
  height: 1.6125rem;
}

.toggle-switch-sm .toggle-switch-indicator {
  width: 1.20938rem;
  height: 1.20938rem;
}

.toggle-switch-sm
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  -webkit-transform: translate3d(0.81094rem, 50%, 0);
  transform: translate3d(0.81094rem, 50%, 0);
}

.toggle-switch-lg .toggle-switch-label {
  width: 4rem;
  height: 2.5rem;
}

.toggle-switch-lg .toggle-switch-indicator {
  width: 2rem;
  height: 2rem;
}

.toggle-switch-lg
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  transform: translate3d(1.5rem, 50%, 0);
}

/*------------------------------------
  Custom File Boxed
------------------------------------*/
.custom-file-boxed {
  position: relative;
  width: 100%;
  text-align: center;
  border: 0.1rem dashed #e7eaf3;
  cursor: pointer;
  padding: 3rem 3rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.custom-file-boxed:hover {
  background-color: #f8fafd;
}

.custom-file-boxed-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.custom-file-boxed-label {
  text-align: center;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
}

.custom-file-boxed-sm {
  padding: 2rem 2rem;
}

/*------------------------------------
  Custom File Button
------------------------------------*/
.custom-file-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.custom-file-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.custom-file-btn-label {
  margin-bottom: 0;
}

/*------------------------------------
  Go To
------------------------------------*/
.go-to {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  width: 2.625rem;
  height: 2.625rem;
  background-color: rgba(113, 134, 157, 0.1);
  color: #677788;
  font-size: 0.92969rem;
  opacity: 0.5;
  border-radius: 50%;
  transition: 0.3s ease-out;
}

.go-to:hover,
.go-to:focus:hover {
  color: #fff;
  background-color: #377dff;
  opacity: 1;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}

.icon-circle {
  border-radius: 50%;
}

.icon-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

/*------------------------------------
  Icon Sizes
------------------------------------*/
.icon.icon-xs {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.icon.icon-sm {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.icon.icon-md {
  font-size: 1.29578rem;
  width: 3.1125rem;
  height: 3.1125rem;
}

.icon.icon-lg {
  font-size: 1.53125rem;
  width: 3.9375rem;
  height: 3.9375rem;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon-primary {
  color: #fff;
  border-color: #377dff;
  background-color: #377dff;
}

.icon-secondary {
  color: #fff;
  border-color: #406c8b;
  background-color: #406c8b;
}

.icon-success {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}

.icon-info {
  color: #fff;
  border-color: #00c9db;
  background-color: #00c9db;
}

.icon-warning {
  color: #1e2022;
  border-color: #f5ca99;
  background-color: #f5ca99;
}

.icon-danger {
  color: #fff;
  border-color: #ed4c78;
  background-color: #ed4c78;
}

.icon-light {
  color: #1e2022;
  border-color: #f9fafc;
  background-color: #f9fafc;
}

.icon-dark {
  color: #fff;
  border-color: #132144;
  background-color: #132144;
}

.icon-soft-primary {
  color: #377dff;
  background: rgba(55, 125, 255, 0.1);
}

.icon-soft-secondary {
  color: #406c8b;
  background: rgba(113, 134, 157, 0.1);
}

.icon-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}

.icon-soft-info {
  color: #00c9db;
  background: rgba(0, 201, 219, 0.1);
}

.icon-soft-warning {
  color: #f5ca99;
  background: rgba(245, 202, 153, 0.1);
}

.icon-soft-danger {
  color: #ed4c78;
  background: rgba(237, 76, 120, 0.1);
}

.icon-soft-light {
  color: #f9fafc;
  background: rgba(249, 250, 252, 0.1);
}

.icon-soft-dark {
  color: #132144;
  background: rgba(19, 33, 68, 0.1);
}

/*------------------------------------
  Navs
------------------------------------*/
.nav-link {
  color: #677788;
}

.nav-link:hover {
  color: #009a80;
}

.nav-link.active {
  color: #00c9a7;
}

.nav-title {
  display: block;
  color: #1e2022;
  font-size: 1.14844rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.nav-subtitle {
  display: block;
  color: #8c98a4;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

.nav-tabs .nav-link {
  border-bottom-width: 0.0625rem;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding: 1rem 1rem;
  margin-bottom: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom-width: 0.25rem;
  margin-bottom: -0.1875rem;
}

.nav-tabs-light .nav-link.active,
.nav-tabs-light .nav-item.show .nav-link {
  color: #fff;
}

.nav-tabs-light .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.nav-tabs-light .nav-link:hover {
  color: #fff;
}

.nav-pills .nav-item:not(:first-child) {
  margin-left: 0.25rem;
}

.nav-pills .nav-item:not(:last-child) {
  margin-right: 0.25rem;
}

.nav-pills:not(.nav-segment) .nav-link:hover {
  color: #677788;
  background-color: rgba(189, 197, 209, 0.3);
}

.nav-pills:not(.nav-segment) .nav-link.active {
  color: #fff;
  background-color: #377dff;
}

/*------------------------------------
  Nav Options
------------------------------------*/
.nav-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 0.0625rem solid #e7eaf3;
}

.nav-link-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link-toggle::after,
.nav-link-toggle[data-toggle='collapse']::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-left: auto;
}

.nav-pills .show > .nav-link-toggle:not(:hover)::after,
.nav-pills .show > .nav-link-toggle:not(:hover)[data-toggle='collapse']::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.nav-item.active .nav-link {
  color: #377dff;
}

.nav-icon {
  font-size: 1.125rem;
  line-height: 1.4;
  opacity: 0.7;
  -ms-flex: 0 0 1.75rem;
  flex: 0 0 1.75rem;
}

.nav-compact {
  text-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-compact > .nav-item {
  margin: 0.25rem 0;
}

.nav-compact > .nav-item:not(:last-child) {
  margin-right: 0;
}

.nav-compact > .nav-item > .nav-link {
  width: 7rem;
  border-radius: 0.3125rem;
}

.nav-compact > .nav-item > .nav-link:hover,
.nav-compact > .nav-item > .nav-link.active {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}

.nav-compact > .nav-item > .nav-link .nav-icon {
  font-size: 1.25rem;
}

.nav-compact > .show > .nav-link {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}

.nav-compact-title {
  display: block;
}

.nav-compact-icon {
  text-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-compact-icon > .nav-item {
  margin: 0.25rem 0;
}

.nav-compact-icon > .nav-item:not(:last-child) {
  margin-right: 0;
}

.nav-compact-icon .nav-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.625rem;
  height: 2.625rem;
  font-size: 0.92969rem;
  color: #677788;
  background-color: transparent;
  opacity: 1;
  margin-right: 0;
  border-radius: 0.3125rem;
  text-align: center;
}

.nav-compact-icon .nav-icon:hover,
.nav-compact-icon .nav-icon.active {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}

.nav-compact-icon .nav-link.active .nav-icon {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}

.nav-compact-icon-circle .nav-icon {
  border-radius: 50%;
}

.nav-indicator-icon {
  color: #bdc5d1;
  font-size: 6px;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
}

.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;
}

.nav-segment:not(.nav-fill) {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.nav-segment .nav-link {
  color: #677788;
  font-size: 0.8125rem;
  padding: 0.4375rem 0.65625rem;
  border-radius: 0.3125rem;
}

.nav-segment .nav-link:hover {
  color: #377dff;
}

.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.nav-segment.nav-pills {
  border-radius: 6.1875rem;
}

.nav-segment.nav-pills .nav-link {
  border-radius: 6.1875rem;
}

@media (max-width: 575.98px) {
  .nav-sm-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .nav-md-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 991.98px) {
  .nav-lg-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 1199.98px) {
  .nav-xl-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 1399.98px) {
  .nav-xxl-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.nav-down-break {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
}

/*------------------------------------
  Legend Indicator
------------------------------------*/
.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

/*------------------------------------
  Popover
------------------------------------*/
.popover {
  background-color: #132144;
  box-shadow: none;
}

.popover-header {
  font-size: 1rem;
  font-weight: 600;
}

.popover-body {
  color: #bdc5d1;
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-link {
  cursor: pointer;
  text-align: center;
  min-width: 2.25rem;
}

.page-item:not(.active) .page-link:hover {
  color: #377dff;
}

.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

/*------------------------------------
  Pagination Sizes
------------------------------------*/
.pagination-sm .page-link {
  border-radius: 0.25rem;
}

.pagination-lg .page-link {
  border-radius: 0.75rem;
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f8fafd;
  width: 0.5rem;
  height: 12.5rem;
}

/*------------------------------------
  Profile Cover
------------------------------------*/
.profile-cover {
  position: relative;
  height: 7.5rem;
  padding: 1.75rem 2rem;
  border-radius: 0.75rem;
}

.profile-cover-content {
  position: relative;
  z-index: 1;
}

.profile-cover-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.5rem;
  background-color: #e7eaf3;
  border-radius: 0.75rem;
}

.profile-cover-img {
  width: 100%;
  height: 7.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: 0.75rem;
}

.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  margin: -6.3rem auto 0.5rem auto;
}

.profile-cover-content {
  padding: 1rem 2rem;
}

.profile-cover-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 992px) {
  .profile-cover {
    height: 10rem;
  }
  .profile-cover-img-wrapper {
    height: 10rem;
  }
  .profile-cover-img {
    height: 10rem;
  }
}

/*------------------------------------
  Modal
------------------------------------*/
.modal {
  padding-right: 0 !important;
}

.modal-header {
  -ms-flex-align: center;
  align-items: center;
  border-width: 0;
  padding-bottom: 0;
}

.modal-header .close {
  padding: 0.25rem 0.25rem;
  margin: 0 0 0 auto;
}

.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-footer-text:last-child {
  font-size: 0.8125rem;
  margin-bottom: 0;
}

/*------------------------------------
  Modal Sizes
------------------------------------*/
@media (min-width: 768px) {
  .modal-xl .modal-header,
  .modal-lg .modal-header {
    padding-top: 1rem;
  }
  .modal-xl .modal-header,
  .modal-xl .modal-footer,
  .modal-lg .modal-header,
  .modal-lg .modal-footer {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .modal-xl .modal-body,
  .modal-lg .modal-body {
    padding: 1rem;
  }
  .modal-xl .modal-footer,
  .modal-lg .modal-footer {
    padding-bottom: 1rem;
  }
}

/*------------------------------------
  Modal Options
------------------------------------*/
.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.6875rem;
  border-top-left-radius: 0.6875rem;
}

.modal-top-cover-icon,
.modal-top-cover-avatar {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}

.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}

/*------------------------------------
  Step
------------------------------------*/
.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}

.step-title {
  display: block;
  color: #1e2022;
  font-weight: 600;
}

.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}

.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1.5rem;
}

.step-item-between .step-item:last-child {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.step .step-content {
  -ms-flex: 1;
  flex: 1;
}

.step-item.collapse:not(.show) {
  display: none;
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step .step-avatar {
  font-size: 1.09375rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.step .step-avatar::after {
  position: absolute;
  top: 3.09375rem;
  left: 1.3125rem;
  height: calc(100% - 2.15625rem);
  border-left: 0.125rem solid #e7eaf3;
  content: '';
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step-avatar-xs .step-avatar,
.step-avatar-xs.step-avatar {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.step-avatar-xs .step-avatar::after,
.step-avatar-xs.step-avatar::after {
  top: 2rem;
  left: 0.70313rem;
  width: 1.0625rem;
  height: calc(100% - 1.0625rem);
}

.step-avatar-xs .step-divider::after {
  left: 0.76563rem;
}

.step-avatar-sm .step-avatar,
.step-avatar-sm.step-avatar {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.step-avatar-sm .step-avatar::after,
.step-avatar-sm.step-avatar::after {
  top: 2.65625rem;
  left: 1.03125rem;
  width: 1.0625rem;
  height: calc(100% - 1.71875rem);
}

.step-avatar-sm .step-divider::after {
  left: 1.09375rem;
}

.step-avatar-lg .step-avatar,
.step-avatar-lg.step-avatar {
  font-size: 1.53125rem;
  width: 3.9375rem;
  height: 3.9375rem;
}

.step-avatar-lg .step-avatar::after,
.step-avatar-lg.step-avatar::after {
  top: 4.40625rem;
  left: 1.90625rem;
  width: 1.0625rem;
  height: calc(100% - 3.46875rem);
}

.step-avatar-lg .step-divider::after {
  left: 1.96875rem;
}

/*------------------------------------
  Step Divider
------------------------------------*/
.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-weight: 600;
}

.step-divider::after {
  position: absolute;
  top: 1.46875rem;
  left: 1.3125rem;
  height: calc(100% - 0.53125rem);
  border-left: 0.125rem solid #e7eaf3;
  content: '';
}

/*------------------------------------
  Step Icon
------------------------------------*/
.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-icon::after {
  position: absolute;
  top: 3.09375rem;
  left: 1.3125rem;
  height: calc(100% - 2.15625rem);
  border-left: 0.125rem solid #e7eaf3;
  content: '';
}

.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: '';
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/
.step-icon-xs .step-icon,
.step-icon-xs.step-icon {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.step-icon-xs .step-icon::after,
.step-icon-xs.step-icon::after {
  top: 2rem;
  left: 0.70313rem;
  width: 1.0625rem;
  height: calc(100% - 1.0625rem);
}

.step-icon-xs .step-divider::after {
  left: 0.76563rem;
}

.step-icon-sm .step-icon,
.step-icon-sm.step-icon {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.step-icon-sm .step-icon::after,
.step-icon-sm.step-icon::after {
  top: 2.65625rem;
  left: 1.03125rem;
  width: 1.0625rem;
  height: calc(100% - 1.71875rem);
}

.step-icon-sm .step-divider::after {
  left: 1.09375rem;
}

.step-icon-lg .step-icon,
.step-icon-lg.step-icon {
  font-size: 1.53125rem;
  width: 3.9375rem;
  height: 3.9375rem;
}

.step-icon-lg .step-icon::after,
.step-icon-lg.step-icon::after {
  top: 4.40625rem;
  left: 1.90625rem;
  width: 1.0625rem;
  height: calc(100% - 3.46875rem);
}

.step-icon-lg .step-divider::after {
  left: 1.96875rem;
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-sm.step-icon-xs .step-icon::after,
  .step-sm .step-icon.step-icon-xs::after {
    top: 0.76563rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-sm.step-icon-sm .step-icon::after,
  .step-sm .step-icon.step-icon-sm::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-sm.step-icon-lg .step-icon::after,
  .step-sm .step-icon.step-icon-lg::after {
    top: 1.96875rem;
    left: 4.875rem;
    width: calc(100% - 4.875rem);
  }
}

@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-md.step-icon-xs .step-icon::after,
  .step-md .step-icon.step-icon-xs::after {
    top: 0.76563rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-md.step-icon-sm .step-icon::after,
  .step-md .step-icon.step-icon-sm::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-md.step-icon-lg .step-icon::after,
  .step-md .step-icon.step-icon-lg::after {
    top: 1.96875rem;
    left: 4.875rem;
    width: calc(100% - 4.875rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-lg.step-icon-xs .step-icon::after,
  .step-lg .step-icon.step-icon-xs::after {
    top: 0.76563rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-lg.step-icon-sm .step-icon::after,
  .step-lg .step-icon.step-icon-sm::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-lg.step-icon-lg .step-icon::after,
  .step-lg .step-icon.step-icon-lg::after {
    top: 1.96875rem;
    left: 4.875rem;
    width: calc(100% - 4.875rem);
  }
}

@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-xl.step-icon-xs .step-icon::after,
  .step-xl .step-icon.step-icon-xs::after {
    top: 0.76563rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-xl.step-icon-sm .step-icon::after,
  .step-xl .step-icon.step-icon-sm::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-xl.step-icon-lg .step-icon::after,
  .step-xl .step-icon.step-icon-lg::after {
    top: 1.96875rem;
    left: 4.875rem;
    width: calc(100% - 4.875rem);
  }
}

/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-sm.step-centered.step-icon-xs .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70313rem);
  }
  .step-sm.step-centered.step-icon-sm .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-sm.step-centered.step-icon-lg .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 3.9375rem);
    left: calc(50% + 2.90625rem);
  }
}

@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-md.step-centered.step-icon-xs .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70313rem);
  }
  .step-md.step-centered.step-icon-sm .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-md.step-centered.step-icon-lg .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 3.9375rem);
    left: calc(50% + 2.90625rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70313rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 3.9375rem);
    left: calc(50% + 2.90625rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70313rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 3.9375rem);
    left: calc(50% + 2.90625rem);
  }
}

/*------------------------------------
  Step States
------------------------------------*/
.step .step-is-valid-icon,
.step .step-is-invalid-icon {
  display: none;
}

.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .active .step-title,
.step .active.is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-is-valid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}

.step .is-invalid .step-icon {
  color: #fff;
  background-color: #ed4c78;
}

.step .is-invalid .step-title {
  color: #ed4c78;
}

.step .is-invalid .step-is-invalid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}

/*------------------------------------
  Step Colors
------------------------------------*/
.step-icon-primary {
  color: #fff;
  background-color: #377dff;
}

.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-secondary {
  color: #fff;
  background-color: #406c8b;
}

.step-icon-secondary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}

.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-info {
  color: #fff;
  background-color: #00c9db;
}

.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-warning {
  color: #1e2022;
  background-color: #f5ca99;
}

.step-icon-warning.step-icon-pseudo::before {
  background-color: #1e2022;
}

.step-icon-danger {
  color: #fff;
  background-color: #ed4c78;
}

.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-light {
  color: #1e2022;
  background-color: #f9fafc;
}

.step-icon-light.step-icon-pseudo::before {
  background-color: #1e2022;
}

.step-icon-dark {
  color: #fff;
  background-color: #132144;
}

.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #377dff;
}

.step-icon-soft-secondary {
  color: #406c8b;
  background-color: rgba(113, 134, 157, 0.1);
}

.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #406c8b;
}

.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}

.step-icon-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #00c9db;
}

.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}

.step-icon-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #ed4c78;
}

.step-icon-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.step-icon-soft-light.step-icon-pseudo::before {
  background-color: #f9fafc;
}

.step-icon-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #132144;
}

/*------------------------------------
  Step Inline
------------------------------------*/
.step-inline .step-content-wrapper {
  -ms-flex-align: center;
  align-items: center;
}

.step-inline .step-item:last-child .step-title::after {
  display: none;
}

.step-inline .step-title {
  display: inline-block;
}

@media (min-width: 576px) {
  .step-sm.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: '';
  }
  .step-sm.step-inline.step-icon-xs .step-content .step-title::after,
  .step-sm.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.76563rem;
  }
  .step-sm.step-inline.step-icon-sm .step-content .step-title::after,
  .step-sm.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-sm.step-inline.step-icon-lg .step-content .step-title::after,
  .step-sm.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 1.96875rem;
  }
}

@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: '';
  }
  .step-md.step-inline.step-icon-xs .step-content .step-title::after,
  .step-md.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.76563rem;
  }
  .step-md.step-inline.step-icon-sm .step-content .step-title::after,
  .step-md.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-md.step-inline.step-icon-lg .step-content .step-title::after,
  .step-md.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 1.96875rem;
  }
}

@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: '';
  }
  .step-lg.step-inline.step-icon-xs .step-content .step-title::after,
  .step-lg.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.76563rem;
  }
  .step-lg.step-inline.step-icon-sm .step-content .step-title::after,
  .step-lg.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-lg.step-inline.step-icon-lg .step-content .step-title::after,
  .step-lg.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 1.96875rem;
  }
}

@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: '';
  }
  .step-xl.step-inline.step-icon-xs .step-content .step-title::after,
  .step-xl.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.76563rem;
  }
  .step-xl.step-inline.step-icon-sm .step-content .step-title::after,
  .step-xl.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-xl.step-inline.step-icon-lg .step-content .step-title::after,
  .step-xl.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 1.96875rem;
  }
}

/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76563rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.96875rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.76563rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -1.96875rem;
  }
}

@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76563rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.96875rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.76563rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -1.96875rem;
  }
}

@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76563rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.96875rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.76563rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -1.96875rem;
  }
}

@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76563rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.96875rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.76563rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -1.96875rem;
  }
}

/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .table-nowrap th,
  .table-nowrap td {
    white-space: normal;
  }
  .step-item-between .step-item:last-child {
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
    width: auto;
  }
  .ie-modal-curved-shape {
    height: 1.75rem;
  }
  .ie-welcome-brands {
    width: 5rem;
  }
  .ie-sidebar-activity-img {
    width: 3.5rem;
  }
  .ie-card-img {
    width: 5.5rem;
  }
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

/*------------------------------------
  Borders
------------------------------------*/
.border-left-primary {
  border-left-color: #377dff !important;
}

.border-left-secondary {
  border-left-color: #406c8b !important;
}

.border-left-success {
  border-left-color: #00c9a7 !important;
}

.border-left-info {
  border-left-color: #00c9db !important;
}

.border-left-warning {
  border-left-color: #f5ca99 !important;
}

.border-left-danger {
  border-left-color: #ed4c78 !important;
}

.border-left-light {
  border-left-color: #f9fafc !important;
}

.border-left-dark {
  border-left-color: #132144 !important;
}

/*------------------------------------
  Gutters
------------------------------------*/
.g-0,
.gx-0 {
  margin-left: -0.0625rem;
  margin-right: -0.0625rem;
}

.g-0 > *,
.gx-0 > * {
  padding-left: 0;
  padding-right: 0;
}

.g-0,
.gy-0 {
  margin-top: -0;
  margin-bottom: -0;
}

.g-0 > *,
.gy-0 > * {
  padding-top: 0;
  padding-bottom: 0;
}

.g-1,
.gx-1 {
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;
}

.g-1 > *,
.gx-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.g-1,
.gy-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.g-1 > *,
.gy-1 > * {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.g-2,
.gx-2 {
  margin-left: -0.4375rem;
  margin-right: -0.4375rem;
}

.g-2 > *,
.gx-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.g-2,
.gy-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.g-2 > *,
.gy-2 > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.g-3,
.gx-3 {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.g-3 > *,
.gx-3 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.g-3,
.gy-3 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.g-3 > *,
.gy-3 > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.g-4,
.gx-4 {
  margin-left: -1.4375rem;
  margin-right: -1.4375rem;
}

.g-4 > *,
.gx-4 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.g-4,
.gy-4 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.g-4 > *,
.gy-4 > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.g-5,
.gx-5 {
  margin-left: -2.9375rem;
  margin-right: -2.9375rem;
}

.g-5 > *,
.gx-5 > * {
  padding-left: 3rem;
  padding-right: 3rem;
}

.g-5,
.gy-5 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.g-5 > *,
.gy-5 > * {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 576px) {
  .g-sm-0,
  .gx-sm-0 {
    margin-left: -0.0625rem;
    margin-right: -0.0625rem;
  }
  .g-sm-0 > *,
  .gx-sm-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    margin-top: -0;
    margin-bottom: -0;
  }
  .g-sm-0 > *,
  .gy-sm-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-sm-1 > *,
  .gx-sm-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-sm-1 > *,
  .gy-sm-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-sm-2 > *,
  .gx-sm-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-sm-2 > *,
  .gy-sm-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-sm-3 > *,
  .gx-sm-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-sm-3 > *,
  .gy-sm-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-sm-4 > *,
  .gx-sm-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-sm-4 > *,
  .gy-sm-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-sm-5 > *,
  .gx-sm-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-sm-5 > *,
  .gy-sm-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .g-md-0,
  .gx-md-0 {
    margin-left: -0.0625rem;
    margin-right: -0.0625rem;
  }
  .g-md-0 > *,
  .gx-md-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-md-0,
  .gy-md-0 {
    margin-top: -0;
    margin-bottom: -0;
  }
  .g-md-0 > *,
  .gy-md-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-md-1,
  .gx-md-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-md-1 > *,
  .gx-md-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-md-1 > *,
  .gy-md-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-md-2 > *,
  .gx-md-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-md-2 > *,
  .gy-md-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-md-3 > *,
  .gx-md-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-md-3 > *,
  .gy-md-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-md-4 > *,
  .gx-md-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-md-4 > *,
  .gy-md-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-md-5 > *,
  .gx-md-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-md-5 > *,
  .gy-md-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .g-lg-0,
  .gx-lg-0 {
    margin-left: -0.0625rem;
    margin-right: -0.0625rem;
  }
  .g-lg-0 > *,
  .gx-lg-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    margin-top: -0;
    margin-bottom: -0;
  }
  .g-lg-0 > *,
  .gy-lg-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-lg-1 > *,
  .gx-lg-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-lg-1 > *,
  .gy-lg-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-lg-2 > *,
  .gx-lg-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-lg-2 > *,
  .gy-lg-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-lg-3 > *,
  .gx-lg-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-lg-3 > *,
  .gy-lg-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-lg-4 > *,
  .gx-lg-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-lg-4 > *,
  .gy-lg-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-lg-5 > *,
  .gx-lg-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-lg-5 > *,
  .gy-lg-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1200px) {
  .g-xl-0,
  .gx-xl-0 {
    margin-left: -0.0625rem;
    margin-right: -0.0625rem;
  }
  .g-xl-0 > *,
  .gx-xl-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    margin-top: -0;
    margin-bottom: -0;
  }
  .g-xl-0 > *,
  .gy-xl-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-xl-1 > *,
  .gx-xl-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-xl-1 > *,
  .gy-xl-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-xl-2 > *,
  .gx-xl-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-xl-2 > *,
  .gy-xl-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-xl-3 > *,
  .gx-xl-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-xl-3 > *,
  .gy-xl-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-xl-4 > *,
  .gx-xl-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-xl-4 > *,
  .gy-xl-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-xl-5 > *,
  .gx-xl-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-xl-5 > *,
  .gy-xl-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1400px) {
  .g-xxl-0,
  .gx-xxl-0 {
    margin-left: -0.0625rem;
    margin-right: -0.0625rem;
  }
  .g-xxl-0 > *,
  .gx-xxl-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    margin-top: -0;
    margin-bottom: -0;
  }
  .g-xxl-0 > *,
  .gy-xxl-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-xxl-1 > *,
  .gx-xxl-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-xxl-1 > *,
  .gy-xxl-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-xxl-2 > *,
  .gx-xxl-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-xxl-2 > *,
  .gy-xxl-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-xxl-3 > *,
  .gx-xxl-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-xxl-3 > *,
  .gy-xxl-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-xxl-4 > *,
  .gx-xxl-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-xxl-4 > *,
  .gy-xxl-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-xxl-5 > *,
  .gx-xxl-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-xxl-5 > *,
  .gy-xxl-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-xs {
  opacity: 0.2;
}

.opacity-sm {
  opacity: 0.4;
}

.opacity {
  opacity: 0.6;
}

.opacity-lg {
  opacity: 0.8;
}

/*------------------------------------
  Position Spaces
------------------------------------*/
.top-0 {
  top: 0 !important;
}

.top-4 {
  top: 4px !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;
}

.top-70 {
  top: 70px !important;
}
.top-80 {
  top: 80px !important;
}


.right-0 {
  right: 0 !important;
}

.right-4 {
  right: 4px !important;
}
.right-10 {
  right: 10px !important;
}

.right-20 {
  right: 20px !important;
}

.right-65 {
  right: 65px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

/*------------------------------------
  Width
------------------------------------*/
.min-h-100 {
  min-height: 100%;
}

.min-h-100vh {
  min-height: 100vh;
}

@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh;
  }
  .min-vh-lg-100 {
    min-height: 100vh;
  }
}

/*------------------------------------
  Box Shadow
------------------------------------*/
.shadow-soft {
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25) !important;
}

/*------------------------------------
  Typography
------------------------------------*/
.text-cap {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03125rem;
}
.font-size-xxs {
  font-size: 10px;
}
.font-size-xs {
  font-size: 0.75rem !important;
}

.font-size-sm {
  font-size: 0.8125rem;
}
.font-size-md {
  font-size: 0.9125rem;
}
.font-size-lg {
  font-size: 1rem;
}
.font-size-xl {
  font-size: 1.2rem;
}

.font-size-2xl {
  font-size: 1.4rem;
}
.font-size-3xl {
  font-size: 1.8rem;
}
.font-size-4xl {
  font-size: 2rem;
}

.font-size-5xl {
  font-size: 2.5rem;
}

/*------------------------------------
  Text Colors
------------------------------------*/

.text-green {
  color: var(--green) !important;
}

.text-yellow-bright {
  color: #FFFF00 !important;
}

.text-red {
  color: var(--red) !important;
}

.text-blue {
  color: var(--blue) !important;
}

.text-purple {
  color: var(--purple) !important;
}

.text-pink {
  color: var(--pink) !important;
}

.text-orange {
  color: var(--orange) !important;
}

.text-dark {
  color: #1e2022 !important;
}

.text-dark[href]:hover {
  color: #377dff !important;
}

.text-secondary[href]:hover {
  color: #377dff !important;
}

.text-muted[href]:hover,
.text-body[href]:hover {
  color: #0052ea !important;
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-70[href]:hover {
  color: #fff;
}

a:hover .text-hover-primary {
  color: #0052ea !important;
}

.text-warning {
  color: #ec9a3c !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-2 {
  z-index: 2;
}
.z-index-10 {
  z-index: 10;
}

.z-index-9999 {
  z-index: 9999;
}

/*------------------------------------
  Chartjs
------------------------------------*/
.chartjs-custom {
  position: relative;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.cmz-chartjs-tooltip-wrap {
  position: absolute;
  z-index: 3;
  transition: opacity 0.2s ease-in-out, left 0.2s ease, top 0.2s ease;
}

.cmz-chartjs-tooltip {
  position: relative;
  font-size: 0.75rem;
  background-color: #132144;
  border-radius: 0.3125rem;
  padding: 0.54688rem 0.875rem;
  transition: opacity 0.2s ease-in-out, left 0.2s ease, top 0.2s ease, top 0s;
}

.cmz-chartjs-tooltip::before {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: -0.4375rem;
  width: 1rem;
  height: 0.5rem;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='1rem' height='0.5rem' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 50 22.49'%3E%3Cpath fill='%23132144' d='M0,0h50L31.87,19.65c-3.45,3.73-9.33,3.79-12.85,0.13L0,0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 0.5rem;
}

.cmz-chartjs-tooltip-left {
  left: -130%;
}

.cmz-chartjs-tooltip-left::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -0.6875rem;
  left: auto;
  -webkit-transform: translateY(-50%) rotate(270deg);
  transform: translateY(-50%) rotate(270deg);
}

.cmz-chartjs-tooltip-right {
  left: 30%;
}

.cmz-chartjs-tooltip-right::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -0.6875rem;
  right: auto;
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.cmz-chartjs-tooltip-header {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  white-space: nowrap;
}

.cmz-chartjs-tooltip-body {
  color: #fff;
}

/*------------------------------------
  Chartjs Doughnut
------------------------------------*/
.chartjs-doughnut-custom {
  position: relative;
}

.chartjs-doughnut-custom-stat {
  position: absolute;
  top: 8rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/*------------------------------------
  Chartjs Matrix
------------------------------------*/
.chartjs-matrix-custom {
  position: relative;
}

.cmz-chartjs-matrix-legend {
  display: inline-block;
  position: relative;
  height: 2.5rem;
  list-style: none;
  padding-left: 0;
}

.cmz-chartjs-matrix-legend-item {
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
}

.cmz-chartjs-matrix-legend-min {
  position: absolute;
  left: 0;
  bottom: 0;
}

.cmz-chartjs-matrix-legend-max {
  position: absolute;
  right: 0;
  bottom: 0;
}

/*------------------------------------
  Circles
------------------------------------*/
.circle-custom-text {
  z-index: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/*------------------------------------
  Datatable
------------------------------------*/
.datatable-custom .sorting,
.datatable-custom .sorting_asc,
.datatable-custom .sorting_desc {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.datatable-custom .sorting:after,
.datatable-custom .sorting_asc:after,
.datatable-custom .sorting_desc:after {
  position: absolute;
  top: 50%;
  width: 0.7rem;
  height: 0.7rem;
  background: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
  content: '';
  margin-left: 0.5rem;
  margin-top: 0.125rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.datatable-custom .sorting_asc:after {
  background: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%2300c9a7'/%3E%3C/svg%3E%0A");
  content: '';
}

.datatable-custom .sorting_desc:after {
  background: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%2300c9a7'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
  content: '';
}

.datatable-custom-pagination {
  margin-bottom: 0;
}

.datatable-custom-content-box.dataTable,
.datatable-custom-content-box.dataTable th,
.datatable-custom-content-box.dataTable td {
  box-sizing: content-box;
}

.datatable-custom-centered .dataTable {
  margin: 0 auto;
}

.datatable-custom-collapsible td.details-control {
  position: relative;
}

.datatable-custom-collapsible td.details-control::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: '';
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.datatable-custom-collapsible tr.shown td.details-control::before {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

/*------------------------------------
  Datatable Custom Theme
------------------------------------*/

.datatable-custom .top.card-body,
.datatable-custom .bottom.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datatable-custom .top.card-body label {
  margin-bottom: 0;
}

.datatable-custom .dataTables_filter input {
  margin-left: 10px;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.datatable-custom .dataTables_length select {
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 1rem);
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 0.65625rem;
  padding-right: 1.5625rem;
  margin: 0 0.4375rem;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right 0.5rem center/1rem 1rem;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.datatable-custom .dataTables_paginate .paginate_button {
  display: inline-block;
  font-weight: 400;
  color: #677788;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0;
  padding: 0.5rem 0.875rem;
  line-height: 1.25;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.datatable-custom .dataTables_paginate .paginate_button.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
}

.datatable-custom .dataTables_paginate .paginate_button.current {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.datatable-custom .dataTables_paginate .paginate_button:not(.disabled):hover {
  text-decoration: none;
  color: #00c9a7;
  background-color: rgba(55, 125, 255, 0.1);
}

.datatable-custom .dataTables_processing {
  position: absolute;
  background: rgb(255 255 255 / 55%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 0.75rem;
  pointer-events: none;
  user-select: none;
  text-align: center;
}

.datatable-custom .dataTables_processing > div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*------------------------------------
  Daterangepicker
------------------------------------*/
.daterangepicker-custom-input {
  cursor: pointer;
}

.daterangepicker-wrapper {
  border: none;
  padding: 0;
  cursor: pointer;
}

.daterangepicker {
  border: none;
  font-family: 'Open Sans', sans-serif;
  z-index: 98;
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.75rem;
}

.daterangepicker::before {
  border-bottom-color: transparent;
}

.daterangepicker .drp-calendar {
  max-width: 18.75rem;
}

.daterangepicker .drp-calendar.left {
  padding: 1rem 1rem;
}

.daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}

.daterangepicker .calendar-table table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
}

.daterangepicker .calendar-table th.prev:hover,
.daterangepicker .calendar-table th.next:hover {
  background-color: rgba(55, 125, 255, 0.1);
}

.daterangepicker .calendar-table th.prev:hover span,
.daterangepicker .calendar-table th.next:hover span {
  color: #377dff;
}

.daterangepicker .calendar-table th.prev span,
.daterangepicker .calendar-table th.next span {
  position: relative;
  border: none;
  color: #677788;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.daterangepicker .calendar-table th.prev,
.daterangepicker .calendar-table th.next {
  min-width: auto;
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
}

.daterangepicker .calendar-table th.prev span,
.daterangepicker .calendar-table th.next span {
  padding: 0;
}

.daterangepicker .calendar-table th.prev span::before,
.daterangepicker .calendar-table th.next span::before {
  display: inline-block;
  background-color: #677788;
  width: 0.6125rem;
  height: 0.6125rem;
  content: '';
}

.daterangepicker .calendar-table th.prev span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/0.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th.prev:hover span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/0.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th.next span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/0.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th.next:hover span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/0.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th:not(.month) {
  color: #97a4af;
  font-weight: 600;
  text-transform: uppercase;
}

.daterangepicker .calendar-table th.month {
  font-size: 0.875rem;
  font-weight: 600;
}

.daterangepicker .calendar-table td {
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 0.875rem;
  line-height: 1.9375rem;
}

.daterangepicker td.available:not(.in-range) {
  border-radius: 50%;
}

.daterangepicker td.available:hover:not(.active) {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.daterangepicker td.in-range,
.daterangepicker td.in-range.available:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.daterangepicker td.today.start-date.end-date {
  border-radius: 50%;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: #fff;
  background-color: #377dff;
}

.daterangepicker td.active.start-date,
.daterangepicker td.active:hover.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.daterangepicker td.active.end-date,
.daterangepicker td.active:hover.end-date {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range {
  color: #e7eaf3;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left,
.daterangepicker .drp-buttons {
  border-color: #e7eaf3;
}

.daterangepicker .drp-buttons {
  padding: 1rem 1rem;
}

.daterangepicker .drp-buttons .btn {
  font-weight: 400;
  padding: 0.4375rem 0.65625rem;
}

.daterangepicker .cancelBtn {
  background-color: #fff;
  border-color: #e7eaf3;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus,
.daterangepicker .cancelBtn:active,
.daterangepicker .cancelBtn.active {
  color: #0052ea;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.daterangepicker .drp-selected {
  color: #406c8b;
}

@media (max-width: 575.98px) {
  .daterangepicker .drp-selected {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.daterangepicker .ranges ul {
  min-width: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.daterangepicker .ranges li {
  color: #406c8b;
  font-size: 0.875rem;
  line-height: 1.6;
  padding: 0.375rem 1.5rem;
}

.daterangepicker .ranges li:hover {
  color: #0052ea;
  background-color: transparent;
}

.daterangepicker .ranges li.active {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  background-color: transparent;
}

/*------------------------------------
  Dropzone
------------------------------------*/
.dropzone-custom {
  cursor: pointer;
}

.dropzone-custom .dz-message {
  width: 100%;
  text-align: center;
}

.dropzone-custom .dz-details {
  margin-bottom: 1rem;
}

.dropzone-custom .dz-file-preview {
  background-color: #fff;
  border-radius: 0.3125rem;
  padding: 1rem 1rem;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.dropzone-custom .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropzone-custom .dz-filename {
  margin-bottom: 0.25rem;
}

.dropzone-custom .dz-img {
  max-width: 2.625rem;
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}

.dropzone-custom .dz-img-inner {
  border-radius: 0.3125rem;
}

.dropzone-custom .dz-close-icon,
.dropzone-custom .dz-size {
  color: #677788;
}

.dropzone-custom .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}

.dropzone-custom .dz-size {
  font-size: 80%;
}

.dropzone-custom .dz-file-initials {
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  line-height: 2.625rem;
  font-weight: 600;
  font-size: 0.92969rem;
  color: #377dff;
  text-align: center;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}

.dropzone-custom [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}

.dropzone-custom .dz-progress {
  margin-bottom: 1rem;
}

.dropzone-custom .dz-processing .dz-error-mark,
.dropzone-custom .dz-processing .dz-success-mark {
  display: none;
}

.dropzone-custom .dz-processing.dz-error .dz-error-mark,
.dropzone-custom .dz-processing.dz-success .dz-success-mark {
  display: block;
}

.dropzone-custom .dz-processing .dz-error-mark {
  color: #ed4c78;
}

.dropzone-custom .dz-processing .dz-success-mark {
  color: #00c9a7;
}

/*------------------------------------
  Flatpickr
------------------------------------*/
.flatpickr-custom {
  position: relative;
  width: 100% !important;
}

.flatpickr-custom .flatpickr-calendar {
  top: calc(1.6em + 1.21875rem) !important;
  left: 0 !important;
  width: auto;
}

.form-control[readonly],
.flatpickr-custom-form-control[readonly] {
  background-color: transparent;
}

.flatpickr-calendar {
  padding: 1rem 1rem;
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.75rem;
}

.flatpickr-calendar::before,
.flatpickr-calendar::after {
  display: none;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar .flatpickr-months {
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.75rem;
}

.flatpickr-calendar .flatpickr-current-month,
.flatpickr-calendar .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-next-month {
  padding: 0;
}

.flatpickr-calendar .flatpickr-current-month {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
}

.flatpickr-calendar .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-next-month {
  position: static;
  color: #677788;
  width: 2.1875rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  background-color: transparent;
  border-radius: 50%;
}

.flatpickr-calendar .flatpickr-prev-month:hover,
.flatpickr-calendar .flatpickr-next-month:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.flatpickr-calendar .flatpickr-weekday {
  color: #97a4af;
  text-transform: uppercase;
  font-weight: 600;
}

.flatpickr-calendar .rangeMode .flatpickr-day {
  margin-top: 0.25rem;
}

.flatpickr-calendar .flatpickr-monthDropdown-months {
  font-size: 0.875rem;
  font-weight: 600;
}

.flatpickr-calendar .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
}

.flatpickr-calendar .flatpickr-current-month input.cur-year {
  font-size: 0.875rem;
  font-weight: 600;
}

.flatpickr-calendar .flatpickr-day {
  border-color: transparent;
}

.flatpickr-calendar .flatpickr-day:focus,
.flatpickr-calendar .flatpickr-day:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.selected.inRange,
.flatpickr-calendar .flatpickr-day.startRange.inRange,
.flatpickr-calendar .flatpickr-day.endRange.inRange,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.startRange:focus,
.flatpickr-calendar .flatpickr-day.endRange:focus,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange:hover,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.flatpickr-calendar .flatpickr-day.inRange {
  color: #fff;
  background-color: #377dff;
  box-shadow: -0.5rem 0 0 #377dff, 0.3125rem 0 0 #377dff;
}

.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:hover {
  color: #fff;
  border-color: #377dff;
  background-color: #377dff;
}

.flatpickr-calendar .flatpickr-day.today {
  color: #fff;
  border-color: transparent;
  background-color: #377dff;
}

.flatpickr-calendar .flatpickr-day.prevMonthDay,
.flatpickr-calendar .flatpickr-day.nextMonthDay {
  color: #e7eaf3;
}

.flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.nextMonthDay:hover {
  color: #bdc5d1;
  background-color: #e7eaf3;
  border-color: #e7eaf3;
}

.flatpickr-calendar .flatpickr-day.disabled {
  color: #8c98a4;
}

.flatpickr-calendar .flatpickr-day.disabled:hover {
  color: #8c98a4;
  background-color: #e7eaf3;
  border-color: #e7eaf3;
}

.flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -0.5rem 0 0 #377dff, 0.3125rem 0 0 #377dff;
}

.flatpickr-calendar .numInputWrapper span.arrowUp,
.flatpickr-calendar .numInputWrapper span.arrowDown {
  display: none;
}

.flatpickr-calendar .numInputWrapper:hover {
  background-color: transparent;
}

.flatpickr-custom-borderless {
  width: 0 !important;
  min-width: 6.5rem !important;
}

.flatpickr-custom-borderless .input-group-text {
  border: 0;
  padding-right: 0 !important;
}

.flatpickr-custom-borderless .flatpickr-custom-form-control {
  border: none;
  cursor: pointer;
  box-shadow: none;
  padding-right: 0;
  color: #377dff;
}

/*------------------------------------
  Form search
------------------------------------*/
.cmz-form-search-menu-content {
  position: absolute;
  display: block !important;
  opacity: 0;
  pointer-events: none;
}

.cmz-form-search-menu-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

.cmz-form-search-menu-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

/*------------------------------------
  Loading State
------------------------------------*/
.cmz-loader-wrapper {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: none;
  background-color: #fff;
  border-radius: 0.3125rem;
}

.cmz-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

/*------------------------------------
  Unfold
------------------------------------*/
.cmz-unfold {
  position: relative;
  display: inline-block;
}

.cmz-unfold-content {
  display: block !important;
  opacity: 0;
  pointer-events: none;
}

.cmz-unfold-content-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.cmz-unfold-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

.cmz-unfold-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(19, 33, 68, 0.25);
}

.cmz-unfold-content.cmz-unfold-reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0;
}

.cmz-unfold-has-submenu {
  top: 0;
  right: calc(100% + 0.75rem);
  left: auto;
  margin-top: 0;
}

.cmz-unfold-has-submenu::after {
  position: absolute;
  top: 0;
  display: block;
  left: calc(100% - 0.0625rem);
  width: 1rem;
  height: 100%;
  content: '';
}

.cmz-unfold-has-submenu-right {
  top: 0;
  right: auto;
  left: calc(100% + 0.75rem);
  margin-top: 0;
}

.cmz-unfold-has-submenu-right::after {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1rem;
  height: 100%;
  content: '';
}

/*------------------------------------
  Nav Scroll Horizontal
------------------------------------*/
.cmz-nav-scroller-horizontal {
  position: relative;
}

.cmz-nav-scroller-horizontal .nav {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.cmz-nav-scroller-horizontal .nav .nav-item {
  white-space: nowrap;
}

.cmz-nav-scroller-horizontal .nav::-webkit-scrollbar {
  display: none;
}

.cmz-nav-scroller-horizontal .nav-tabs {
  padding-bottom: 2px;
}

.cmz-nav-scroller-horizontal .cmz-nav-scroller-arrow-prev,
.cmz-nav-scroller-horizontal .cmz-nav-scroller-arrow-next {
  position: absolute;
  height: 100%;
  z-index: 1;
  font-size: 1.3125rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.cmz-nav-scroller-horizontal .cmz-nav-scroller-arrow-link {
  width: 2.625rem;
  color: #677788;
}

.cmz-nav-scroller-horizontal .cmz-nav-scroller-arrow-link:hover {
  color: #0052ea;
}

.cmz-nav-scroller-horizontal .cmz-nav-scroller-arrow-prev {
  left: 0;
  margin-left: -0.125rem;
}

.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-prev
  .cmz-nav-scroller-arrow-link {
  padding: 0.75rem 0;
  background-image: linear-gradient(
    to right,
    #fff 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: repeat-x;
}

.cmz-nav-scroller-horizontal .cmz-nav-scroller-arrow-next {
  right: 0;
  margin-right: -0.125rem;
}

.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-next
  .cmz-nav-scroller-arrow-link {
  padding: 0.75rem 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    #fff 50%
  );
  background-repeat: repeat-x;
  text-align: right;
}

.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-dark-prev
  .cmz-nav-scroller-arrow-link,
.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-dark-next
  .cmz-nav-scroller-arrow-link {
  color: rgba(255, 255, 255, 0.7);
}

.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-dark-prev
  .cmz-nav-scroller-arrow-link:hover,
.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-dark-next
  .cmz-nav-scroller-arrow-link:hover {
  color: #fff;
}

.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-dark-prev
  .cmz-nav-scroller-arrow-link {
  background-image: linear-gradient(
    to right,
    #132144 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: repeat-x;
}

.cmz-nav-scroller-horizontal
  .cmz-nav-scroller-arrow-dark-next
  .cmz-nav-scroller-arrow-link {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    #132144 50%
  );
  background-repeat: repeat-x;
}

.cmz-nav-scroller-vertical {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.cmz-nav-scroller-vertical::-webkit-scrollbar {
  width: 0.6125rem;
}

.cmz-nav-scroller-vertical::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.cmz-nav-scroller-unfold {
  position: static;
}

/*------------------------------------
  Fullscreen
------------------------------------*/
.cmz-fullscreen {
  position: fixed !important;
  z-index: 1059;
  top: 0;
  left: 0;
  right: 0;
  height: 100% !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 0;
}

.cmz-fullscreen .cmz-fullscreen-icon-default {
  display: none;
}

.cmz-fullscreen .cmz-fullscreen-icon-active {
  display: block;
}

.cmz-fullscreen-on {
  overflow: hidden;
}

.cmz-fullscreen-icon-default {
  display: block;
}

.cmz-fullscreen-icon-active {
  display: none;
}

/*------------------------------------
  Range Slider
------------------------------------*/
.range-slider-custom {
  height: 1.25rem;
}

.range-slider-custom .irs {
  height: 1.25rem;
}

.range-slider-custom .irs-line {
  height: 0.25rem;
}

.range-slider-custom .irs-bar {
  height: 0.25rem;
  background-color: #377dff;
}

.range-slider-custom .irs-handle {
  width: 1.53125rem;
  height: 1.53125rem;
  top: 0.76563rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.range-slider-custom .irs-handle i:first-child {
  display: none;
}

.range-slider-custom .irs-handle.state_hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.range-slider-custom .irs-from,
.range-slider-custom .irs-to,
.range-slider-custom .irs-single {
  top: -2.25rem;
  display: inline-block;
  min-width: 2.5rem;
  background-color: #fff;
  color: #1e2022;
  font-size: 0.8125rem;
  text-shadow: none;
  text-align: center;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  border-radius: 0.3125rem;
  padding: 0.5rem 0.5rem;
}

.range-slider-custom .irs-from::before,
.range-slider-custom .irs-to::before,
.range-slider-custom .irs-single::before {
  border-top-color: #fff;
}

/* Grid */
.range-slider-grid .irs-grid-pol {
  top: 0.5rem;
  height: 0.75rem;
  background-color: #e7eaf3;
}

.range-slider-grid .irs-grid-pol.small {
  display: none;
}

.range-slider-grid .irs-grid-text {
  top: 2rem;
  font-size: 0.875rem;
}

/*------------------------------------
  JVectormap
------------------------------------*/
.jvectormap-custom {
  width: 100%;
  height: 100%;
}

.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.jvectormap-tip,
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  border-radius: 0.25rem;
}

.jvectormap-tip {
  z-index: 100;
  display: none;
  color: #1e2022;
  background-color: #fff;
  padding: 0.54688rem 0.875rem;
  margin: -0.875rem;
  box-shadow: 0px 0.375rem 1.5rem 0px rgba(140, 152, 164, 0.125);
}

.jvectormap-tip::before {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: -0.4375rem;
  width: 1rem;
  height: 0.5rem;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='1rem' height='0.5rem' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 50 22.49'%3E%3Cpath fill='%23fff' d='M0,0h50L31.87,19.65c-3.45,3.73-9.33,3.79-12.85,0.13L0,0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 0.5rem;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #1e2022;
  background-color: #fff;
  width: 1.53125rem;
  height: 1.53125rem;
  cursor: pointer;
}

.jvectormap-zoomin:hover,
.jvectormap-zoomin:focus,
.jvectormap-zoomout:hover,
.jvectormap-zoomout:focus {
  color: #0052ea;
  background-color: #f2f2f2;
}

.jvectormap-zoomin {
  top: 1rem;
  left: 1rem;
}

.jvectormap-zoomout {
  top: 2.84375rem;
  left: 1rem;
}

/*------------------------------------
  Leaflet
------------------------------------*/
.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  border-radius: 0.3125rem;
}

.leaflet-popup {
  margin-bottom: 3rem;
}

/*------------------------------------
  Quill Editor
------------------------------------*/
.ck-editor-small .ck-editor__editable {
  min-height: 200px !important;
}

.quill-custom {
  position: relative;
}

.quill-custom .ql-container {
  position: static;
}

.quill-custom .ql-toolbar.ql-snow,
.quill-custom .ql-container.ql-snow {
  border-color: #e7eaf3;
}

.quill-custom .ql-toolbar.ql-snow {
  padding: 0.75rem 1.3125rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.quill-custom .ql-container.ql-snow {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.quill-custom.ql-toolbar-bottom .ql-toolbar.ql-snow {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.quill-custom.ql-toolbar-bottom .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border: 0.0625rem solid #e7eaf3;
}

.quill-custom .ql-toolbar-list {
  line-height: normal;
  margin-bottom: 0;
}

.quill-custom .ql-formats:first-child {
  padding-left: 0;
}

.quill-custom .ql-formats:first-child button {
  margin-right: 0.5rem;
}

.quill-custom .ql-formats:first-child button:first-child {
  margin-left: -0.25rem;
}

.quill-custom .ql-editor {
  position: relative;
  min-height: 10rem;
  padding: 1.3125rem 1.3125rem;
}

.quill-custom .ql-editor p {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  color: #1e2022;
}

.quill-custom .ql-editor-content {
  min-height: 10rem;
}

.quill-custom .ql-editor.ql-blank::before {
  left: 1.3125rem;
  color: #97a4af;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
}

.quill-custom .ql-snow a.btn-primary {
  color: #fff;
}

.quill-custom .ql-snow.ql-toolbar .ql-fill {
  fill: #677788;
}

.quill-custom .ql-snow.ql-toolbar .ql-stroke {
  stroke: #677788;
}

.quill-custom .ql-snow.ql-toolbar button:hover {
  color: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button,
.quill-custom .ql-snow .ql-toolbar button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem 0.25rem;
}

.quill-custom .ql-snow .ql-tooltip {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 1;
  min-width: 20rem;
  border-width: 0;
  text-align: center;
  box-shadow: 0px 10px 40px 10px rgba(140, 152, 164, 0.175);
  padding: 1.5rem 1.5rem;
  border-radius: 0.3125rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.quill-custom .ql-snow .ql-tooltip::before {
  display: block;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 0.0625rem solid #e7eaf3;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text'] {
  min-width: 20rem;
  font-size: 1rem;
  line-height: normal;
  border: 0.0625rem solid #e7eaf3;
  padding: 0.875rem 1.09375rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview {
  display: block;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
  border-color: #377dff;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text'] {
  height: auto;
  display: block;
  font-family: 'Open Sans', sans-serif;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type='text']:focus {
  border-color: rgba(55, 125, 255, 0.4);
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}

.quill-custom .ql-snow .ql-action {
  display: inline-block;
  color: #fff;
  background-color: #377dff;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.875rem 1.09375rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-action:hover {
  background-color: #1366ff;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove::before,
.quill-custom .ql-snow .ql-tooltip a.ql-action::after {
  padding-right: 0;
  margin-left: 0;
  border-right: none;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove {
  border: 0.0625rem solid #e7eaf3;
  font-size: 0.875rem;
  padding: 0.875rem 1.09375rem;
  border-radius: 0.3125rem;
  margin-left: 0.5rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
  color: #0052ea;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Select2
------------------------------------*/
.select2-custom {
  position: relative;
}

.select2-custom .select2-custom-hide {
  display: none;
}

.select2-custom-right .select2-container--open {
  right: 0 !important;
  left: auto !important;
}

@media (min-width: 576px) {
  .select2-custom-sm-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}

@media (min-width: 768px) {
  .select2-custom-md-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}

@media (min-width: 992px) {
  .select2-custom-lg-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}

@media (min-width: 1200px) {
  .select2-custom-xl-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}

@media (min-width: 1400px) {
  .select2-custom-xxl-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}

.select2-dropdown {
  border-color: #e7eaf3;
}

.select2-container--open .select2-dropdown--below {
  border-radius: 0.3125rem;
  border-top: 0.0625rem solid #e7eaf3;
  margin-top: 0.5rem;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-radius: 0.3125rem;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: rgba(55, 125, 255, 0.4);
}

.select2-container--default .custom-select.select2-selection--multiple,
.select2-container--default .form-control.select2-selection--multiple {
  height: auto;
  min-height: calc(1.6em + 1.21875rem);
}

.select2-container--default
  .form-control.select2-selection--multiple
  .select2-selection__choice {
  margin-top: 0.5rem;
}

.select2-container--default
  .form-control.select2-selection--multiple
  .select2-search--inline {
  margin-top: 0.25rem;
}

.select2-container--default
  .form-control-lg.select2-selection--multiple
  .select2-selection__choice {
  margin-top: 0.6125rem;
}

.select2-container--default .select2-selection--multiple {
  border-color: #e7eaf3;
  padding: 0 0;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  position: relative;
  background-color: #e7eaf3;
  border-color: transparent;
  padding-right: 1.125rem;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  position: absolute;
  right: 0.125rem;
  color: #406c8b;
}

.select2-container--default .select2-search__field::-webkit-input-placeholder {
  color: #97a4af;
}

.select2-container--default .select2-search__field::-moz-placeholder {
  color: #97a4af;
}

.select2-container--default .select2-search__field:-ms-input-placeholder {
  color: #97a4af;
}

.select2-container--default .select2-search__field::-ms-input-placeholder {
  color: #97a4af;
}

.select2-container--default .select2-search__field::placeholder {
  color: #97a4af;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e7eaf3;
  background-color: #f9fafc;
  padding: 0.4375rem 0.65625rem;
  border-radius: 0.3125rem;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field::-webkit-input-placeholder {
  color: #97a4af;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field::-moz-placeholder {
  color: #97a4af;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field:-ms-input-placeholder {
  color: #97a4af;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field::-ms-input-placeholder {
  color: #97a4af;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field::placeholder {
  color: #97a4af;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  border-color: rgba(55, 125, 255, 0.4);
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}

.select2-container--default
  .select2-selection.active
  .select2-selection__placeholder {
  color: #1e2022;
}

.select2-container--default
  .custom-select
  .select2-search--inline
  .select2-search__field,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin-top: 0.5rem;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding-right: 0.875rem;
  padding-left: 0.875rem;
}

.select2-container--default .select2-results__option {
  padding: 0.5rem 3rem 0.5rem 0.5rem;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}

.select2-container--default .select2-results__option:first-child,
.select2-container--default .select2-results__option:first-child:hover {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.select2-container--default .select2-results__option:last-child,
.select2-container--default .select2-results__option:last-child:hover {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.select2-container--default .select2-results__option {
  position: relative;
}

.select2-container--default
  .select2-results__option[aria-selected='true']::after {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: '';
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected]:not([aria-selected='true']) {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e7eaf3;
}

.select2-selection__placeholder {
  color: #97a4af;
}

.select2-selection--multiple .select2-selection__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 0.75rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*------------------------------------
  SortableJS
------------------------------------*/
.sortablejs-custom.sortable-ghost {
  opacity: 0.3;
}

.sortablejs-custom-rotate.sortable-fallback {
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 1 !important;
}

.sortablejs-custom-handle {
  cursor: pointer;
}

/*------------------------------------
  Tagify
------------------------------------*/
.tagify {
  --tag-bg: unset;
  --tag-hover: unset;
  -ms-flex-align: center;
  align-items: center;
  border-color: #e7eaf3;
  border-radius: 0.25rem;
}

.tagify:hover {
  border-color: #e7eaf3;
}

.tagify.tagify--focus {
  border-color: rgba(55, 125, 255, 0.4);
}

.tagify__tag {
  margin: 0.25rem 0.25rem 0.25rem 0;
  background-color: #e7eaf3;
  border-radius: 0.25rem;
}

.tagify__tag:first-child {
  margin-left: 0;
}

.tagify__tag > div {
  color: #1e2022;
  line-height: normal;
  padding: 0.3125rem 0.875rem;
  border-radius: 0.25rem;
}

.tagify__tag > div * {
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: baseline;
}

.tagify__tag__removeBtn {
  width: 0.875rem;
  height: 0.875rem;
  line-height: 0.875rem;
}

.tagify__tag__removeBtn:hover {
  background-color: #ed4c78;
}

.tagify__tag__removeBtn:hover + div::before {
  box-shadow: none !important;
}

.tagify__tag__removeBtn:hover + div .tagify__tag-text {
  opacity: 1;
}

.tagify-form-control {
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 0 0.25rem;
}

.tagify__input:first-child {
  padding-left: 0;
  margin-left: 0;
}

.tagify__input::before {
  color: #97a4af;
  line-height: 1.4;
}

.tagify__input .tagify__tag > div {
  padding: 0.3125rem 0.875rem;
  line-height: normal;
}

.tagify__dropdown__menu {
  border: 0.0625rem solid #e7eaf3;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  margin-top: -1px;
}

.tagify__dropdown__item--active {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.tagify.tagify-form-control-list {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tagify__dropdown__wrapper {
  border: none;
}

/*------------------------------------
  File for your custom CSS style
------------------------------------*/

.btn-loading {
  position: relative;
  color: transparent !important;
  text-shadow: none !important;
  pointer-events: none;
}

.btn-loading:after {
  content: '';
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  color: #ffffff;
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.btn-loading.btn-outline-primary:after {
  color: #377dff;
}

.btn-loading.btn-outline-success:after {
  color: #00c9a7;
}

.btn-loading.btn-outline-danger:after {
  color: #ed4c78;
}

.btn-loading.btn-outline-warning:after {
  color: #f5ca99;
}

.btn-loading.btn-outline-info:after {
  color: #00c9db;
}

.btn-loading.btn-outline-secondary:after {
  color: #406c8b;
}

.btn-loading.btn-outline-dark:after {
  color: #132144;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-help {
  cursor: help !important;
}

.navbar .fa,
.navbar .fab,
.navbar .fad,
.navbar .fal,
.navbar .far,
.navbar .fas {
  font-size: 1rem;
}

button .fa,
button .fab,
button .fad,
button .fal,
button .far,
button .fas,
a .fa,
a .fab,
a .fad,
a .fal,
a .far,
a .fas {
  margin-right: 0.2rem;
  pointer-events: none;
}

img.colorless-image {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
}

img.colorless-image:hover {
  -webkit-filter: grayscale(0);
  filter: none;
  opacity: 1;
  cursor: pointer;
  transition: 300ms all;
}

/*------------------------------------
  Fusion Chart Custom CSS style
------------------------------------*/

svg > g[class^='raphael-group-'] > text {
  display: none;
}

.fc__tooltip {
  font-size: 16px !important;
}

/*------------------------------------
  Frame Player CSS style
------------------------------------*/
#player > canvas {
  object-fit: contain;
}

/*------------------------------------
  ReactDatepicker Custom CSS style
------------------------------------*/
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker-popper.all-time .react-datepicker__time-container {
  width: 100%;
}

.react-datepicker-popper.all-time
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
}

/*------------------------------------
  Custom Hover CSS style
------------------------------------*/
.hover-success,
.hover-secondary {
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.hover-success:hover {
  color: #00c9a7 !important;
  background-color: rgba(0, 201, 167, 0.1);
}

.hover-secondary:hover {
  color: #406c8b !important;
  background-color: rgba(19, 33, 68, 0.1);
}

/*------------------------------------
  React-Flow CSS style
------------------------------------*/
.react-flow__node {
  padding: 10px;
  border-radius: 3px;
  min-width: 150px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
}

.react-flow__node-source {
  border-color: #377dff;
}

.react-flow__node-analytic_types {
  border-color: #f09038;
}

.react-flow__node-actions {
  border-color: #00c9a7;
}

.react-flow__node-step {
  border-color: #f5ca99;
}

.react-flow__node-condition {
  border-color: #00c9db;
}

.react-flow .react-flow__node.selected {
  background-color: #fbfbfb;
}

.react-flow__handle {
  width: 8px;
  height: 8px;
}

.react-flow .react-flow__node:hover {
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
}

.react-flow .node__tools {
  position: absolute;
  z-index: 4;
  right: 40px;
  top: 40px;
}

.node__tools--buttons {
  background-color: #fff;
  border-width: 0.0625rem;
  border-style: solid;
  padding: 0.54688rem 0.875rem;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  cursor: grab;
}

.node__tools--buttons.node-source {
  border-color: #377dff;
  color: #377dff;
}

.node__tools--buttons.node-step {
  border-color: #f5ca99;
  color: #f5ca99;
}

.node__tools--buttons.node-condition {
  border-color: #00c9db;
  color: #00c9db;
}

.node__tools--buttons.node-result {
  border-color: #406c8b;
  color: #406c8b;
  user-select: none;
}

.node__tools--buttons.node-reset {
  border-color: #ed4c78;
  color: #ed4c78;
  user-select: none;
}

.node__tools--buttons[disabled],
.node__tools--buttons:disabled {
  opacity: 0.55;
  pointer-events: none;
}

/*------------------------------------
  Not Confirmation Account Warning CSS
------------------------------------*/
#accountConfirmationWarning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
  width: 100%;
  border-radius: 0;
}

.application-header {
  height: 70px;
  align-items: center;
}

.radius-md {
  border-radius: 1.25rem !important;
}

.application-body.radius {
  border-radius: 0 0 50px 50px !important;
}
.application-side-bar {
  height: calc(100vh - 70px);
  border-top: 1px solid #e3e3e3;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.application-header .name-input {
  min-width: 100%;
}
.application-header .name-input input {
  border-radius: 0;
  border: none !important;
  height: 50px;
  background: transparent !important;
  font-size: 22px;
  width: 80%;
}
.application-header .name-input input:hover {
  text-decoration: underline;
}
.application-header .colorful-mini{
  display: none;
}

@media only screen  and (max-width : 1100px ){
  .application-header .colorful{
    display: none;
  }
  .application-header .colorful-mini{
    display: block;
  }
  .application-header button span{
    display:none;
  }
  .application-header button svg{
    margin:0 !important;
  }

}

.application-side-bar .add-button {
  z-index: 90;
}
.application-content {
  height: calc(100vh - 70px);
  overflow: auto;
  position: relative;
}
.application-content .content-header {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
  z-index: 100 !important;
}
.application-content .content-body {
  height: calc(100vh - 150px);
  overflow-x: auto;
  padding: 0;
  z-index: 2 !important;
}
.application-content .content-body .builder-component {
  height: 140px;
}
.application-content .content-body .builder-component:hover {
  height: 140px;
  box-shadow: #000;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15) !important;
}

.application-content .content-body .no-header {
  height: calc(100vh - 70px) !important;
  overflow: auto;
}

.settings-header {
  height: 70px;
  align-items: center;
}

.config-modal .config-modal-header {
  height: 60px;
}

.config-modal .config-modal-body {
  height: calc(100vh - 130px);
  overflow: auto;
}
.config-modal .config-modal-body .content-body{
  padding-top:10px;
  padding-bottom:10px;
  height: calc(100vh - 213px);
  background-color: #fff;
  overflow-x:auto;
}

.projects-wrapper {
  height: calc(100vh - 70px);
  overflow: auto;
}

.project-start{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  min-height: 70px;
  background-color: #ffc107;
  border-radius: 100px;
  color: #000;
  font-weight: bold;
  position:relative;
  cursor:pointer;
  border:6px solid #FBDF8B;
  box-shadow: inset 0px 0px 11px -2px rgba(0,0,0,0.37);

}
.project-start:hover{
  background-color: #FBDD85;
  box-shadow: inset 0px 0px 24px -2px rgba(0,0,0,0.37);
}

.project-stop{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  min-height: 70px;
  background-color: #FFA5A5;
  border-radius: 100px;
  color: #000;
  font-weight: bold;
  position:relative;
  cursor:pointer;
  border:6px solid #FFD1D1;
  box-shadow: inset 0px 0px 11px -2px rgba(0,0,0,0.37);
}
.project-stop:hover{
  background-color: #F8CECE;
}

.project-end{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  min-height: 70px;
  background-color: #9FE2B2;
  border-radius: 100px;
  color: #000;
  font-weight: bold;
  position:relative;
  border:6px solid  #9FE2B2;
  box-shadow: inset 0px 0px 11px -2px rgba(0,0,0,0.37);
}

.project-card button{
  width: 30px;
  height: 30px;
  margin-left: 4px;
  border:1px solid #ccc;
  background-color: white;
  border-radius: 4px;
}
.project-card button:hover{
  color: white;
  background-color: #333;
}
.project-card button:disabled{
  background-color: #efefef;
  color: #999;
}
.project-card button:disabled:hover{
  background-color: #efefef;
  color: #999;
}

.project-card-line {
  position: absolute;
  display: block;
  width: 1px;
  border-left: 2px dashed #588B40;
  height: 80px;
  bottom:0;
  z-index: 50;
}

.project-card-line-bottom {
  display: block;
  width: 1px;
  border-left: 2px dashed #588B40;
  min-height: 20px;
  bottom:0;
}
.project-card-line-top {
  display: block;
  width: 1px;
  border-left: 2px dashed #588B40;
  height: 30px;
}
.project-card-line-horizontal {
  position: absolute;
  display: block;
  width: 100%;
  border-top: 2px dashed #999;
  height: 1px;
  z-index:10;
}


.object-cover {
  object-fit: cover;
}
.dzu-dropzone {
  border: none !important;
  border-radius: 10px !important;
  min-height: auto !important;
  padding: 10px 0 10px 0;
}
.dzu-inputLabel {
  position: relative !important;
  font-size: 16px;
  font-weight: 400 !important;
  margin: 0 !important;
  color: #598b40;
}
.z-999 {
  z-index: 999 !important;
}
.dzu-previewContainer {
  padding: 10px !important;
  border-radius: 10px;
}
.dzu-inputLabelWithFiles {
  margin: 0 !important;
  margin-top: 10px !important;
  color: #598b40;
  background-color: rgba(89, 139, 64, 0.1);
  border-color: transparent;
  width: 100%;
}
.dzu-submitButtonContainer {
  width: 100%;
  margin: 0 !important;
}
.dzu-submitButton {
  color: #fff;
  background-color: #598b40;
  border-color: #598b40;
  width: 100%;
}
.dzu-submitButton:disabled {
  color: #598b40;
  background-color: rgba(89, 139, 64, 0.1);
  border-color: transparent;
  width: 100%;
}

.zindex-20 {
  z-index: 20 !important;
}
.zindex-50 {
  z-index: 50 !important;
}
.zindex-60 {
  z-index: 60 !important;
}
.zindex-70 {
  z-index: 70 !important;
}
.zindex-104 {
  z-index: 104 !important;
}
.zindex-105 {
  z-index: 105 !important;
}
.zindex-106 {
  z-index: 106 !important;
}
.zindex-107 {
  z-index: 107 !important;
}
.zindex-108 {
  z-index: 108 !important;
}

.files-item {
  width: 160px !important;
  height: 160px;
  box-sizing: border-box;
}
.files-item .file-image {
  width: 100% !important;
  height: 120px;
}

.face-recognition-modal-body {
  background-color: #efefef;
  max-height: 300px;
  overflow-y: scroll;
  border-bottom: 1px solid #e3e3e3;
}

.application-preview .application-preview-header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.application-preview .application-preview-content {
  min-height: calc(100vh - 70px);
}
.application-preview .application-preview-content .application-preview-left {
  width: 300px;
  min-height: calc(100vh - 70px);
  overflow-y: auto;
  box-sizing: border-box;
}
.application-preview
  .application-preview-content
  .application-preview-left
  img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.application-preview .application-preview-content .application-preview-right {
  width: calc(100% - 300px);
  min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  background-color: #efefef;
  box-sizing: border-box;
}

@media only screen  and (max-width : 770px ){
  .application-preview .application-preview-content .application-preview-right {
    width:100vw !important;
  }
  .application-preview .application-preview-content .application-preview-left {
    display: none !important;
  }
}

.application-preview
  .application-preview-content
  .application-preview-right
  img {
  max-height: 360px;
}

.animate-ping {
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping {
  0%{
    transform: scale(1);
    opacity: 0.6;
  }
  50%{
    transform: scale(1);
    opacity: 1;
  }
  100%{
    transform: scale(1);
    opacity: 0.5;
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shadow-dark {
  box-shadow: 0px 0px 27px -12px rgba(0, 0, 0, 0.75);
}

.white-space {
  white-space: nowrap;
}
.hover-link:hover {
  color: #598b40 !important;
  text-decoration: underline;
}
.border-box {
  box-sizing: border-box;
}

.dashed-divider {
  height: 1px;
  width: 100%;
  border-top: 2px dashed #598b40;
}

.tooltip-inner {
  min-width: 250px !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.swal2-title {
  font-size: 22px;
  font-weight: bold;
}

.logo-loader {
  width: 150px;
}

.logo-loader.medium {
  width: 100px !important;
}

.logo-loader.small {
  width: 70px !important;
}

.placeholder-text {
  color: #96a4af;
}

.select-box-body {
  max-height: 200px;
  overflow-x: auto;
  position: absolute;
  width: 100%;
  z-index: 105;
}
.select-box-body .item {
  padding: 4px 12px;
  cursor: pointer;
  width: 100%;
}
.select-box-body .item:hover {
  background: #efefef;
}

.select-box-body .item.active {
  background: #598b40;
  color: #fff;
}

.label-selector {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.label-selector .label-list {
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

.label-list-wrapper {
  height: 260px;
  overflow-x: auto;
  background-color: #efefef;
}
@media (max-width: 768px) {
  .label-selector .label-list {
    border-radius: 0 !important;
  }
  .label-list-wrapper {
    height:auto !important;
  }
  .selected-label-list-wrapper {
    height: auto !important;
  }
  .label-selector .selected-label-list {
    border-left:1px solid #ccc !important;
    border-radius: 0 !important;
    margin-top:10px ;
  }
}


.label-list-wrapper .item {
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 0 6px 6px 0;
  border: 1px solid #ccc;
  cursor: pointer;
  color: #000;
}
.label-list-wrapper .item:hover {
  background-color: lightblue;
}

.label-selector .selected-label-list {
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.selected-label-list-wrapper {
  height: 260px;
  overflow-x: auto;
  background-color: #f9f9f9;
}
.selected-label-list-wrapper .item {
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 0 6px 6px 0;
  border: 1px solid #ccc;
  cursor: pointer;
  color: #000;
}
.selected-label-list-wrapper .item:hover {
  background-color: #e3e3e3;
  border: 1px solid red;
}

.side-menu {
  height: 100vh;
  background-color: #fff;
}
.side-menu-header {
  width: 100%;
  height: 3.75rem;
  border-bottom: 1px solid #e7eaf3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.side-menu-header .full-logo {
  height: 34px;
}

.side-menu-header .mini-logo {
  height: 34px;
  display: none;
}

.side-menu-header .menu-toggle-button {
  display: none;
  position: absolute;
  right: -14px;
  padding: 0 !important;
  border: none;
  border-radius: 10px;
  color: #666;
}


.side-menu-body {
  height: calc(100vh - 15.75rem);
  overflow-x: auto;
  padding-bottom: 20px;
  background-color: #fff;
}
.side-menu-footer {
  height: 12rem;
  border-top: 1px solid #e7eaf3;
  box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.14);
}
.side-menu-footer-plan {
  padding: 14px 8px;
}

.side-menu-body .menu-title {
  font-size: 12px;
  font-weight: bold;
  color: #000;
  padding: 10px;
  display: block;
}
.menu-item-wrapper{
  max-height: 0;
  transition: max-height 0.30s ease-out;
  overflow: hidden;
}
.menu-item-wrapper.active{
  max-height: 700px;
  transition: max-height 0.25s ease-in;
}

.side-menu-body .menu-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.side-menu-body .menu-item.active {
  background-color: #D8E9D2;
  color: #41642E;

}

.side-menu-body .menu-item.active .icon i {
  color: #41642e;
}
.side-menu-body .menu-item .menu-icon {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.side-menu-body .menu-item i {
  font-size: 22px !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
}
.side-menu-body .menu-divider{
  width: 100%;
  padding:6px 0;
  display: flex;
}
.side-menu-body .menu-divider .menu-divider-line{
  width: 100%;
  height: 1px;
  border-bottom:1px solid #ccc;
}

.navbar-vertical-aside-mini-mode .menu-title {
  display: none !important;
}

.navbar-vertical-aside-mini-mode .menu-item span {
  display: none !important;
}

.navbar-vertical-aside-mini-mode .side-menu-header {
  justify-content: center;
}

.navbar-vertical-aside-mini-mode .side-menu-header .full-logo {
  display: none !important;
}

.navbar-vertical-aside-mini-mode .side-menu-header .mini-logo {
  display: block !important;
}

.navbar-vertical-aside-mini-mode .side-menu-footer .side-menu-footer-plan {
  display: none !important;
}

.navbar-vertical-aside-mini-mode .side-menu-body {
  height: calc(100vh - 7.5rem) !important;
}
.navbar-vertical-aside-mini-mode .side-menu-body .menu-item {
  justify-content: center;
}

.navbar-vertical-aside-mini-mode .side-menu-body .menu-item .menu-icon {
  width: 40px;
  height: 40px;
}

.navbar-vertical-aside-mini-mode .side-menu-body .menu-item.active .menu-icon {
  border: 2px solid #598b40;
  color: #000;
  border-radius: 4px;
}

.account-switch-menu {
  width: 100%;
  position: absolute;
  bottom: 69px;
  left: 0;
  background-color: #fff;
  z-index: 104;
  box-shadow: 0px -6px 8px -5px rgba(0, 0, 0, 0.75);
}
.account-switch-menu-wrapper{
  max-height: 300px;
  overflow-x:auto;
}

.navbar-vertical-aside-mini-mode .account-switch-menu {
  position: fixed;
  width: 300px;
  bottom: 4rem !important;
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.35);
}

.navbar-vertical-aside-mini-mode .side-menu-footer-account .account-button {
  justify-content: center !important;
}
.navbar-vertical-aside-mini-mode
  .side-menu-footer-account
  .account-button
  .avatar {
  margin-left: 0.5rem !important;
}
.navbar-vertical-aside-mini-mode
  .side-menu-footer-account
  .account-button
  span {
  display: none;
}

.builder-filter-item {
  padding: 4px 12px !important;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #000;
  margin: 0px 6px 6px 0px;
  cursor: pointer;
  text-transform: capitalize;
}

.builder-filter-item:hover {
    background: #ebf3e8;
}

.builder-filter-item.active {
  background: #41642e;
  color: #fff;
}

.builder-filter-item.active:hover {
    background: #41642e;
    color: #fff;
}

.variable-component .variable-item .copy-icon{
  display: none;
 }

.variable-component .variable-item:hover .copy-icon{
 display: inline-block;
 margin-left:6px;
}

@media only screen and (max-width: 576px) {
  .mt-sm-200 {
    margin-top: 200px;
  }
}
.mobile-device-popup{
  display: none !important;
}
@media only screen and (max-width : 998px){
  .mobile-device-popup{
    display: flex !important;
    position: fixed;
    width:100vw;
    height: 100vh;
    background-color: rgba(0,0, 0, 0.7);
    z-index: 999 !important;
  }
}

.component-detail-wrapper{
  height: calc(100vh - 100px);
  overflow-x:scroll;
  background-color: #f9f9f9;
}


.tooltip-arrow{
  content: "";
     position: absolute;
     width: 20px;
     height: 20px;
     background: white;
     transform: translateX(-50%) translateY(-50%) rotate(45deg);
     top: 0;
     right:20px;
}

.tooltip-arrow.left{
    right: -20px !important;
    top: 26px;
}

.blend-mode-multiply{
  mix-blend-mode: multiply;
}

.components-modal{
  position:absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  top:0;
  display:flex;
  justify-content: center;
  align-items: end;
  z-index: 106;

}



.components-container{
  height: calc(100vh - 70px);
  overflow-x:auto;
  border-radius:10px 10px 0 0 ;
}
.components-wrapper{
  height: calc(100vh - 200px);
  overflow-x:auto;
  background-color: #f9f9f9;
  width: 100%;
  display: grid;
  position:relative;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit,  calc(100% / 2) );
  align-content: flex-start;
  padding:6px !important;
  align-items: stretch;
}

.rotate-180{
  transform: rotate(180deg);
  transition: all 100ms;
}

.input-output-list-wrapper{
  height: calc(100vh - 120px);
  overflow-x:auto;
}

.dzu-inputLabel{
  height: 100% !important;
}

.applications-overview-wrapper{
  min-height: 360px;
}

.model-test-data-container {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.overflow-x{
  overflow-x:auto !important;
}

.transition-all{
  transition: max-height 0.15s ease-out;
}
.transition-width{
  transition: width 0.5s ease-in-out;
}

.onboarding-container{
  background-color: white;
  background-image: url(../images/triangle-bg.png);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.image-brightnes-dark:hover img {
  filter: brightness(0.7);
}

.image-button .button-wrapper{
  display: none;
  background-color:  rgba(0, 0, 0, 0.3);
  justify-content:center;
  align-items:center;
}

.image-button:hover .button-wrapper{
  display: flex;

}

.builder-description img{
 width: 100%;
}

.builder-description blockquote{
  padding:10px;
  border:1px solid #ccc;
  border-radius: 6px;
  background: #efefef;
  font-size: 14px;
  font-weight: 500;
}
.builder-description blockquote p{
  margin: 0;
  padding: 0;
  color: #000;
}

.ck-content{
  min-height: 450px;
}
.dropzone-ui-extra{
  min-height: auto !important;
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
}

.model-frame-page .file-selector-item{
  min-height: auto !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  width: auto;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items:center;
  padding:10px;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
}
.model-frame-page .file-selector-item svg{
  margin-right: 10px;
  font-size: 26px !important;
}

.model-frame-page .file-selector-item:hover{
  background-color: #fff;
  color: #598B40 !important;
}

.model-frame-page .dropzone-ui-extra{
  min-height: auto !important;
  height: auto;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.model-frame-page .dropzone-ui-extra .file-item-list-container{
  min-height: auto !important;
  height: auto;
  width: auto;
  min-width: auto !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.test-image-gallery{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
}
.test-image-gallery img{
  width: 100%;
  height: 120px;
  object-fit: cover;
}


.menu-accordion{
  overflow: hidden;
  max-height: 0px;
  transition: all 100ms;
}
.menu-accordion.active{
  overflow: hidden;
  max-height: 700px;
}

.hljs.line-numbers__qxcy{
  line-height: 1.3 !important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;
  font-size: inherit  !important;
}
.hljs.container-code-editor__qxcy{
  align-items: start !important;
}
.hljs.code-editor__textarea__qxcy{
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;
  font-size: inherit  !important;
}

.payment-header{
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 0 20px;
}

.payment-body{
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #fff;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;
}

.payment-method-button{
  border-radius: 10px;
  border: none;
  color: #fff;

  padding: 4px 30px;
  background: linear-gradient(to right, #29863D, #635BFF);
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  transition: all 1000ms;
}
.payment-method-button:hover{
  background: linear-gradient(to left, #29863D, #635BFF);
}

.new-account-subscription{
  background: url("../images/api-integration-bg.png");
  background-size: cover;
}
.new-account-subscription .payment-body{
  background-color: transparent !important;
}

.resize-y{
  resize: vertical;
}

.usecase-input-group{
  display: flex;
  align-items: end;
  justify-content: center;
  border: 1px solid #999;
  border-radius: 4px;

}

.usecase-input-group input{
  border: none;
  border-left: 1px solid #999;
  border-radius: 0 !important;
  width: 100%;
  padding:0 10px;
  background-color: #fff;
  font-size: 16px;
  height: 50px;
  width: calc(100% - 150px);
}
.term-container{
  border: none;
  border-left: 1px solid #999;
  border-radius: 0 !important;
  width: 100%;
  padding:10px 10px;
  background-color: #fff;
  font-size: 16px;
  min-height: 100px;
  display: flex;
  align-items:start;
  max-height: 160px;
  overflow: auto;
}

.term-container textarea{
  border: none;
  border-radius: 0 !important;
  background-color: #fff;
  font-size: 16px;
  height: 100px ;
  width:100%
}

.usecase-input-group button.reset{
  border-radius: 0;
  border:1px solid #ccc;
  height: 50px;
  background-color: #fff !important;
}

.usecase-input-group button svg{
 font-size: 18px;
 font-weight: bold;
}

.auth-background{
  background-image: url('../images/auth-bg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.tool-navigation{
  box-shadow: inset 0px -1px 0px 0px rgba(153, 153, 153, 1);
}
.tool-navigation-button{
 border: none;
 background-color: transparent;
 border-top: 1px solid transparent ;
 border-left: 1px solid transparent ;
 border-right: 1px solid transparent ;
}

.tool-navigation-button.active{
  background-color: #fff;
  border-top: 1px solid #999 ;
  border-left: 1px solid #999 ;
  border-right: 1px solid #999 ;
}
.loader {
  width:100%;
  margin:0 auto;
  border-radius:10px;
  border:4px solid transparent;
  position:relative;
  padding:2px;
}
.loader:before {
  content:'';
  border:1px solid var(--primary-dark);
  border-radius:10px;
  position:absolute;
  background-color: #e3e3e3;
  top:-4px;
  right:-4px;
  bottom:-4px;
  left:-4px;
}
.loader .loaderBar {
  position:absolute;
  border-radius:10px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:var(--primary);
  width:0;
  animation:borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}

.mobile-menu {
  display: none !important;
}

.assistant-side-bar{
  position:relative;
  min-width: 200px !important;
  width: 200px;
  transition: all 0.5s ease;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-color: rgba(238, 238, 240, 0.498);
  border-style: solid;
  border-width: 0px 5px;
  box-shadow: rgba(29, 29, 31, 0.05) 0px 4px 5px;
  background: rgb(255, 255, 255);
}

.assistant-side-bar.passive{
  min-width: 0 !important;
  width:0 !important;
 }
.assistant-route-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: auto;
  height: 60px;
  z-index: 30;
}

.sidebar-show-button{
  z-index: 50;
  height: 60px;
  width: 60px;
  border: none;
  background-color: transparent;
  color: #333;

}
.sidebar-show-button-header{
  display: none;
  position: relative;
  height: 40px;
  width: 40px;
  border: none;
  background-color: transparent;
  color: #666;

}

.category-container {
  overflow-x: auto;
  white-space: nowrap;
}

.type-selection-container {
  background: radial-gradient(100.99% 100.73% at 0 0,rgba(0,150,0,.726) 0,#008000 .01%,rgba(0,150,0,0) 100%),radial-gradient(68.47% 129.02% at 22.82% 97.71%,#008000 0,rgba(0,100,0,0) 100%),radial-gradient(106.1% 249.18% at 0 0,#008000 0,rgba(0,150,0,0) 100%),radial-gradient(64.14% 115.13% at 5.49% 50%,#008000 0,rgba(0,100,0,0) 100%),#006400;
}

.task-header-menu {
  background: linear-gradient(90deg,#00c4cc,#7d2ae8) !important;
}

.type-selection-categories {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.15),0 2px 8px rgba(13,18,22,.15);
  color: #0d1216;
  height: 48px;
  margin-bottom: 8px;
  width: 48px;
}

.type-selection-category-active {
  background: #D8D8D8 !important;
  border: 2px solid var(--white) !important;
}

.type-selection-text-active {
  color: #D8D8D8 !important;
}

.assistant-side-bar-header{
  display:flex;
  justify-content:space-between;
  align-items: center;
  height: 60px;
}

.assistant-history{
  min-height: 100%;
}
.history-item{
  display: flex;
  align-items:center;
  justify-content:space-between;
  color: #fff;
  padding: 6px 0;
  cursor: pointer;
}
.history-item:hover{
  background-color: #333;
}

.assistant-side-bar-menu{
  border-top:1px solid #333;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.assistant-menu-button{
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  height: 2rem;
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 0.85rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0px 16px;
  user-select: none;
  position: relative;
  cursor: pointer;
  color: rgb(24, 25, 27);
}

.assistant-menu-button:hover{
  text-decoration: none;
  color: rgb(24, 25, 27) !important;
  background-color: rgb(238, 238, 240) !important;
}

.assistant-menu-button-active{
  text-decoration: none;
  color: rgb(24, 25, 27) !important;
  background-color: rgb(238, 238, 240) !important;
}

.assistant-menu-button-sm{
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  height: 1.5rem;
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 0.85rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0px 16px;
  user-select: none;
  position: relative;
  cursor: pointer;
  color: rgb(24, 25, 27);
}

.assistant-menu-button-sm:hover{
  text-decoration: none;
  color: rgb(24, 25, 27) !important;
  background-color: rgb(238, 238, 240) !important;
}

.assistant-menu-button-sm-active{
  text-decoration: none;
  color: rgb(24, 25, 27) !important;
  background-color: rgb(238, 238, 240) !important;
}

.assistant-chat{
  width:100%;
  position:relative;
}
.assistant-chat-input{
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  z-index:50;
}

@media (max-width: 768px) {
  .assistant-side-bar{
    position: absolute !important;
    z-index:500;
    width: 80% !important;
    border-width: 0px 1px !important;
  }
.assistant-route-header{
  width: 100%;
}
  .assistant-configuration-sidebar{
    position: absolute !important;
    z-index:500;
    width: 100% !important;
    right: 0;
  }
  .sidebar-show-button-header{
    display: block;
  }
}

.assistant-configuration-sidebar{
  position:relative;
  min-width: 300px !important;
  width: 300px;
  transition: all 0.5s ease;
  background-color: #000;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.assistant-configuration-sidebar.passive{
  min-width: 0 !important;
  width:0 !important;
 }


.min-h-avaliable{
  max-height: -webkit-fill-available;
}

.uploaded-image-item{
  position: relative;
  width: 50px !important;
  height: 50px !important;
  border:1px solid #999;
  margin-right: 6px;
  border-radius: 4px;
  cursor:pointer;
}
.uploaded-image-item img{
  position: relative;
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px;
}

.uploaded-image-item .delete-image-btn{
  position:absolute;
  top: -10px;
  right: -10px;
  border-radius:100%;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  z-index:40;
}

section.pricing {
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing .card-active {
  border: 2px solid #598b40 !important;
  border-radius: 1rem !important;
}

.pricing .card-main {
  padding-left: 5px!important;
  padding-right: 5px! important;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul {
  padding-inline-start: 1rem !important;
}

.pricing ul li {
  margin-bottom: 1rem;
  list-style: none;
  margin-left: 0px !important;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn-inactive {
  opacity: 0.7 !important;
}

.btn-inactive {
  opacity: 0.7 !important;
}

.spy-text {
  transition: opacity 1s ease; /* Adjust the duration and easing function as needed */
}

.spy-text.loading {
  opacity: 0.5; /* Adjust the opacity for the loading state */
}

.image-loading-card {
  width: 100%;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  padding-bottom: 0.1rem!important;
  box-sizing: border-box;
}
.image-loading-placeholder {
  background: #eee;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}
.image-loading-image-placeholder {
  aspect-ratio: 16 / 12;
  margin-bottom: 1rem;
}
.image-loading-title-placeholder {
  aspect-ratio: 16 / 1.5;
  margin-bottom: 2rem;
}
.image-loading-text-placeholder {
  aspect-ratio: 16 / 1;
  margin-top: 1rem;
  margin-bottom: 0;
}

.image-loading-placeholder:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  background: linear-gradient(90deg, #eee, #f4f4f4, #eee);
  animation: gradient 1s infinite ease-in-out;
}

@keyframes gradient {
  form {
    left: 0%;
  }
  
  to {
    left: 100%;
  }
}


/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover .btn-inactive {
    opacity: 1 !important;
  }
}

@media (min-width: 768px) { /* md and above */
  .editor-panel-vh-100 {
    height: 80vh !important;
  }
}

@media (max-width: 767.98px) { /* sm and below */
  .editor-panel-vh-100 {
    height: 80vh !important;
  }
}

.disabled-textarea {
  background-color: #808080; /* Gray color */
  opacity: 0.6; /* Optional: makes the text slightly less opaque */
  pointer-events: none; /* Prevents interaction with the text */
  color: #000000 ! important
}

.text-settings-dropdown {
  position: absolute;
  top: 50px;
  background: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 220px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.text-settings-dropdown label {
  margin: 0;
  flex: 1;
  color: white;
}

.text-settings-dropdown input[type="range"] {
  flex: 1;
  margin: 0 10px;
}

.text-settings-dropdown span {
  color: white;
  width: 40px;
  text-align: left;
}

.btn-editor-small {
  padding: 0.3rem 0.6rem !important;
}

.introjs-tooltip {
  max-width: 450px !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
}

.introjs-dontShowAgain {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.animated-gradient-text {
  background: linear-gradient(90deg, var(--black), var(--primary));
  background-size: 400% 400%;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 5s ease infinite;
}

.rainbow-text {
  background: linear-gradient(90deg, #B22222, #FF4500, #FF8C00, #32CD32, #1E90FF, #4B0082);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.chat-input-wrapper {
  background-color: var(--light);
  border-radius: 30px;
  padding: 15px 20px;
  box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 4px 8px rgba(0,0,0,0.14);
}

.shadow-8 {
  box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 4px 8px rgba(0,0,0,0.14);
}

.chat-input-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.chat-input-area textarea,
.chat-input-area input {
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  width: 100%;
  resize: none;
}

.oval-input-group {
  display: flex;
  align-items: stretch;
  border-radius: 25px;
  overflow: hidden;
  background-color: #fff;
  height: 100px;
}

.oval-input-group .term-container {
  display: flex;
  align-items: center;
}

.oval-input-group textarea {
  border: none;
  outline: none;
  padding: 10px 15px;
  resize: none;
  height: 100%;
  min-height: unset;
}

.oval-input-group button {
  background-color: var(--primary );
  color: #fff;
  transition: background-color 0.3s ease;
  font-weight: 500;
  white-space: nowrap;
}

.oval-input-group button:hover:not(:disabled) {
  background-color: var(--primary-dark);
}

.oval-input-group button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.my-masonry-grid {
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 15px;
}

.introjs-tooltipReferenceLayer {
  visibility: visible !important;
}

/* Video Player */
.video-js {
  width: 100% !important;
}

.video-js .vjs-control-bar {
  border-radius: 10px !important;
}